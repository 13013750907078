<template>
    <router-view @toggle-sounds="toggleTracks(muted)" @off-sounds="toggleTracks(false)" @on-sounds="toggleTracks(true)" />
</template>

<script>
import { Howl, Howler } from "howler";

export default {
    name: "App",
    components: {
    },
    data() {
        return {
            muted: true,
        }
    },
    mounted() {
        const loop = new Howl({
            src: "/assets/loopTrack.mp3",
            autoplay: false,
            loop: true,
            volume: 0.5
        });

        const intro = new Howl({
            src: "/assets/introTrack.mp3",
            autoplay: true,
            loop: false,
            volume: 0.5,
            onend: () => {
                loop.play();
            }
        });

        Howler.mute(true)
        intro.play()
    },
    methods: {
        toggleTracks(muted) {
            Howler.mute(!muted);
            this.muted = !muted;
        }
    }
}
</script>

<style>
html {
    scroll-behavior: smooth;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.gradient-rosegold {
    background: rgb(120,53,15);
    background: linear-gradient(311deg, rgba(120,53,15,1) 0%, rgba(230,180,116,1) 44%, rgba(146,83,39,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>