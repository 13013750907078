<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergAlagaroContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="lg:px-20 px-6 xl:px-40 overlay">
                <Lightbox ref="alagaroAllBlack" :media="alagaroAllBlack" />
                <Lightbox ref="alagaroMetallic" :media="alagaroMetallic" />
                <Lightbox ref="enquiryForm" :media="enquiryForm" />
                <div class="mt-10 lg:mt-20 lg:w-2/5">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-6xl lg:text-7xl uppercase mb-5">Alagaro {{ this.color === "allBlack" ? "All Black" : this.color }}</h2>
                    <p class="text-lg leading-snug">
                        Highest precision wrapped with powerful noblesse.
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 mt-4 mb-8 mr-2 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 xl:w-1/3 text-center">Enquire now</button>
                    <button @click="this.$refs[`alagaro`+ (this.color === 'allBlack' ? 'AllBlack' : this.color)].toggleLightbox();" class="font-lora border-rosegold border ml-2 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear inline-block duration-75 text-center">Gallery</button>
                </div>
            </div>
        </div>

        <div id="technical" class="lg:px-20 xl:px-40 px-6 pt-16">
            <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 lg:w-2/5">Technical Facts</h2>
            <div class="grid grid-cols-2 gap-10 text-gray-300 py-4">
                <div class="col-span-2 lg:col-span-1 text-left">
                    Alagaro’s stunning precision comes from the <b class="gradient-rosegold">Trilevis 6118</b>, the revolutionary new Flying Tourbillon, developed and engineered from the ground up by Giberg Haute Horlogerie exclusively for its own watch collection.
                </div>
                <div class="col-span-2 lg:col-span-1 text-left">
                    The Alagaro has a sapphire ceramic case with yellow gold, a diamond bezel set, a sapphire dial with brilliant index and a brown Alligator bracelet.
                </div>
                <div class="col-span-2 flex justify-center">
                    <img :src="`/assets/giberg/product/mensWatches/alagaro${color}Face.png`" alt="Alagaro watch face">
                </div>
            </div>
        </div>

        <div id="desc">
            <div class="lg:px-20 px-6 xl:px-40 overlay">
                <p class="lg:w-2/5 text-lg">
                    This is not a watch for the average. The Alagaro was created for the independent mind, for those who have chosen their own way through life. A men’s watch destined to be your faithful companion on unexpected, challenging and adventurous paths to new possibilities.
                    <br><br>
                    The Alagaro reflects your own personality in timeless beauty and irresistible gracefulness: highest precision wrapped with powerful noblesse.
                    <br><br>
                    Alagaro is an ultra-limited single-item watch available as an individual customized edition.
                </p>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergAlagaro",
    data() {
        return {
            color: this.$route.params.color.charAt(0).toUpperCase() + this.$route.params.color.slice(1),
            alagaroAllBlack: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensWatches/alagaroAllBlack1.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensWatches/alagaroAllBlack2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensWatches/alagaroAllBlack3.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensWatches/alagaroAllBlack4.png"
                }
            ],
            alagaroMetallic: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensWatches/alagaroMetallic1.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensWatches/alagaroMetallic2.jpg"
                }
            ],
            enquiryForm: [
                {
                    type: "form",
                    product: "Alagaro " + this.$route.params.color.charAt(0).toUpperCase() + this.$route.params.color.slice(1)
                }
            ]
        }
    },
    mounted() {
        if (this.color === 'All-black') {
            this.color = "allBlack";
        }

        document.querySelector("#gibergAlagaroContainer #landing").style.backgroundImage = `url("/assets/giberg/product/mensWatches/alagaro${this.color}Landing.jpg")`;
        document.querySelector("#gibergAlagaroContainer #desc").style.backgroundImage = `url("/assets/giberg/product/mensWatches/alagaro${this.color}Desc.jpg")`;
        
    },
    components: {
        Navbar,
        Lightbox,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #gibergAlagaroContainer #landing .overlay {
        background-color:rgba(0, 0, 0, 0.55);
    }
    #gibergAlagaroContainer #technical {
        height: auto;
    }

    #gibergAlagaroContainer #desc {
        background-position: 65% 50% !important;
    }
}
@media only screen and (min-width : 992px) {
    #gibergAlagaroContainer #landing .overlay {
        background-color:rgba(0, 0, 0, 0.1);
    }
    #gibergAlagaroContainer #technical {
        height: 680px;
    }
}

#gibergAlagaroContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergAlagaroContainer .overlay {
    background-color:rgba(0, 0, 0, 0.55);
    padding-top: calc(90px + 5vh) !important;
    min-height: calc(80vh + 90px);
}

#gibergAlagaroContainer #landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background: url("/assets/giberg/product/ladiesWatches/alagaroMetallicLanding.jpg");
    background-size: cover;
    background-position: center;
}

#gibergAlagaroContainer #technical img {
    height: 500px;
}

#gibergAlagaroContainer #desc {
    background: url("/assets/giberg/product/ladiesWatches/oloraDiamondDesc.jpg");
    background-size: cover;
    min-height: calc(80vh + 90px);
    background-position: center;
}
</style>
