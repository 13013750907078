<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergOloraContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="lg:px-20 xl:px-40 px-6 overlay">
                <Lightbox ref="oloraDiamond" :media="oloraDiamond" />
                <Lightbox ref="oloraRose" :media="oloraRose" />
                <Lightbox ref="oloraSapphire" :media="oloraSapphire" />
                <Lightbox ref="enquiryForm" :media="enquiryForm" />
                <div class="mt-10 lg:mt-20 lg:w-2/5">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-7xl uppercase mb-5">Olora {{ color }}</h2>
                    <p class="text-lg leading-snug">
                        Olora pairs lightness and elegance with impressive precision.
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 mt-4 mb-8 mr-2 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 xl:w-1/3 text-center">Enquire now</button>
                    <button @click="this.$refs[`olora`+color].toggleLightbox();" class="font-lora border-rosegold border ml-2 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear inline-block duration-75 text-center">Gallery</button>
                </div>
            </div>
        </div>

        <div id="technical" class="lg:px-20 xl:px-40 px-6 pt-16">
            <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 lg:w-2/5">Technical Facts</h2>
            <div class="grid gap-6 grid-cols-2 lg:gap-10 text-gray-300 py-4">
                <div class="lg:col-span-1 col-span-2 text-left">
                    Olora’s stunning precision comes from the <b class="gradient-rosegold">Trilevis 6118</b>, the revolutionary new Flying Tourbillon, developed and engineered from the ground up by Giberg Haute horlogerie exclusively for its own watch collection.
                </div>
                <div class="lg:col-span-1 col-span-2 text-left">
                    Olora has a sapphire ceramic case with white gold framed with swans, a diamond bezel set, a sapphire dial with sapphire index, two swan attachments and a bracelet made with natural grained cowhide and supple calfskin for the inner lining.
                </div>
                <div class="col-span-2 flex justify-center">
                    <img :src="`/assets/giberg/product/ladiesWatches/olora${color}Face.png`" alt="Olora Diamond watch face">
                </div>
            </div>
        </div>

        <div id="desc">
            <div class="lg:px-20 xl:px-40 px-6 overlay">
                <p class="lg:w-2/5 text-lg lg:mt-10">In its perfect harmony this incomparable ladies’ watch represents a tribute to the art of watchmaking as well as to the grandiosity of the ballet. The elegant case is framed with swans while the Flying Tourbillon seems to dance behind the heart of the watch.</p>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergOlora",
    data() {
        return {
            color: this.$route.params.color.charAt(0).toUpperCase() + this.$route.params.color.slice(1),
            oloraDiamond: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraDiamond1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraDiamond2.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraDiamond3.jpg"
                },

            ],
            oloraSapphire: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraSapphire1.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraSapphire2.jpg"
                }
            ],
            oloraRose: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraRose1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraRose2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesWatches/oloraRose3.jpg"
                }
            ],
            enquiryForm: [
                {
                    type: "form",
                    product: "Olora " + this.$route.params.color.charAt(0).toUpperCase() + this.$route.params.color.slice(1)
                }
            ]
        }
    },
    mounted() {
        document.querySelector("#gibergOloraContainer #landing").style.backgroundImage = `url("/assets/giberg/product/ladiesWatches/olora${this.color}Landing.jpg")`;
        document.querySelector("#gibergOloraContainer #desc").style.backgroundImage = `url("/assets/giberg/product/ladiesWatches/olora${this.color}Desc.jpg")`;
    },
    components: {
        Navbar,
        Lightbox,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #gibergOloraContainer #technical {
        height: auto;
    }

    #gibergOloraContainer #desc {
        background-position: 75% 50% !important;
    }
}
@media only screen and (min-width : 992px) {
    #gibergOloraContainer #technical {
        height: 680px;
    }
}

#gibergOloraContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergOloraContainer .overlay {
    background-color:rgba(0, 0, 0, 0.45);
    padding-top: calc(90px + 5vh) !important;
    min-height: 80vh;
}

#gibergOloraContainer #landing .overlay {
    background-color:rgba(0, 0, 0, 0.65) !important;
    min-height: calc(80vh + 90px);
}

#gibergOloraContainer #landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background: url("/assets/giberg/product/ladiesWatches/oloraDiamondLanding.jpg");
    background-size: cover;
    background-position: 40% 50%;
}

#gibergOloraContainer #technical img {
    height: 500px;
}

#gibergOloraContainer #desc {
    background: url("/assets/giberg/product/ladiesWatches/oloraDiamondDesc.jpg");
    background-size: cover;
}
</style>
