<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="contactContainer" class="bg-wallgray px-6 text-gray-300">
        <h3 class="text-rosegold text-center">Get in touch with us</h3>
        <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 lg:w-1/3 text-center mx-auto mb-10 lg:mb-16">Contact Us</h2>
        <ContactForm class="lg:w-1/2 mx-auto"/>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer";

export default {
    name: "Contact",
    components: {
        Navbar,
        ContactForm,
        Footer
    }
}
</script>

<style scoped>
#contactContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#contactContainer {
    padding-top: 90px;
    margin-top: -90px;
    padding-bottom: 45px;
}
</style>