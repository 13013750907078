<template>
    <div class="w-full bg-wallgray">
        <Navbar @toggle-sounds="$emit('toggleSounds')" />
        <div id="homeContainer">
            <div class="carousel relative overflow-hidden">
                <div class="carousel-inner relative overflow-hidden w-full">
                    <!--Slide 1-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-1"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    checked="checked"
                    />
                    <div
                    class="carousel-item absolute opacity-0 bg-center"
                    style="
                        background-image: url(/assets/giberg/product/ahton/ahtonHome.jpg);
                    "
                    ></div>
                    <label
                    for="carousel-7"
                    class="
                        control-1
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                        flex
                        justify-center
                        content-center
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-2"
                    class="
                        next
                        control-1
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!--Slide 2-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-2"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    />
                    <div
                    class="carousel-item absolute opacity-0 bg-center"
                    style="
                        background-image: url(/assets/giberg/product/mensJewellery/blackBraceletWhiteGold1.jpg);
                    "
                    ></div>
                    <label
                    for="carousel-1"
                    class="
                        control-2
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-3"
                    class="
                        next
                        control-2
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!--Slide 3-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-3"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    />
                    <div
                    class="carousel-item absolute opacity-0"
                    style="
                        background-image: url(/assets/trx/trxSnow.jpg);
                        background-position: 50% 35% !important;
                    "
                    ></div>
                    <label
                    for="carousel-2"
                    class="
                        control-3
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-4"
                    class="
                        next
                        control-3
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!--Slide 4-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-4"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    />
                    <div
                    class="carousel-item absolute opacity-0"
                    style="
                        background-image: url(/assets/trx/trxPhoto1.jpg);
                    "
                    ></div>
                    <label
                    for="carousel-3"
                    class="
                        control-4
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-5"
                    class="
                        next
                        control-4
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!--Slide 5-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-5"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    />
                    <div
                    class="carousel-item absolute opacity-0"
                    style="
                        background-image: url(/assets/fleury/watchArm.jpg);
                    "
                    ></div>
                    <label
                    for="carousel-4"
                    class="
                        control-5
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-6"
                    class="
                        next
                        control-5
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!--Slide 6-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-6"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    />
                    <div
                    class="carousel-item absolute opacity-0"
                    style="
                        background-image: url(/assets/madegva/dragon/hero.jpg);
                    "
                    ></div>
                    <label
                    for="carousel-5"
                    class="
                        control-6
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-7"
                    class="
                        next
                        control-6
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!--Slide 7-->
                    <input
                    class="carousel-open"
                    type="radio"
                    id="carousel-7"
                    name="carousel"
                    aria-hidden="true"
                    hidden=""
                    />
                    <div
                    class="carousel-item absolute opacity-0"
                    style="
                        background-image: url(/assets/orkos/orkosBuckle.jpg);
                    "
                    ></div>
                    <label
                    for="carousel-6"
                    class="
                        control-7
                        w-10
                        h-10
                        ml-2
                        md:ml-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        left-0
                        my-auto
                    "
                    ><i class="fas fa-angle-left text-2xl"></i
                    ></label>
                    <label
                    for="carousel-1"
                    class="
                        next
                        control-7
                        w-10
                        h-10
                        mr-2
                        md:mr-10
                        absolute
                        cursor-pointer
                        hidden
                        font-bold
                        text-gray-300
                        hover:text-rosegold
                        rounded-full
                        leading-tight
                        text-center
                        z-10
                        inset-y-0
                        right-0
                        my-auto
                    "
                    ><i class="fas fa-angle-right text-2xl"></i
                    ></label>

                    <!-- Add additional indicators for each slide-->
                    <ol class="carousel-indicators">
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-1"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-2"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-3"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-4"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-5"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-6"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    <li class="inline-block mr-3">
                        <label
                        for="carousel-7"
                        class="
                            carousel-bullet
                            cursor-pointer
                            block
                            text-white
                            hover:text-rosegold
                        "
                        ><i class="fa-solid fa-square-full"></i></label
                        >
                    </li>
                    </ol>
                </div>
            </div>

            <div class="xl:px-20 xl:py-20">
                <div class="grid grid-cols-1 lg:grid-cols-2 px-6 lg:px-10 xl:pb-32 xl:pb-0 my-16 xl:my-0" id="madegvaIntro">
                    <div class="col-span-1 my-auto">
                        <img src="/assets/madegva/tiger/hero.jpg" class="h-full w-full rounded-lg shadow-rosegold object-cover" alt="M.A.D’E GVA Tigre watch picture">
                    </div>
                    <div class="col-span-1 text-gray-300 lg:px-16">
                        <span class="uppercase text-rosegold mt-6 mb-2 text-sm block">Introducing</span>
                        <h1 class="uppercase text-5xl font-lora gradient-rosegold mb-5">M.A.D’E GVA</h1>
                        <h2 class="mt-3 mb-8 leading-relaxed">M.A.D’E GVA for Monney Atelier D’Exclusivités is a private Swiss company based in Geneva, a mecca of the Swiss watch industry and where the largest watchmaking groups rub shoulders.<br /><br />Launched in 2014 by Sébastien Monney and specialists in crafts, the M.A.D’E team is dedicated to the manufacture and assembly of timepieces in limited production. Our expertise also allows us to carry out any type of mandate or project for companies as well as for individuals.</h2>
                        <router-link to="/madegva" class="border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 inline-block text-center">Explore more M.A.D’E GVA</router-link>
                    </div>
                </div>
                <div class="grid grid-cols-1 lg:grid-cols-3 gap-16 lg:gap-0">
                    <div class="col-span-1 text-gray-300 px-6 lg:px-10">
                        <img src="/assets/giberg/product/ladiesWatches/oloraRose1.jpg" class="rounded-lg shadow-rosegold" alt="Giberg Ahton picture">
                        <span class="uppercase text-rosegold mb-2 mt-6 text-sm block">Introducing</span>
                        <h1 class="uppercase text-5xl font-lora gradient-rosegold mb-5">Giberg</h1>
                        <h2 class="mt-3 mb-8 leading-relaxed">To bring the great and legendary tradition of the old master watchmakers and goldsmiths into the 21st century is one of the objectives of Giberg. With 40 years of experience as a supplier in the watch- and jewellery industry, Andreas Altmann and his team of highly skilled engineers and designers are creating solely ultra-limited masterpieces.</h2>
                        <router-link to="/giberg" class="border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 inline-block text-center">Explore more Giberg</router-link>
                    </div>
                    <div class="col-span-1 text-gray-300 px-6 lg:px-10 mt-2">
                        <img src="/assets/fleury/product/limited1.jpg" class="rounded-lg shadow-rosegold" alt="Fleury watch">
                        <span class="uppercase text-rosegold mb-2 mt-6 text-sm block">Introducing</span>
                        <h1 class="uppercase text-5xl font-lora gradient-rosegold mb-5">Fleury</h1>
                        <h2 class="mt-3 mb-8 leading-relaxed">Fleury is an independent, family-owned artisanal watch-manufacturing company located in the countryside of Geneva, it is animated by the desire of sharing core values such as: The mutual help of small independent craftsmen, trust, respect, fairness, sharing, creativity, development, evolution, education, independence and freedom.</h2>
                        <router-link to="/fleury" class="border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 inline-block text-center">Explore more Fleury</router-link>
                    </div>
                    <div class="col-span-1 text-gray-300 px-6 lg:px-10 mt-2">
                        <img src="/assets/orkos/orkosHomePic.jpg" class="rounded-lg shadow-rosegold" alt="Orkos watch buckle">
                        <span class="uppercase text-rosegold mb-2 mt-6 text-sm block">Introducing</span>
                        <h1 class="uppercase text-5xl font-lora gradient-rosegold mb-5">Orkos</h1>
                        <h2 class="mt-3 mb-8 leading-relaxed">Orkos wishes to protect lovers of fine timepieces, not only via the Watchlock® system, the very first micro-locking system for prestige clasps; but also via several technological accessories currently being designed. It is quite an adventure because this sector is completely new. Apart from Orkos, no company has yet taken the gamble of entering the sector of watchmaking security despite the many needs.</h2>
                        <router-link to="/orkos" class="border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 inline-block text-center">Explore more Orkos</router-link>
                    </div>
                </div>

                <div class="grid grid-cols-1 lg:grid-cols-2 py-12 px-6 lg:px-10 xl:pt-32 xl:pb-0" id="trxIntro">
                    <div class="col-span-1 my-auto">
                        <video autoplay playsinline muted loop class="w-full rounded-lg shadow-rosegold">
                            <source src="/assets/trx/trxVideo.mp4" type="video/mp4">
                        </video>
                    </div>
                    <div class="col-span-1 text-gray-300 lg:px-16">
                        <span class="uppercase text-rosegold mt-6 mb-2 text-sm block">Introducing</span>
                        <h1 class="uppercase text-5xl font-lora gradient-rosegold mb-5">TR-X, Time Research Experience</h1>
                        <h2 class="mt-3 mb-8 leading-relaxed">TR-X ghost, was directly inspired by the cult film of the 80s, and the clip of Ray Parker Jr, or in the feature film, colored Led effects dressed in the accessories of the clip. Fan of this film that rocked our childhood, the TR-X GHOST watch and exponentially ghostly with our luminescent skeleton.<br><br>This skeleton tourbillon man’s manufacturing and patented in Swiss is an object of great value. She was developed using watchmaking techniques with a special luminising application with SwissSuper-LumiNova (R) that meets the needs of individuals wishing to wear a watch on their wrist in a modern and avant-garde fashion, during important events, day and night.</h2>
                        <router-link to="/trx" class="border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 inline-block text-center">Explore more TR-X</router-link>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div> 
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
    name: "Home",
    components: {
        Navbar,
        Footer
    },
    methods: {
        changeBrand(brand) {
            document.querySelector("#brands").style.backgroundImage = `url('/assets/${brand}Photo.jpg')`;
        }
    }
}
</script>

<style scoped>
#homeContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

.carousel {
    margin-top: -90px;
}

.carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    height: calc(85vh + 90px);
    margin-top: -90px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3,
#carousel-4:checked ~ .control-4,
#carousel-5:checked ~ .control-5,
#carousel-6:checked ~ .control-6,
#carousel-7:checked ~ .control-7 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked
~ .control-1
~ .carousel-indicators
li:nth-child(1)
.carousel-bullet,
#carousel-2:checked
~ .control-2
~ .carousel-indicators
li:nth-child(2)
.carousel-bullet,
#carousel-3:checked
~ .control-3
~ .carousel-indicators
li:nth-child(3)
.carousel-bullet,
#carousel-4:checked
~ .control-4
~ .carousel-indicators
li:nth-child(4)
.carousel-bullet,
#carousel-5:checked
~ .control-5
~ .carousel-indicators
li:nth-child(5)
.carousel-bullet,
#carousel-6:checked
~ .control-6
~ .carousel-indicators
li:nth-child(6)
.carousel-bullet,
#carousel-7:checked
~ .control-7
~ .carousel-indicators
li:nth-child(7)
.carousel-bullet {
    color: #E6B474;
    /*Set to match the Tailwind colour you want the active one to be */
}

.fa-square-full {
    font-size: 0.5rem;
}

.col-span-1 img {
    height: 350px;
    object-fit: cover;
    object-position: center;
}

#madegvaIntro img {
    height: 400px;
}
</style>

<style>
.shadow-rosegold {
    -webkit-box-shadow: 0px 0px 10px 3px rgba(230,180,116,0.1); 
    box-shadow: 0px 0px 10px 3px rgba(230,180,116,0.1);
}
</style>