<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="ahtonContainer" class="text-gray-300 bg-wallgray">
        <div id="landing">
            <Lightbox @off-sounds="$emit('offSounds')" @on-sounds="$emit('onSounds')" ref="ahtonVid" :media="ahtonVid" />
            <div class="overlay flex flex-col justify-center items-end lg:px-20 xl:px-40 px-6">
                <div class="lg:w-1/3">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5">Ahton</h2>
                    <p>
                        For thousands of years humans are fascinated by dragons – by their mystical power, their strength and their confidence. Ahton is the keeper of this confidence and its unique story tells us about the destiny and the gifts of this precious creature.
                    </p>
                    <button @click="this.$refs.ahtonVid.toggleLightbox();" class="font-lora border-rosegold border mr-3 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear inline-block duration-75 text-center">Watch video</button>
                </div>
            </div>
        </div>

        <div id="sculpture">
            <div class="lg:px-20 xl:px-40 px-6 overlay text-center">
                <Lightbox @off-sounds="$emit('offSounds')" @on-sounds="$emit('onSounds')" ref="sculptureVid" :media="sculptureVid" />
                <h3 class="text-rosegold">Custom made masterpieces</h3>
                <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 lg:w-1/2 mx-auto">The Sculpture</h2>
                <p class="pt-5 pb-9 text-lg font-bold text-gray-300">The magic of an ancient creature reborn in a precious breathtaking sculpture!</p>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-10 text-gray-300 py-4">
                    <div class="col-span-1 text-center lg:text-left">
                        Ahton, Keeper of Confidence, is a one and only masterpiece of the highest possible craftsmanship. Incomparable, seductive and captivating in its expression, Ahton stands for the rebirth of an age-old mythical power.
                        <br><br>
                        Ahton’s name comes from the language of an old nordic tribe and means The Seeing One or The Thoughtful. Its power ist the one of an inner conviction based on the knowledge of its own strength.
                    </div>
                    <div class="col-span-1 text-center lg:text-left">
                        The uncompromised love for details and the rigorous use of only the most precious materials lend this sculpture its unique expressiveness. A dragon’s ark was especially designed as a sophisticated showcase for Ahton. Its mechanism ensures that, if so wished, the dragon remains dwelling in his hiding place, or else comes into view, to the astonishment of the assembled guests.
                        <br><br>
                        Ahton represents the credo of Giberg Swiss Noble Forge in its most intriguing and powerful form: to create unique masterpieces of the highest possible quality.
                    </div>
                </div>
                <button @click="this.$refs.sculptureVid.toggleLightbox();" class="font-lora border-rosegold border mr-3 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear inline-block duration-75 text-center">Watch video</button>
            </div>
        </div>
        
        <div id="amulet">
            <Lightbox ref="amulet1" :media="amulet1" />
            <Lightbox ref="amulet2" :media="amulet2" />
            <Lightbox ref="amulet3" :media="amulet3" />
            <Lightbox ref="enquiryForm" :media="enquiryForm" />

            <div class="overlay lg:px-20 xl:px-40 px-6">
                <div class="lg:w-1/3">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 w-2/3">Ahton’s Amulet</h2>
                    <p class="mb-16">A symbol of unshakeable confidence. The amulet is charged with the power of Ahton during a night of your choice. For its bearer, success will shine on life.</p>
                </div>
                <div class="lg:w-1/2">
                    <div>
                        <div class="flex gap-6">
                            <div>
                                <img @click="this.$refs.amulet1.toggleLightbox()" src="/assets/giberg/product/ahton/amulet1.png" class="cursor-pointer border hover:scale-105 transform transition ease-linear duration-75 amuletImg border-rosegold" alt="Amulet 1" />
                                <p class="mt-4">13gr of 18K<br>Yellow Gold<br>268 brilliants<br>(total 0,8 Carat)</p>
                                <button @click="this.$refs.enquiryForm.toggleLightbox(`Ahton's Amulet 0.8 Carat`);" class="font-light border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-full xl:w-2/3 text-center">Enquire now</button>
                            </div>
                            <div>
                                <img @click="this.$refs.amulet2.toggleLightbox()" src="/assets/giberg/product/ahton/amulet2.png" class="cursor-pointer border hover:scale-105 transform transition ease-linear duration-75 amuletImg border-rosegold" alt="Amulet 2" />
                                <p class="mt-4">13gr of 18K<br>Yellow Gold<br>183 brilliants<br>(total 0,5 Carat)</p>
                                <button @click="this.$refs.enquiryForm.toggleLightbox(`Ahton's Amulet 0.5 Carat`);" class="font-light border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-full xl:w-2/3 text-center">Enquire now</button>
                            </div>
                            <div>
                                <img @click="this.$refs.amulet3.toggleLightbox()" src="/assets/giberg/product/ahton/amulet3.png" class="cursor-pointer border hover:scale-105 transform transition ease-linear duration-75 amuletImg border-rosegold" alt="Amulet 3" />
                                <p class="mt-4">13gr of 18K<br>Yellow Gold<br>90 brilliants<br>(total 0,36 Carat)</p>
                                <button @click="this.$refs.enquiryForm.toggleLightbox(`Ahton's Amulet 0.36 Carat`);" class="font-light border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-full xl:w-2/3 text-center">Enquire now</button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
        <div id="saga">
            <div class="overlay flex flex-col items-end lg:px-20 xl:px-40 px-6">
                <div class="lg:w-1/3">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-8">The Saga</h2>
                    <p>
                        The saga of Ahton, Keeper of Confidence, tells the secret behind this unique creature. It’s a story about destiny and a fable about wisdom. But this stunning tale exists only once. The Saga of Ahton is handwritten on parchment and comes in a book made with gold and goatskin.
                        <br><br>
                        This book is hidden in the Dragon’s Ark, an exclusive cabinet whose intricate mechanics allow the sculpture to ascend into view at the push of a button. Further accessories complement this luxurious work – from the leather glove to the carrying bag and the flight case, everything has been developed and fine-tuned as an ensemble.
                    </p>
                </div>
            </div>
        </div>

        <div id="craftsmanship">
            <div class="lg:px-20 xl:px-40 px-6">
                <Lightbox @off-sounds="$emit('offSounds')" @on-sounds="$emit('onSounds')" ref="craftsmanshipVid" :media="craftsmanshipVid" />
                <div class="lg:w-1/2" id="craftsmanshipDesc">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-4xl lg:text-5xl uppercase mb-8">The Craftsmanship</h2>
                    <p class="pb-4">This unique sculpture of Ahton, Keeper of Confidence, measuring 25×30×24 cm (length × width × height) was designed and crafted with great care for 5 years by the team of Giberg Swiss Noble Forge.</p>
                    <p class="pt-2">Cast in 5.090 kg of green-yellow and red-yellow solid gold (Au 750), studded with 7739 G-H coloured diamonds (clarity grade: VS1-SI1) weighing a total of 108.655 Ct and possessing a brilliant top cut, this precious creature has eyes that are formed by two rubies of navette cabochon-cut that altogether weigh 0.48 Ct.</p>
                    <button @click="this.$refs.craftsmanshipVid.toggleLightbox();" class="font-lora border-rosegold border py-1 px-5 my-8 inline-block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Making Of</button>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Lightbox from "../components/Lightbox";

export default {
    name: "GibergAhton",
    data() {
        return {
            ahtonVid: [ 
                {
                    type: "video",
                    src: "/assets/giberg/ahtonVid.mp4"
                }
            ],
            craftsmanshipVid: [
                {
                    type: "video",
                    src: "/assets/giberg/ahtonCraftsmanshipVid.mp4"
                }
            ],
            sculptureVid: [
                {
                    type: "video",
                    src: "/assets/giberg/ahtonSculptureVid.mp4"
                }
            ],
            amulet1: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ahton/amulet1.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ahton/amulet1alt.png"
                }
            ],
            amulet2: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ahton/amulet2.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ahton/amulet2alt.png"
                }
            ],
            amulet3: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ahton/amulet3.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/ahton/amulet3alt.jpg"
                }
            ],
            enquiryForm: [
                {
                    type: "form",
                    product: "Ahton's Amulet 0.8 Carat"
                }
            ]
        }
    },
    components: {
        Navbar,
        Footer,
        Lightbox
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #ahtonContainer #landing {
        background-position: 40% 50% !important;
    }

    #ahtonContainer #amulet {
        background-position: 70% !important;
    }
    
    #ahtonContainer #landing .overlay {
        background-color:rgba(0, 0, 0, 0.6);
    }
}

@media only screen and (min-device-width: 992px) {
    #ahtonContainer #landing .overlay {
        background-color:rgba(0, 0, 0, 0);
    }
}

#ahtonContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#ahtonContainer .overlay {
    background-color:rgba(0, 0, 0, 0.6);
    padding-top: 90px !important;
    min-height: 80vh;
}

#ahtonContainer #landing {
    background: url("/assets/giberg/ahtonLanding.png");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
}

#ahtonContainer #sculpture {
    background: url("/assets/giberg/product/ahton/sculpture.jpg");
    background-size: cover;
    background-position: 50% 30%;
    background-attachment: fixed;
}

#ahtonContainer #amulet {
    background: url("/assets/giberg/product/ahton/amulet.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10% 40%;
    min-height: calc(80vh + 90px);
}

#ahtonContainer #saga {
    background: url("/assets/giberg/product/ahton/saga.jpg");
    background-size: cover;
    background-position: 10% 40%;
    min-height: 80vh;
}

#ahtonContainer #craftsmanship {
    background: url("/assets/giberg/product/ahton/craftsmanship.jpg");
    background-size: cover;
    background-position: 40% 50%;
    min-height: 80vh;
    padding-top: 10rem;
}
</style>