<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="ambassContainer">
        <div class="text-gray-300 bg-wallgray">
            <div id="ambassHeader" class="grid lg:grid-cols-2 px-6 lg:px-20 xl:px-40 lg:pb-32">
                    <div class="mt-8 order-2 lg:order-1 lg:col-span-1 lg:my-auto">
                        <h3 class="text-rosegold">An ambassador passionate about technology and fashion</h3>
                        <h2 class="font-lora gradient-rosegold text-gray-300 text-6xl uppercase mt-2 mx-auto">Cédric Bakambu</h2>

                        <p class="mt-10 text-lg leading-snug">To launch the TR-X Ghost , the brand has chosen to partner with Cédric Bakambu, a 30 year old Franco-Congolese footballer who is currently playing for Beijing Guoan, one of the main teams in the Chinese Premier League.</p>
                        <div class="flex mt-10 lg:mb-5 mb-16">
                            <button v-if="!activeVid" @click="toggleVideo" class="font-lora border-rosegold border py-1 px-1 md:px-2 xl:px-0 xl:w-1/3 block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Watch the movie</button>
                            <button v-if="activeVid" @click="toggleVideo" class="font-lora border-rosegold border py-1 px-1 md:px-2 xl:px-0 xl:w-1/3 block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Close the movie</button>
                            <a href="/assets/trx/bakagoal.pdf" ref="noopener" target="_blank" class="font-lora border-rosegold border ml-5 py-1 w-1/2 md:w-1/3 lg:w-1/2 block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Bakagoal Presentation</a>
                        </div>
                    </div>
                    <div class="order-1 lg:order-2 lg:col-span-1">
                        <video controls muted loop class="opacity-0 md:w-4/5 mx-auto lg:ml-auto border-2 shadow border-rosegold">
                            <source src="/assets/trx/trxVideoAmbassador.mp4" type="video/mp4">
                        </video>
                    </div>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-3">
                <div class="col-span-1 md:border-0 border-r border-rosegold order-1">
                    <img class="object-cover h-full w-full" src="/assets/trx/trxAmbassPic1.jpg" alt="Anthony photo">
                </div>
                <div class="md:col-span-1 col-span-2 order-3 md:order-2 flex flex-col h-full justify-around py-14 px-6 md:py-24 md:px-14 my-auto md:border-l-2 md:border-r-2 border-rosegold">
                    <img src="/assets/trx/trxLogo.svg" alt="TR-X logo" class="object-contain h-full w-full mx-auto">
                    <p class="text-2xl text-center md:my-0 my-8">A <span class="gradient-rosegold">stand-out</span> partnership<br>which is also the result of an <br>authentic encounter.</p>
                    <img src="/assets/trx/bakagLogo.png" alt="Bakagoal logo" class="object-contain h-full w-full mx-auto">
                </div>
                <div class="col-span-1 order-2 md:order-3 md:border-0 border-l border-rosegold">
                    <img class="object-cover h-full w-full" src="/assets/trx/trxAmbass2.jpg" alt="TR-X Ambassador photo">
                </div>
            </div>

            <div class="lg:grid lg:grid-cols-2 lg:px-20xl:px-40 pb-10 lg:py-20">
                <div class="col-span-2 lg:w-2/3 mx-auto pb-12">
                    <img src="/assets/trx/trxAmbass1.jpg" class="object-cover h-full w-full border-rosegold border-t-2 border-b-2 lg:border-2" alt="TR-X Ambassador photo">
                </div>
                <div class="col-span-1 px-6 lg:mr-10">
                    <h5 class="lg:w-1/4 gradient-rosegold text-xl tracking-wide">Cédric Bakambu:</h5>
                    <blockquote class="mt-2 leading-relaxed">“We met through a friend who runs a specialized 'Signature' agency. Several things impressed me and I was immediately hooked. First and foremost I appreciate beautiful watches and fashion. Secondly, I like projects and challenges. I like the TR-X Ghost because it is a real challenge. Moreover, the world of new technology fascinates me. In this respect, the watch is truly an avant-garde product. Furthermore, I must say that the relationship between Anthony, his partner and myself immediately got off on the right foot."</blockquote>
                    <p class="mt-5">Cédric Bakambu is also delighted to be one of the first to wear the watch and to provide the brand with the benefit of his network of knowledge in the world of European and Chinese football.</p>
                </div>
                <div class="col-span-1 px-6 mt-8 lg:mt-0 lg:ml-10">
                    <h5 class="lg:w-1/4 gradient-rosegold text-xl tracking-wide">Anthony Amato:</h5>
                    <blockquote class="mt-2 leading-relaxed">“Cédric was immediately interested in the product and this allowed us to go forward with the right feeling. Like us, he shares a passion for new technology and the principles of beauty that we defend. I must say he impressed me when he came to visit our workshop in Switzerland. When he sat down at the bench, we gave him the watchmaker's tools needed take apart and reassemble the watch components, he showed remarkable composure and precision in every task he undertook."</blockquote>
                    <p class="mt-8 lg:mt-5 text-sm font-lora italic">
                        Journalist: Thierry Brandt<br>
                        Artistic Director and Photographer: Johann Sauty<br>
                        Sports and artistic agent: Signature SWISS LUXURY - Tresor Bukusu & Sergio Domingos
                    </p>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-1 lg:gap-5">
                <div class="col-span-1">
                    <img src="/assets/trx/trxAmbass4.jpg" class="h-full object-cover object-right" alt="TR-X Ambassador Photo">
                </div>
                <div class="col-span-1">
                    <img src="/assets/trx/trxAmbass3.jpg" class="h-full object-cover object-center" alt="TR-X Ambassador Photo">
                </div>
                <div class="col-span-1">
                    <img src="/assets/trx/trxAmbass5.jpg" class="h-full object-cover trxAmbass5" alt="TR-X Ambassador Photo">
                </div>
            </div>

            <div class="py-10 flex justify-center">
                <router-link to="/trx" class="font-lora border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75">Explore more TR-X</router-link>
            </div>
        </div>

    </div>

    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import gsap from "gsap";
export default {
    name: "TrxAmbassador",
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            activeVid: false,
        }
    },
    methods: {
        toggleVideo() {
            const video = document.querySelector("#ambassHeader video");
            if (this.activeVid) {
                gsap.fromTo("video", { opacity: 1, scale: 1 }, { opacity: 0, scale: 0, duration: 0.4 })
                this.activeVid = false;
            } else {
                gsap.fromTo("video", { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, duration: 0.45 })
                video.play();
                window.scrollTo(0, 0);
                this.activeVid = true;
            }
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #ambassHeader {
        padding-top: calc(90px + 2rem);
        background: url("/assets/trx/trxAmbassBgMobile.jpg");
        background-position: center;
    }
}

@media only screen and (min-device-width: 1024px) {
    #ambassHeader {
        padding-top: calc(90px + 2rem);
        background: url("/assets/trx/trxAmbassBg.jpg");
        background-position: 75%;
    }
}

@media only screen and (min-width : 1025px) {
    #ambassHeader {
        padding-top: 2rem;
        background: url("/assets/trx/trxAmbassBg.jpg");
        background-position: center;
    }
}

.trxAmbass5 {
    object-position: 85%;
}

#ambassContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#ambassHeader {
    margin-top: -90px;
    background-size: cover;
    background-attachment: fixed;
}
</style>