<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergLJContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Ladies's jewellery</h3>
                    <p class="mt-2 text-lg leading-snug">
                        With devotion and attention to detail, Giberg creates pieces of jewelry of astonishing beauty that ideally accentuate the individual personality with their sensual radiance.
                    </p>
                    <router-link to="#collections" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore collections</router-link>
                    <p class="leading-snug text-sm">Official agent/retailer for Australia, Indonesia, Malaysia, New Zealand, Philippines, Thailand, Vietnam, Cambodia, Myanmar, Laos, Middle East, India, China, Japan, Korea, USA and Latin America.</p>
                </div>
            </div>
        </div>

        <div id="collections">
            <Lightbox ref="enquiryForm" :media="enquiryForm" />

            <div id="amphora">
                <div class="overlay flex justify-end xl:px-40 px-6 lg:px-20">
                    <div class="lg:w-2/5">
                        <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 w-2/3">Amphora</h2>
                        <p>A dazzling interplay of precious metals and diamonds – combined into a circle of pure passion.</p>
                        <button @click="currentCollection = 'amphora'" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</button>
                    </div>
                </div>
            </div>

            <!-- Amphora lightboxes -->
            <div v-if="currentCollection == 'amphora'">
                <Lightbox ref="amphoraWGWD" :media="amphoraWGWD" />
                <Lightbox ref="amphoraWGBD" :media="amphoraWGBD" />
                <Lightbox ref="amphoraWGCD" :media="amphoraWGCD" />
                <Lightbox ref="amphoraWG" :media="amphoraWG" />
                <Lightbox ref="amphoraYGWD" :media="amphoraYGWD" />
                <Lightbox ref="amphoraYGBD" :media="amphoraYGBD" />
                <Lightbox ref="amphoraYGCD" :media="amphoraYGCD" />
                <Lightbox ref="amphoraYG" :media="amphoraYG" />
                <Lightbox ref="amphoraRGWD" :media="amphoraRGWD" />
                <Lightbox ref="amphoraRGBD" :media="amphoraRGBD" />
                <Lightbox ref="amphoraRGCD" :media="amphoraRGCD" />
                <Lightbox ref="amphoraRG" :media="amphoraRG" />
            </div>

            <div v-if="currentCollection == 'amphora'" class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10 px-6 xl:px-40 lg:px-20 py-5">
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraWGWD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraWGWDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – White Gold, White Diamonds</h3>
                    <p>
                        Whitegold 26, 4gr<br>
                        64 White Diamonds<br>
                        0,39ct F-G VVS-VS<br>
                        Single black rubber band<br>
                        S$17,540 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora White Gold, White Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraWGBD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraWGBDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – White Gold, Black Diamonds</h3>
                    <p>
                        Whitegold 26,4gr<br>
                        64 black Diamonds<br>
                        0,45ct<br>
                        Single black rubber band<br>
                        S$17,230 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora White Gold, Black Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraWGCD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraWGCDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – White Gold, Champagne Diamonds</h3>
                    <p>
                        Whitegold 26,4gr<br>
                        64 Champagne Diamonds<br>
                        0,39ct<br>
                        Single black rubber band<br>
                        S$17,160 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora White Gold, Champagne Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraWG.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraWGFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – White Gold</h3>
                    <p>
                        Whitegold 27,25gr<br>
                        Single black rubber band<br>
                        S$14,520 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora White Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraYGWD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraYGWDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Yellow Gold, White Diamonds</h3>
                    <p>
                        Yellow Gold 24,00gr<br>
                        64 White Diamonds<br>
                        0,39ct F-G VVS-VS<br>
                        S$14,970 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Yellow Gold, White Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraYGBD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraYGBDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Yellow Gold, Black Diamonds</h3>
                    <p>
                        Yellow Gold 24,00gr<br>
                        64 black Diamonds<br>
                        0,45ct<br>
                        Single black rubber band<br>
                        S$14,650 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Yellow Gold, Black Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraYGCD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraYGCDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Yellow Gold, Champagne Diamonds</h3>
                    <p>
                        Yellow Gold 24,00gr<br>
                        64 Champagne Diamonds<br>
                        0,39ct<br>
                        Single black rubber band<br>
                        S$14,590 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Yellow Gold, Champagne Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraYG.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraYGFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Yellow Gold</h3>
                    <p>
                        Yellow Gold 24,90gr<br>
                        Single black rubber band<br>
                        S$11,920 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Yellow Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraRGWD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraRGWDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Red Gold, White Diamonds</h3>
                    <p>
                        Red Gold 24,00gr<br>
                        64 White Diamonds<br>
                        0,39ct F-G VVS-VS<br>
                        Single black rubber band<br>
                        S$14,970 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraRGBD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraRGBDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Red Gold, Black Diamonds</h3>
                    <p>
                        Red Gold 24,00gr<br>
                        64 black Diamonds<br>
                        0,45ct<br>
                        Single black rubber band<br>
                        S$14,650 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Red Gold, Black Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraRGCD.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraRGCDFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Red Gold, Champagne Diamonds</h3>
                    <p>
                        Red Gold 24,00gr<br>
                        64 Champagne Diamonds<br>
                        0,39ct<br>
                        Single black rubber band<br>
                        S$14,590 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Red Gold, Champagne Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.amphoraRG.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/amphoraRGFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Amphora bracelet" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Amphora – Red Gold</h3>
                    <p>
                        Red Gold 24,90gr<br>
                        Single black rubber band<br>
                        S$11,920 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Amphora Red Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
            </div>
            
            <div class="grid grid-cols-1 lg:grid-cols-3">
                <div id="filigreeHeader" class="col-span-1">
                    <div class="overlay pl-6 pr-6 lg:pl-10 lg:pr-10 flex flex-col justify-end">
                        <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5">Ring Filigree</h2>
                        <p class="lg:w-2/3">A floating maze of rich detail thanks to unprecedented craftsmanship.</p>
                        <router-link to="#filigree" @click="currentCollection = 'filigree'" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</router-link>
                    </div>
                </div>
                <div id="swanHeader" class="col-span-1">
                    <div class="overlay pl-6 pr-6 lg:pl-10 lg:pr-10 flex flex-col justify-end">
                        <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 w-2/3">Swan</h2>
                        <p class="lg:w-2/3">Graceful as a ballerina, and an uncompromising testament to women's grace.</p>
                        <router-link to="#swan" @click="currentCollection = 'swan'" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</router-link>
                    </div>
                </div>
                <div id="heartHeader" class="col-span-1">
                    <div class="overlay pl-6 pr-6 lg:pl-10 lg:pr-10 flex flex-col justify-end">
                        <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 w-2/3">Heart</h2>
                        <p class="lg:w-2/3">I am yours: The ultimate confession of love – presented in a breathtaking manner.</p>
                        <router-link to="#heart" @click="currentCollection = 'heart'" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</router-link>
                    </div>
                </div>
            </div>

            <!-- Filigree lightboxes -->
            <div v-if="currentCollection == 'filigree'">
                <Lightbox ref="filigree1" :media="filigree1" />
                <Lightbox ref="filigree2" :media="filigree2" />
                <Lightbox ref="filigree3" :media="filigree3" />
                <Lightbox ref="filigree4" :media="filigree4" />
                <Lightbox ref="filigree5" :media="filigree5" />
                <Lightbox ref="filigree6" :media="filigree6" />
                <Lightbox ref="filigree7" :media="filigree7" />
            </div>

            <div v-if="currentCollection == 'filigree'" id="filigree" class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10 px-6 xl:px-40 lg:px-20 py-5">
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree1.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree1.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 1</h3>
                    <p>
                        4.2 grams of 18K yellow gold<br>
                        S$3,050 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 1');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree2.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree2.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 2</h3>
                    <p>
                        5.3 grams of 18K yellow gold<br>
                        13 diamonds (total 0.66ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$5,600 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 2');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree3.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree3.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 3</h3>
                    <p>
                        5.8 grams of 18K yellow gold<br>
                        56 diamonds (total 0.32ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$5,770 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 3');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree4.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree4.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 4</h3>
                    <p>
                        6 grams of 18K yellow gold<br>
                        28 diamonds (total 1.32ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$7,600 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 4');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree5.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree5.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 5</h3>
                    <p>
                        6.6 grams of 18K yellow gold<br>
                        42 diamonds (total 1.98ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$9,920 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 5');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree6.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree6.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 6</h3>
                    <p>
                        5.6 grams of 18K yellow gold<br>
                        84 diamonds (total 1.63ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$10,370 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 6');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.filigree7.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/filigree7.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Ring Filigree" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Ring Filigree – 7</h3>
                    <p>
                        6.3 grams of 18K yellow gold<br>
                        42 diamonds (total 1.98ct)<br>
                        Stone quality: I-F-VVS<br>
                        56 ruby (0.215ct)<br>
                        S$15,110 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Ring Filigree 7');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
            </div>

            <!-- Swan lightboxes -->
            <div v-if="currentCollection == 'swan'">
                <Lightbox ref="swanRing" :media="swanRing" />
                <Lightbox ref="swanEarringsW" :media="swanEarringsW" />
                <Lightbox ref="swanEarringsR" :media="swanEarringsR" />
            </div>

            <div v-if="currentCollection == 'swan'" id="swan" class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10 px-6 xl:px-40 lg:px-20 py-5">
                <div class="group col-span-1">
                    <img @click="this.$refs.swanRing.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/swanRing.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Swan ring" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Swan – Ring</h3>
                    <p>
                        23 gr of White gold 750<br>
                        Set with 30 baguettes (1,71 ct) and 572 diamonds (5,47 carat)<br>
                        2 black diamonds as eyes (0,01ct)
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Swan Ring');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.swanEarringsW.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/swanEarringsW.png" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Swan earrings" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Swan – Feather-earrings White</h3>
                    <p>
                        29 gr of White gold 750<br>
                        Set with 680 diamonds (9,02 carat)
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Swan Feather-earrings White');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.swanEarringsR.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/swanEarringsR.png" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Swan earrings" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Swan – Feather-earrings Rose</h3>
                    <p>
                        29 gr of Rose gold 750
                        Set with 680 diamonds (9,02 carat)
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Swan Feather-earrings Rose');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
            </div>

            <!-- Heart lightboxes-->
            <div v-if="currentCollection == 'heart'">
                <Lightbox ref="heartWG" :media="heartWG" />
                <Lightbox ref="heartYG" :media="heartYG" />
            </div>

            <div v-if="currentCollection == 'heart'" id="heart" class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10 px-6 xl:px-40 lg:px-20 py-5">
                <div class="group col-span-1">
                    <img @click="this.$refs.heartWG.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/heartWG.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Heart pendant" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Heart – Pendant (crowned), White Gold</h3>
                    <p>
                        8 grams of 18K white gold<br>
                        3 diamonds (total 0.07ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$5,070 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Heart Pendant (crowned), White Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.heartYG.toggleLightbox();" src="/assets/giberg/product/ladiesJewellery/heartYG.jpg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Heart pendant" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Heart – Pendant, Yellow Gold</h3>
                    <p>
                        15 grams of 18K yellow gold<br>
                        72 diamonds (total 1.12ct)<br>
                        Stone quality: I-F-VVS<br>
                        S$12,300 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Heart Pendant Yellow Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
            </div>

            <div id="cross">
                <div class="overlay px-6 flex xl:px-40 lg:px-20">
                    <div class="lg:w-2/5">
                        <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 w-2/3">Cross</h2>
                        <p>Unmatched in sophistication and complexity – created with humility and uncompromising dedication.</p>
                        <button @click="currentCollection = 'cross'" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</button>
                    </div>
                </div>
            </div>

            <!-- Cross lightboxes -->
            <div v-if="currentCollection == 'cross'">
                <Lightbox ref="crossWhiteGold1" :media="crossWhiteGold1" />
                <Lightbox ref="crossYellowGold1" :media="crossYellowGold1" />
                <Lightbox ref="crossWhiteGold33" :media="crossWhiteGold33" />
                <Lightbox ref="crossYellowGold33" :media="crossYellowGold33" />
                <Lightbox ref="crossAllBlackRuby" :media="crossAllBlackRuby" />
                <Lightbox ref="crossAllBlackSapphire" :media="crossAllBlackSapphire" />
                <Lightbox ref="crossAllBlackDiamond" :media="crossAllBlackDiamond" />
            </div>

            <div v-if="currentCollection == 'cross'" class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10 px-6 xl:px-40 lg:px-20 py-5">
                <div class="group col-span-1">
                    <img @click="this.$refs.crossWhiteGold1.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossWhiteGold1Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross White Gold 1 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – White Gold, 1 diamond</h3>
                    <p>
                        White Gold Edition with 1 diamond<br>
                        Cross: 11 grams of 18K White-Gold<br>
                        One brilliant-cut Diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        Chain: 9,1 grams of 18K White-Gold<br>
                        S$11,610 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross White Gold, 1 diamond');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossYellowGold1.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossYellowGold1Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross Yellow Gold 1 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – Yellow Gold, 1 diamond</h3>
                    <p>
                        Yellow Gold Edition with 1 diamond<br>
                        Cross: 10 grams of 18K Yellow-Gold (3N)<br>
                        One brilliant-cut diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        Chain: 9,1 grams of 18K White-Gold<br>
                        S$10,660 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross Yellow Gold, 1 diamond');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossWhiteGold33.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossWhiteGold33Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross White Gold 33 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – White Gold, 33 diamonds</h3>
                    <p>
                        White Gold Edition with 33 diamonds<br>
                        Cross: 11 grams of 18K White-Gold<br>
                        One brilliant-cut diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        32 brilliant-cut diamonds: 0,90mm / 0,08ct / F-G IF-VVS<br>
                        Chain: 9,1 grams of 18K White-Gold<br>
                        S$13,360 — GST included

                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross White Gold, 33 diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossYellowGold33.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossYellowGold33Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross Yellow Gold 33 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – Yellow Gold, 33 diamonds</h3>
                    <p>
                        Yellow Gold Edition with 33 diamonds<br>
                        Cross: 10 grams of 18K Yellow-Gold (3N)<br>
                        One brilliant-cut diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        32 brilliant-cut diamonds: 0,90mm / 0,08ct / F-G IF-VVS<br>
                        Chain: 9 grams of 18K Yellow-Gold<br>
                        S$12,410 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross Yellow Gold, 33 diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossAllBlackRuby.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossAllBlackRubyFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross All Black Ruby" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – All Black, Ruby Edition</h3>
                    <p>
                        Stainless Steel black pvd-coated<br>
                        One Ruby Cabochon (0.36Ct. / 3.8mm)<br>
                        S$4,200 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross All Black, Ruby');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossAllBlackSapphire.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossAllBlackSapphireFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross All Black Sapphire" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – All Black, Sapphire Edition</h3>
                    <p>
                        Stainless Steel black pvd-coated<br>
                        One Sapphire Cabochon (0.41Ct. / 3.8mm)<br>
                        S$4,030 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross All Black, Sapphire');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossAllBlackDiamond.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossAllBlackDiamondFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross All Black Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – All Black, Diamonds Edition</h3>
                    <p>
                        Stainless Steel black pvd-coated<br>
                        One brilliant-cut diamond (4,5mm / 0,35ct / F-G IF-VVS)<br>
                        S$7,910 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross All Black, Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 text-center">Enquire now</button>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergLJewellery",
    components: {
        Navbar,
        Lightbox,
        Footer
    },
    data() {
        return {
            currentCollection: null,
            crossWhiteGold1: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold11.jpeg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold12.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold13.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold14.jpg"
                },
            ],
            crossYellowGold1: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold11.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold12.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold13.jpg"
                }
            ],
            crossWhiteGold33: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold331.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold332.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold333.jpg"
                }
            ],
            crossYellowGold33: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold331.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold332.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold333.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold334.jpg"
                }
            ],
            crossAllBlackRuby: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackRuby1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackRuby2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackRuby3.jpg"
                },
            ],
            crossAllBlackSapphire: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackSapphire1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackSapphire2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackSapphire3.jpg"
                },
            ],
            crossAllBlackDiamond: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackDiamond1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackDiamond2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackDiamond3.jpg"
                },
            ],
            amphoraWGWD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraWGWD1.jpg"
                },
            ],
            amphoraWGBD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraWGBD1.jpg"
                },
            ],
            amphoraWGCD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraWGCD1.jpg"
                },
            ],
            amphoraWG: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraWG1.jpg"
                },
            ],
            amphoraYGWD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraYGWD1.jpg"
                },
            ],
            amphoraYGBD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraYGBD1.jpg"
                },
            ],
            amphoraYGCD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraYGCD1.jpg"
                },
            ],
            amphoraYG: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraYG1.jpg"
                },
            ],
            amphoraRGWD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraRGWD1.jpg"
                },
            ],
            amphoraRGBD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraRGBD1.jpg"
                },
            ],
            amphoraRGCD: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraRGCD1.jpg"
                },
            ],
            amphoraRG: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/amphoraRG1.jpg"
                },
            ],
            enquiryForm: [
                {
                    type: "form"
                }
            ],
            swanRing: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/swanRing.jpg"
                },
            ],
            swanEarringsW: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/swanEarringsW.png"
                },
            ],
            swanEarringsR: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/swanEarringsR.png"
                },
            ],
            heartWG: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/heartWG.jpg"
                },
            ],
            heartYG: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/heartYG.jpg"
                },
            ],
            filigree1: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree1.jpg"
                },
            ],
            filigree2: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree2.jpg"
                },
            ],
            filigree3: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree3.jpg"
                },
            ],
            filigree4: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree4.jpg"
                },
            ],
            filigree5: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree5.jpg"
                },
            ],
            filigree6: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree6.jpg"
                },
            ],
            filigree7: [
                {
                    type: "image",
                    src: "/assets/giberg/product/ladiesJewellery/filigree7.jpg"
                },
            ]
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergLJContainer #collections .grid .overlay {
        min-height: 50vh;
    }
    #gibergLJContainer .overlay {
        min-height: 70vh;
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergLJContainer #collections .grid .overlay {
        min-height: 60vh;
    }
    #gibergLJContainer .overlay {
        min-height: 80vh;
    }
}

#gibergLJContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergLJContainer .overlay {
    background-color:rgba(0, 0, 0, 0.55);
    padding-top: calc(90px + 5vh) !important;
}

#gibergLJContainer #landing {
    background: url("/assets/giberg/ladiesJewelleryLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
    background-position: 45% 50%;
}

#gibergLJContainer #landing .overlay {
    min-height: calc(80vh + 90px);
    background-color:rgba(0, 0, 0, 0.65);
}

#gibergLJContainer #collections #amphora {
    background: url("/assets/giberg/ladiesJewelleryAmphora.png");
    background-size: cover;
    background-position: center;
}

#gibergLJContainer #collections .grid .overlay {
    background-color:rgba(0, 0, 0, 0.7) !important;
    padding-top: 0px !important;
}

#gibergLJContainer #collections #filigreeHeader {
    background: url("/assets/giberg/ladiesJewelleryFiligree.png");
    background-size: cover;
}

#gibergLJContainer #collections #swanHeader {
    background: url("/assets/giberg/ladiesJewellerySwan.png");
    background-size: cover;
}

#gibergLJContainer #collections #filigree img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

#gibergLJContainer #collections #swan img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

#gibergLJContainer #collections #heart img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

#gibergLJContainer #collections #heartHeader {
    background: url("/assets/giberg/ladiesJewelleryHeart.png");
    background-size: cover;
}

#gibergLJContainer #collections #cross {
    background: url("/assets/giberg/ladiesJewelleryCross.png");
    background-size: cover;
    background-position: center;
}
</style>
