<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergArtContainer" class="text-gray-300 bg-wallgray">
        <div id="ahton" class="flex flex-col lg:items-end px-6 lg:px-40">
            <div id="ahtonDesc" class="mt-24 lg:mt-36">
                <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase">Ahton</h2>
                <p class="mt-5">The magic of an ancient creature reborn in a precious breathtaking sculpture!</p>
                <router-link to="/giberg/ahton" class="font-lora border-rosegold border py-1 px-5 my-8 inline-block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Continue</router-link>
            </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2">
            <div id="customMade" class="col-span-1 flex items-center">
                <div class="lg:w-1/2 pl-6 lg:pl-20 pt-10 lg:pt-0">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-4xl uppercase">Custom Made Masterpieces</h2>
                    <p class="mt-5">Breaking boundaries to create infinite possibilities</p>
                    <router-link to="/giberg/custom" class="font-lora border-rosegold border py-1 px-5 my-8 inline-block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Continue</router-link>
                </div>
            </div>
            <div id="creators" class="col-span-1 flex items-center">
                <div class="lg:w-1/2 pl-6 lg:pl-20 pt-10 lg:pt-0">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-4xl uppercase">Creators</h2>
                    <p class="mt-5">With passion and precision to perfection</p>
                    <router-link to="/giberg/creators" class="font-lora border-rosegold border py-1 px-5 my-8 inline-block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Continue</router-link>
                </div>

            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


export default {
    name: "GibergArt",
    components: {
        Navbar,
        Footer
    }
}
</script>

<style scoped>
#gibergArtContainer #ahton {
    background: url("/assets/giberg/ahtonArt.jpg");
    min-height: calc(80vh + 90px);
    margin-top: -90px;
    background-size: cover;
    background-position: center;
    padding-top: 90px;
}

#gibergArtContainer #ahtonDesc {
    width: 15em;
}

#gibergArtContainer .grid {
    min-height: 400px;
}


#gibergArtContainer #customMade {
    background: url("/assets/giberg/customMadeArt.png");
    background-size: cover;
}

#gibergArtContainer #creators {
    background: url("/assets/giberg/creatorsArt.png");
    background-size: cover;
}
</style>