<template>
    <form id="contactForm" class="mx-auto text-gray-800" @submit.prevent>
        <div class="lg:flex">
            <input type="text" v-model="firstName" name="firstName" placeholder="First Name" class="mb-3 lg:mr-2 rounded-lg block w-full px-4 py-2" />
            <input type="text" v-model="lastName" name="lastName" placeholder="Last Name" class="mb-3 lg:ml-2 rounded-lg block w-full px-4 py-2" />
        </div>

        <div class="lg:flex">
            <input type="text" v-model="email" name="email" placeholder="Email" class="mb-3 lg:mr-2 rounded-lg block w-full px-4 py-2" />
            <input type="text" v-model="phone" name="phone" placeholder="Phone" class="mb-3 lg:ml-2 rounded-lg block w-full px-4 py-2" />
        </div>

        <textarea id="message" v-model="message" name="message" placeholder="Your Message" rows="8" class="rounded-lg mt-2 block w-full px-4 py-2" />

        <div class="text-center mt-6">
            <p v-show="!submitted" class="text-red-500 text-sm text-center my-5"><i v-if="errorMessage" class="fas fa-exclamation mr-2"></i>{{ errorMessage }}</p>
            <p v-show="submitted" class="text-green-500 text-sm text-center my-5">Your message has been sent. We will get back to you shortly.</p>
            <button v-if="!submitted" @click="handleSubmit" class="border-rosegold border py-1 mt-4 mb-8 mr-2 text-rosegold font-lora hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-3/5 md:w-2/5 text-center">Send enquiry</button>
            <button v-if="submitted" class="opacity-80 cursor-not-allowed w-3/5 lg:w-2/5 text-lg">Submitted</button>
        </div>
    </form>
</template>

<script>
import validator from "validator";

async function sendMail ({firstName, lastName, phone, email, message}) {
  const mailData = {
    from: 'contact@caine.sg',
    to: 'contact@thesafeoftime.com',
    replyTo: email,
    subject: `New enquiry from ${firstName}`,
    text: `You have received a message from ${firstName} ${lastName} - ${phone} - ${email}. Message: ${message}`,
    html: `
      <p>Dear The Safe of Time,</p>
      <p>You got a new message from ${firstName} ${lastName}.</p>
      <p>${phone}</p>
      <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">${message}</p>
    `
  }

  const res = await fetch("https://www.caine.sg/api/contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(mailData)
  })

  return res.status === 200
}

export default {
    name: "ContactForm",
    props: {
        messageValue: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            submitted: false,
            errorMessage: "",
            message: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            shown: false
        }
    },
    mounted() {
        this.message = "I would like to enquire about the " + this.messageValue;
    },
    methods: {
        async handleSubmit() {
            this.submitted = false;

            if (this.firstName === "") {
                this.errorMessage = "Please enter your name.";
            } else if (!validator.isEmail(this.email)) {
                this.errorMessage = "Please enter a valid email.";
            } else if (this.message === "") {
                this.errorMessage = "Do enter a message."
            } else {
              if (await sendMail(this)) {
                this.firstName = "";
                this.lastName = "";
                this.email = "";
                this.phone = "";
                this.message = "";
                
                this.submitted = true;
              } else {
                this.errorMessage = "An error occured while sending your message, try emailing us at contact@thesafeoftime.com instead."
              }
            }
        }
    }
}
</script>

<style scoped>
</style>