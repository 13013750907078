<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergMWContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Gentlemen's watches</h3>
                    <p class="mt-2 text-lg leading-snug">
                        This is not a watch for the average. The Alagaro was created for the independent mind, for those who have chosen their own way through life. A men’s watch destined to be your faithful companion on unexpected, challenging and adventurous paths to new possibilities.
                    </p>
                    <router-link to="#collection" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Explore collection</router-link>
                    <p class="leading-snug text-sm">Official agent/retailer for Australia, Indonesia, Malaysia, New Zealand, Philippines, Thailand, Vietnam, Cambodia, Myanmar, Laos, Middle East, India, China, Japan, Korea, USA and Latin America.</p>
                </div>
            </div>
        </div>

        <div id="collection" class="xl:px-40 lg:px-20 px-6">
            <div class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-2">
                <router-link to="/giberg/watches/gentlemen/alagaro/all-black" class="group col-span-1">
                    <img src="/assets/giberg/product/mensWatches/alagaroAllBlack.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Alagaro All Black" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Alagaro All Black</h3>
                </router-link>
                <router-link to="/giberg/watches/gentlemen/alagaro/metallic" class="group col-span-1">
                    <img src="/assets/giberg/product/mensWatches/alagaroMetallic.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Alagaro Metallic" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Alagaro Metallic</h3>
                </router-link>
                <router-link to="/giberg/watches/trilevis" class="group col-span-1">
                    <img src="/assets/giberg/product/trilevisFace.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Trilevis 6118" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Trilevis 6118</h3>
                </router-link>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
    name: "GibergMWatches",
    components: {
        Navbar,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergMWContainer .overlay {
        background-color:rgba(0, 0, 0, 0.65);
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergMWContainer .overlay {
        background-color:rgba(0, 0, 0, 0.2);
    }
}


#gibergMWContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergMWContainer .overlay {
    padding-top: calc(90px + 5vh) !important;
    min-height: calc(80vh + 90px);
}

#gibergMWContainer #landing {
    background: url("/assets/giberg/mensWatchesLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
    background-position: 70% 50%;
}

#gibergMWContainer #collection {
    padding-top: 90px;
    padding-bottom: 45px;
}
</style>