<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="aboutContainer" class="bg-wallgray font-gelasio text-gray-300">
        <div id="aboutTSOT">
            <div class="overlay px-6 lg:px-20 xl:px-40">
                <h2 class="font-lora gradient-rosegold text-gray-300 text-4xl lg:text-7xl lg:w-2/3 uppercase mb-5">The Safe of Time</h2>
                <h3 class="text-rosegold mt-10 text-xl">Watch Your Treasure Grow</h3>
                <p class="mt-2 text-lg leading-snug">
                    The Safe of Time proudly presents a curated collection of micro brands from independent watchmakers and jewellers.<br><br>
                    Unlike readymades, we prioritise the exploration for greater finesse and trusted quality. Driven by a passion that only skilled craftsmanship has to offer, The Safe of Time champions only rare and exclusive works customised by our selected niche brands.<br><br>
                    In order to provide our clients with the best recommendations and insights, we hold solid relationships with each of our represented designers. Be it a luxury watch or a precious stone, we keep you informed of the technicalities and intricacies that have gone into a fine handcrafted piece.<br><br>
                    At The Safe of Time, we believe it is the little things that make all the difference; taking care of every possible detail in our product and service. Aspiring to bring excellence in the world of high horology and jewellery, this is the place where you will unravel rare collections and gain meticulous appraisal of selected pieces to make an informed choice for your own legacy.<br><br>
                    The Safe of Time guarantees the origin of every item. All editions and bespoked pieces come with the brand’s official warranty. We give our assurance by fulfilling orders only directly from respective manufacturers, authorized retailers and selected sources.<br><br>
                    Explore our collection; enjoy the journey.
                </p>
                <p class="pt-16 pb-20 text-3xl lg:w-1/2 mx-auto gradient-rosegold italic">Like the curated chronicles of The Godfather, we're here to breathe new life into the stories of niche brands.</p>
            </div>
        </div>
        
        <div class="px-6 lg:px-20 pt-7 xl:pt-10 xl:px-40">
            <h3 class="text-rosegold mt-10 text-xl text-center mx-auto">More about</h3>
            <h2 class="font-lora gradient-rosegold text-gray-300 text-6xl lg:text-7xl lg:w-2/3 text-center uppercase mb-16 mx-auto">Our Founder</h2>
            <div class="grid grid-cols-3 pb-14 xl:pb-20">
                <div class="col-span-3 xl:col-span-1 pb-5 lg:pb-0 xl:pr-10">
                    <img src="/assets/bruceProfile.jpg" class="rounded-lg shadow-rosegold" alt="Bruce profile picture">
                </div>
                <p class="xl:pt-0 pt-6 col-span-3 xl:col-span-2 leading-snug my-auto">
                    <b class="gradient-rosegold">Bruce Wagner (Swiss, b.1981)</b> first established his love for high horology in his youth. Growing up in Meyrin (Geneva, Switzerland), it was the finesse of a classic timepiece that quickly grew fascination into a decorated career of over 20 years.<br><br>
                    Rigorously trained for 4 years, Bruce was certified as a watchmaker by Ecole d’Horlogerie de Genève (EHG) in collaboration with Chopard & CIE S.A. His keen eye for detail propelled his promotion to Expert Watchmaker and Assistant Manager within a year. Under Bruce’s care, the team in New York City (USA) skilfully improved. Recognised for his excellent communication skills, Bruce was then assigned to Miami (USA) to set up the Chopard Service Centre.<br><br>
                    In 2009, Bruce joined Chopard Asia (Singapore) as its Regional Customer Service Manager. For a dedicated 10 years, he worked closely with Switzerland to maintain brand positioning in Asia. Driven by purpose for quality, he trained over 500 staff and watchmakers in the region; topping 15 subsidiaries as the fastest worldwide service centre.<br><br>
                    With greater branding plans in mind, Geneva (Far East) Pte Ltd Singapore welcomed Bruce as Group General Manager in 2020. Being the key representative of multiple brands, he continually strives to strengthen all sales channels and develop newer opportunities. His experience includes managing distribution of over 10 named luxury brands in Indo-China with over 100 dealers.
                </p>
                <div class="col-span-3 xl:mt-10 leading-snug">
                    Bruce is highly regarded as a specialist with an international reputation in the high horology and fine jewellery industry. Over the years, he has maintained strong relationships with an extensive network of professionals, watch collectors and connoisseurs alike. As the founder of The Safe of Time, Bruce provides his clientele with exclusive opportunities in the independent sectors of Luxury Watches and Jewellery. Through his passion for undiscovered niche brands, one can expect unique pieces that are rare, complex, handmade and exquisitely artistic.
                </div>
            </div>
        </div>

    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
    name: "About",
    components: {
        Navbar,
        Footer
    }
}
</script>

<style scoped>
#aboutContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}


#aboutTSOT {
    background: url("/assets/logo.png");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

#aboutTSOT .overlay {
    padding-top: calc(90px + 5vh);
    margin-top: -90px;
    background-color:rgba(0, 0, 0, 0.8);
}
</style>