<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergLWContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Ladies' watches</h3>
                    <p class="mt-2 text-lg leading-snug">
                        The experts at Giberg create unique masterpieces of timeless beauty – both for exceptional moments in everyday life and for breathtaking appearances on special occasions.
                    </p>
                    <router-link to="#collection" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Explore collection</router-link>
                    <p class="leading-snug text-sm">Official agent/retailer for Australia, Indonesia, Malaysia, New Zealand, Philippines, Thailand, Vietnam, Cambodia, Myanmar, Laos, Middle East, India, China, Japan, Korea, USA and Latin America.</p>
                </div>
            </div>
        </div>

        <div id="collection" class="xl:px-40 lg:px-20 px-6">
            <div class="grid gap-10 grid-cols-1 lg:gap-12 lg:grid-cols-2">
                <router-link to="/giberg/watches/ladies/olora/diamond" class="group col-span-1">
                    <img src="/assets/giberg/product/ladiesWatches/oloraDiamond.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Olora Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Olora Diamond</h3>
                </router-link>
                <router-link to="/giberg/watches/ladies/olora/rose" class="group col-span-1">
                    <img src="/assets/giberg/product/ladiesWatches/oloraRose.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Olora Rose" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Olora Rose</h3>
                </router-link>
                <router-link to="/giberg/watches/ladies/olora/sapphire" class="group col-span-1">
                    <img src="/assets/giberg/product/ladiesWatches/oloraSapphire.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Olora Sapphire" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Olora Sapphire</h3>
                </router-link>
                <router-link to="/giberg/watches/ladies/niura/red" class="group col-span-1">
                    <img src="/assets/giberg/product/ladiesWatches/niuraRed.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Niura Red" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Niura Red</h3>
                </router-link>
                <router-link to="/giberg/watches/ladies/niura/emerald" class="group col-span-1">
                    <img src="/assets/giberg/product/ladiesWatches/niuraEmerald.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Niura Emerald" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Niura Emerald</h3>
                </router-link>
                <router-link to="/giberg/watches/ladies/niura/sapphire" class="group col-span-1">
                    <img src="/assets/giberg/product/ladiesWatches/niuraSapphire.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Niura Sapphire" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Niura Sapphire</h3>
                </router-link>
                <router-link to="/giberg/watches/trilevis" class="group col-span-1">
                    <img src="/assets/giberg/product/trilevisFace.jpg" class="filter group-hover:brightness-75 transition ease-linear duration-75" alt="Trilevis 6118" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl lg:w-1/2 gradient-rosegold">Trilevis 6118</h3>
                </router-link>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
    name: "GibergLWatches",
    components: {
        Navbar,
        Footer
    }
}
</script>

<style scoped>
#gibergLWContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergLWContainer .overlay {
    background-color:rgba(0, 0, 0, 0.65);
    padding-top: calc(90px + 5vh) !important;
    min-height: calc(80vh + 90px);
}

#gibergLWContainer #landing {
    background: url("/assets/giberg/ladiesWatchesLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
    background-position: 65% 50%;
}

#gibergLWContainer #collection {
    padding-top: 90px;
    padding-bottom: 45px;
}
</style>