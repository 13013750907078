<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="orkosContainer"  class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="overlay xl:px-40">
                <div class="px-6 xl:w-2/5 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Orkos</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Live the Orkos® Experience</h3>
                    <p class="mt-2 text-lg leading-snug">Your watch is the ally of your performance, wear it in all your activities with peace of mind thanks to the Watchlock® micro-locking option.</p>
                    <a href="#details" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire Now</a>
                    <p class="leading-snug text-sm">Official agent/retailer for Singapore, Malaysia, Vietnam, India, Australia, New Zealand, Thailand, Philippines, Indonesia, China, Hong Kong, Japan, Korea, Middle East, Laos, Myanmar and Cambodia.</p>
                </div>
            </div>
        </div>

        <div id="about" class="grid grid-cols-2">
            <Lightbox ref="concepts" :media="concepts" />

            <div class="col-span-2 lg:col-span-1 lg:px-20 xl:px-40 my-auto">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/orkos/about1.jpg" alt="Orkos photos" class="lg:border-r-2 border-r border-b lg:border-b-2 border-rosegold inline-block">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/orkos/about3.jpg" alt="Orkos photos" class="lg:border-l-2 border-l border-b lg:border-b-2 border-rosegold inline-block">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/orkos/about4.jpeg" alt="Orkos photos" class="lg:border-r-2 border-r border-t lg:border-t-2 border-rosegold inline-block">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/orkos/about2.jpg" alt="Orkos photos" class="lg:border-l-2 border-l border-t lg:border-t-2 border-rosegold inline-block">
            </div>
            <div class="col-span-2 lg:col-span-1 lg:pl-14 pr-8 my-8 mx-6 lg:mr-20 xl:mr-40 overflow-y-scroll lg:border-l border-gray-600">
                <h3 class="text-rosegold">1st secure luxury watch clasp</h3>
                <h2 class="gradient-rosegold text-5xl mt-2 text-rosegold font-lora">The Oath</h2>
                <p class="mt-5 text-lg">Etymologically, Orkos comes from the Greek and means: "The oath". Your watch is the faithful witness of your unique history.</p>
                <h3 class="my-6 text-xl font-lora text-rosegold">KEEP THE LINK</h3>
                <p class="mt-4">Beyond its design and its instruments, your watch indicates who you are and embodies your values, it symbolizes major moments in your life, or connects you to your loved ones present or watching over you.</p>
                <p class="mt-4">This exceptional object and of sometimes invaluable sentimental value deserves to accompany you in all serenity and in all situations, even extreme ones.</p>
                <h3 class="my-6 text-xl font-lora text-rosegold">FREE YOUR MIND</h3>
                <p class="mt-4">Make sure that through our products making your watches more secure, you maintain eternally the link with your most precious timekeepers.</p>
                <p class="mt-4">Orkos' ultimate goal is to free you from your limitations and constraints in the use of your luxury watches. Finally be free</p>
                <h3 class="my-6 text-xl font-lora text-rosegold">THE ORIGINS OF THE WATCHLOCK ® PROJECT</h3>
                <p class="mt-4">I am Sébastien Buonomo the founder of the Orkos brand.</p>
                <p class="mt-4">The origin of the Wathclock ® concept  was born from a powerful desire to protect a timepiece of inestimable sentimental value.</p>
                <p class="mt-4">I wanted to take advantage of this watch on a daily basis in order to keep this powerful link with the loved one but also absolutely keep it, one day to pass it on to my children. However, nothing existed on the watch accessory market at the time to protect our watches from accidental loss or theft.</p>
                <p class="mt-4">With a friend who is a precision mechanical engineer, also passionate about watches, we set out in search of the most discreet, reliable and integrable micro-locking system ever made.</p>
                <p class="mt-4">After weeks of reflection, we succeeded in finding the ideal compromise in order to integrate it in a restricted volume, while giving complete satisfaction in terms of resistance, ergonomics and elegance.</p>
                <p class="mt-4">The concept has been the subject of several international patents, and the project obtained a French Tech Innovation grant and an INPI grant. Watchlock® then successfully passed the mandatory prototyping and test series stages.</p>
                <p class="mt-4">Seduced by the Watchlock® project, a renowned partner joined us in the adventure. This is  Bruno Herbet , an engineer in watchmaking micro-mechanics and watchmaker in the Vallée de Joux, he has experience in prestigious houses such as Jaeger-LeCoultre or Vacheron Constantin. Bruno Herbet is now technical director for Orkos.</p>
                <h3 class="my-6 text-xl font-lora text-rosegold">THE WATCHLOCK® PROJECT IS AN INTEGRAL PART OF THE ORKOS ADVENTURE</h3>
                <p class="mt-4">Orkos wishes to protect lovers of fine watchmaking, not only via the Watchlock® system, the very first micro-locking system for prestigious clasps; but also via several technological accessories being designed.</p>
                <p class="mt-4">It is an adventure in the true sense because this sector is completely new. Apart from Orkos, no company has yet taken the bet of getting into the watchmaking securing sector despite the many needs.</p>
                <p class="mt-4">Indeed Orkos has several vocations:
                    <ol class="list-decimal">
                        <li class="pt-4">Allow you to exceed your limits by using your watch in extreme conditions and avoid accidental loss.</li>
                        <li class="mt-2">Make it possible to restrict the use of your watch to yourself.</li>
                        <li class="mt-2 pb-4">Allow you to wear it in all your day or night escapades, even those which represented before a source of anxiety or a risk.</li>
                    </ol>
                </p>
                <p class="mt-4">Like all adventures, that of Orkos is unique and surprising and the experience of increased freedom that it provides is no exception to the rule!</p>
            </div>
        </div>

        <div id="concept">
            <div class="overlay grid grid-cols-2 lg:px-20 xl:px-40 pb-20">
                <div class="col-span-2 text-rosegold text-center pb-10 lg:pb-16 px-6">
                    <h3 class="">The WATCHLOCK® Revolution</h3>
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 lg:w-1/3 mx-auto">The Concept</h2>
                    <p class="my-5 text-lg font-bold text-gray-300 italic">Orkos has created for all watch enthusiasts the first bracelet clasp in the world equipped with an integrated micro-locking system.</p>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 text-gray-300 py-4">
                        <div class="col-span-01 text-left">
                            This <b class="gradient-rosegold">100% Swiss Made</b> patented innovation instantly secures your bracelet and protects it from any accidental or intentional opening! By replacing your basic clasp, and installed in a few minutes, WatchLock® fits perfectly into the design of your watch strap and makes it invulnerable by simply removing the key.
                        </div>
                        <div class="col-span-01 text-left">
                            Remove your key from the clasp using the supplied Orkos key ring and keep it safe: you are free! Thanks to Orkos now, for your most beautiful watches, you have a folding clasp to the standard of the largest Swiss watchmaking houses, with an unprecedented level of safety!
                        </div>
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1 mx-auto">
                    <div @mouseover="conceptBg('research')" class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                        <i class="fa-solid fa-book-open-reader text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                        <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Research & Development</h4>
                        Two years of confidential research and development in our Franco-Swiss design offices.
                        <br><br>
                        Our highly qualified engineers in association with the company Ideact, expert in the development of innovative products, have developed this unique micro-locking system and its Smart and Safe key fob.
                    </div>
                    <div @mouseover="conceptBg('precision')" class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                        <i class="fa-solid fa-wrench text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                        <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Precision engineering</h4>
                        27 high-precision parts and several French, Swiss and international patents filed and obtained for the Watchlock® system and its Smart and Safe keychain.
                    </div>
                    <div @mouseover="conceptBg('steel')" class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                        <i class="fa-solid fa-toolbox text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                        <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Steel alloys</h4>
                        Watchlock® is machined from one of the best steel alloys used in fine watchmaking: Steel 316L, a stainless alloy with extreme wear resistance. An extensive selection of materials and careful assembly by our Swiss craftsmen of all components ensure: reliability, resistance and perfect ergonomics.
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1 mx-auto">
                    <div @mouseover="conceptBg('know')" class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                        <i class="fa-solid fa-brain text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                        <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Know how</h4>
                        Orkos subjects its products to very strict specifications, from design to final completion.
                        <br><br>
                        Each of them has been successfully subjected to drastic tests in watchmaking laboratories and has been verified by the final craftsman. Orkos clasps and accessories benefit from the most in-depth and strict certifications in the watchmaking industry.
                    </div>
                    <div @mouseover="conceptBg('install')" class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                        <i class="fa-solid fa-screwdriver text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                        <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Intuitive installation</h4>
                        Mounting your Watchlock® clasp is quick and easy. It can be carried out by your watchmaker or independently using the BERGEON SA accessories kit supplied in the Watchlock® case.
                        <br><br>
                        In a few minutes, find your peace of mind and use your watch without limits!
                    </div>
                    <div @mouseover="conceptBg('cert')" class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative">
                        <i class="fa-solid fa-certificate text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                        <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase w-2/3 mx-auto lg:w-full">Certifications and commitments</h4>
                        Orkos® folding clasps are 100% Swiss-Made in Geneva by our partner and Swiss leader in the manufacture of prestigious watch clasps: BOUCLEDOR SA / JMT Product.
                        The final assembly, the endings and checks are carried out by hand by the Swiss craftsmen of Boucledor SA in the pure respect of the know-how of fine watchmaking.
                        <br><br>
                        Our common goal is to maintain a high level of performance, at operational levels and from a human point of view, through constant optimization of our management, development, manufacturing and quality control processes.
                    </div>
                    <div class="w-10/12 mx-auto lg:w-9/12 pt-10 pb-6">
                        <img width="150" src="/assets/orkos/swissMadeCert.png" alt="Swiss made cert" />
                        <div class="flex">
                            <img width="150" src="/assets/orkos/rjcCert.png" class="mt-1 mr-1" alt="Responsible Jewellery Council cert" />
                            <img width="150" src="/assets/orkos/ccCert.png" class="mt-1 ml-1" alt="Chain of Custody cert" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="details" class="grid grid-cols-5 lg:px-20 xl:px-40">
            <div class="col-span-5 lg:col-span-2">
                <Gallery :brand="'orkos'" :color="activeColor" />
            </div>
            <div class="col-span-5 lg:col-span-3 mt-10 md:mb-10 xl:mb-20 px-6 lg:ml-16">
                <h2 class="font-lora text-rosegold text-4xl lg:text-5xl mb-3 lg:mb-6 gradient-rosegold">WATCHLOCK® CLASP</h2>
                <p class="px-6">
                    <ul class="list-disc">
                        <li v-if="activeColor === 'polished316L'">Polished & brushed 316L 1.4441 watchmaking steel securable folding clasp</li>
                        <li v-if="activeColor === 'brushed316L'">Securable folding clasp in brushed watch steel 316L 1.4441</li>
                        <li v-if="activeColor === 'yellowGold'">Securable folding clasp in 18 CT Yellow Gold</li>
                        <li v-if="activeColor === 'black316L'">Securable folding clasp in watchmaking steel 316L 1.4441 + Black DLC treatment</li>
                        <li v-if="activeColor === 'yellow316L'">Securable folding clasp in Yellow Gold & Polished & brushed 316L watch steel 1.4441</li>
                        <li v-if="activeColor === 'roseGold'">Securable folding clasp in 18 CT Rose Gold</li>
                        <div v-if="activeColor === 'polished316L' || activeColor === 'yellowGold' || activeColor === 'black316L' || activeColor === 'roseGold'">
                            <li>Compatible with Oyster / Jubilee / Oysterflex ROLEX® bracelet:
                            <br>GMT MASTER 2 / DAYTONA / SKY-DWSELLER / YACHT MASTER / YACHT MASTER 2 / DATE JUST / MILGAUSS</li>
                        </div>
                        <div v-if="activeColor === 'brushed316L'">
                            <li>Compatible with Oyster ROLEX® bracelet
                            <br>SUBMARINER / DEEPSEA / SEADWELLER / EXPLORER / AIR-KING / OYSTER PERPETUAL</li>
                        </div>
                        <div v-if="activeColor === 'yellow316L'">
                            <li>GMT MASTER 2 / DAYTONA / SKY-DWSELLER / YACHT MASTER / YACHT MASTER 2 / DATE JUST</li>
                        </div>
                        <li>Smart and safe keychain to remove and secure your key (3 keys provided)</li>
                        <li>Bergeon SA x Orkos tools / accessories kit: Screwdriver / Pump hunting / Magnifying glass x 4 / Microfiber</li>
                        <li>5ml tube of Loctite 243 threadlock to optimize the tightening / blocking of the screws when reassembling the Watchlock® buckle</li>
                        <li>Orkos certification card and international guarantee</li>
                    </ul>
                </p>

                <h4 class="font-rosegold font-bold mt-8">Color — 
                    <span class="uppercase">
                        <span v-if="activeColor === 'brushed316L'">Brushed 316L steel</span>
                        <span v-if="activeColor === 'polished316L'">Polished & Brushed 316L Steel</span>
                        <span v-if="activeColor === 'yellowGold'">Yellow Gold</span>
                        <span v-if="activeColor === 'black316L'">Black DLC 316L steel</span>
                        <span v-if="activeColor === 'yellow316L'">YELLOW GOLD & 316L STEEL</span>
                        <span v-if="activeColor === 'roseGold'">Rose Gold</span>
                    </span>
                </h4>
                <div id="swatches" class="flex mt-4">
                    <div id="brushed316L" @click="activeColor='brushed316L'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                    <div id="polished316L" @click="activeColor='polished316L'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                    <div id="yellowGold" @click="activeColor='yellowGold'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                    <div id="black316L" @click="activeColor='black316L'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                    <div id="yellow316L" @click="activeColor='yellow316L'" class="cursor-pointer hover:opacity-100 mr-1.5 opacity-70 rounded-full"></div>
                    <div id="roseGold" @click="activeColor='roseGold'" class="cursor-pointer hover:opacity-100 opacity-70 rounded-full"></div>
                </div>

                <h4 v-if="activeColor === 'brushed316L'" class="mt-12 text-3xl">S$2,330.00</h4>
                <h4 v-if="activeColor === 'polished316L'" class="mt-12 text-3xl">S$2,330.00</h4>
                <h4 v-if="activeColor === 'yellowGold'" class="mt-12 text-3xl">S$9,770.00</h4>
                <h4 v-if="activeColor === 'yellow316L'" class="mt-12 text-3xl">S$6,050.00</h4>
                <h4 v-if="activeColor === 'black316L'" class="mt-12 text-3xl">S$3,350.00</h4>
                <h4 v-if="activeColor === 'roseGold'" class="mt-12 text-3xl">S$9,770.00</h4>
                <p class="mt-2">Price includes GST</p>
                <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 mt-4 mb-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                
                <Lightbox ref="enquiryForm" :media="enquiryForm" />

                <p class="text-base mb-8 lg:mb-0">10 year warranty offered by Orkos.</p>
            </div>
        </div>

        <div id="action">
            <div class="overlay">
                <div class="grid grid-cols-1 lg:grid-cols-2 px-6 lg:px-20 xl:px-40 pb-6">
                    <div class="col-span-1">
                        <h3 class="text-center text-2xl border-gray-300 text-rosegold border rounded-full mx-auto mb-4">1</h3>
                        <h4 class="text-rosegold font-lora text-center gradient-rosegold text-2xl group-hover:text-white mb-2">STANDARD USE OF WATCHLOCK® CLASP (UNLOCKED)</h4>
                        <p class="pt-2">
                            Opening:
                            <ol class="list-decimal pb-2">
                                <li class="pt-4">Insert key. Use the key as a push button between thumb and forefinger.</li>
                                <li class="mt-2">Push firmly on the key.</li>
                                <li class="mt-2 pb-4">Then while maintaining pressure on the key, pull to deploy the clasp.</li>
                            </ol>
                            Closing:
                            <ol class="list-decimal">
                                <li class="pt-4">Insert key. Use the key as a push button between thumb and forefinger</li>
                                <li class="mt-2">Push firmly on the key</li>
                                <li class="mt-2">Then while maintaining pressure on the key, close</li>
                                <li class="mt-2">Release the pressure on the key, a click is heard signifying the closing</li>
                                <li class="mt-2 pb-4">The system is closed</li>
                            </ol>
                        </p>
                    </div>
                    <div class="col-span-1 mt-4 lg:mt-0 lg:pl-14 my-auto">
                        <video controls loop class="border-2 border-rosegold">
                            <source src="/assets/orkos/watchlockUse.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="grid grid-cols-1 lg:grid-cols-2 px-6 lg:px-20 xl:px-40 pt-6 pb-12">
                    <div class="lg:order-1 order-2 col-span-1 mt-4 lg:mt-0 lg:pr-14 my-auto">
                        <video controls loop class="border-2 border-rosegold">
                            <source src="/assets/orkos/watchlockLock.mp4" type="video/mp4">
                        </video>
                    </div>
                    <div class="lg:order-2 order-1 col-span-1">
                        <h3 class="text-center text-2xl border-gray-300 text-rosegold border rounded-full mx-auto mb-4">2</h3>
                        <h4 class="text-rosegold font-lora text-center gradient-rosegold text-2xl group-hover:text-white mb-2">USING THE SMART AND SAFE WATCHLOCK® LOCKING SYSTEM</h4>
                        <p class="pt-2">
                            Locking (removing the key):
                            <ol class="list-decimal pb-2">
                                <li class="pt-4">Buckle closed, simply remove your key from the clasp using the key ring provided in the box</li>
                                <li class="mt-2">Clip the key to the key ring and store it in a safe place</li>
                                <li class="mt-2 pb-4">Your clasp is now locked and cannot be opened without a key</li>
                            </ol>
                            Unlocking (re-insertion of the key):
                            <ol class="list-decimal">
                                <li class="pt-4">By hand or using the key ring (recommended), insert the key in the lock</li>
                                <li class="mt-2">Push the key all the way</li>
                                <li class="mt-2">Leave the key permanently</li>
                                <li class="mt-2 pb-4">Your clasp is unlocked</li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div id="press" class="grid grid-cols-3 gap-5 xl:gap-10 py-14 px-6 lg:px-20 xl:px-40">
            <div class="col-span-3 text-center lg:w-1/3 mx-auto">
                <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase pt-2 pb-4">In the press</h2>
            </div>
            <div class="col-span-3 lg:col-span-1 text-lg shadow border border-rosegold">
                <img src="/assets/orkos/press/bm.png" alt="Press picture" class="lg:mx-auto xl:mb-6" />
                <h3 class="lg:text-2xl text-lg text-center font-lora">BUSINESS MONTRES & JOAILLERIE</h3>
                <a ref="noopener" target="_blank" href="https://businessmontres.com/article/pour-une-fois-va-accepter-de-la-boucler-orkos-sebastienbuonomo-watchlock-rolex" class="border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
            </div>
            <div class="col-span-3 lg:col-span-1 text-lg shadow border border-rosegold">
                <img src="/assets/orkos/press/mdl.png" alt="Press picture" class="lg:mx-auto xl:mb-6" />
                <h3 class="lg:text-2xl text-lg text-center font-lora">Montres De Luxe</h3>
                <a ref="noopener" target="_blank" href="https://www.montres-de-luxe.com/Watchlock-d-Orkos-une-boucle-deployante-securisee-revolutionnaire_a17090.html" class="border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
            </div>
            <div class="col-span-3 lg:col-span-1 text-lg shadow border border-rosegold">
                <img src="/assets/orkos/press/fs.png" alt="Press picture" class="lg:mx-auto xl:mb-6" />
                <h3 class="lg:text-2xl text-lg text-center font-lora">Figaro</h3>
                <a ref="noopener" target="_blank" href="https://www.lefigaro.fr/horlogerie/vol-de-montres-de-luxe-la-bonne-idee-francaise-pthe-les-empecher-20210804" class="border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
            </div>
            <div class="col-span-3 lg:col-span-1 text-lg shadow border border-rosegold">
                <img src="/assets/orkos/press/tb.png" alt="Press picture" class="lg:mx-auto xl:mb-6" />
                <h3 class="lg:text-2xl text-lg text-center font-lora">TIME.BUSINESS</h3>
                <a ref="noopener" target="_blank" href="https://www.europastar.ch/time-business/1037-perte-et-vol-faut-il-verrouiller-sa-montre.html" class="border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
            </div>
            <div class="col-span-3 lg:col-span-1 text-lg shadow border border-rosegold">
                <img src="/assets/orkos/press/gmt.png" alt="Press picture" class="lg:mx-auto xl:mb-6" />
                <h3 class="lg:text-2xl text-lg text-center font-lora">GMT Magazine</h3>
                <a ref="noopener" target="_blank" href="https://orkos-watches.com/img/cms/GMT_71_Watchlock.pdf" class="border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Lightbox from "../components/Lightbox";

export default {
    name: "Orkos",
    components: {
        Navbar,
        Footer,
        Lightbox,
        Gallery
    },
    data() {
        return {
            activeColor: "brushed316L",
            enquiryForm: [
                {
                    type: "form",
                    product: "Watchlock® Clasp"
                }
            ],
            concepts: [
                {
                    type: "image",
                    src: "/assets/orkos/about1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/orkos/about3.jpg"
                },
                {
                    type: "image",
                    src: "/assets/orkos/about4.jpeg"
                },
                {
                    type: "image",
                    src: "/assets/orkos/about2.jpg"
                },

            ]
        }
    },
    methods: {
        conceptBg(name) {
            if (name === "research") {
                document.querySelector("#orkosContainer #concept").style.backgroundImage = `url("/assets/orkos/concept/research.jpg")`;
            } else if (name === "precision") {
                document.querySelector("#orkosContainer #concept").style.backgroundImage = `url("/assets/orkos/concept/precision.jpg")`;
            } else if (name === "steel") {
                document.querySelector("#orkosContainer #concept").style.backgroundImage = `url("/assets/orkos/concept/steel.jpg")`;
            } else if (name === "know") {
                document.querySelector("#orkosContainer #concept").style.backgroundImage = `url("/assets/orkos/concept/know.jpg")`;
            } else if (name === "install") {
                document.querySelector("#orkosContainer #concept").style.backgroundImage = `url("/assets/orkos/concept/install.jpg")`;
            } else if (name === "cert") {
                document.querySelector("#orkosContainer #concept").style.backgroundImage = `url("/assets/orkos/concept/cert.jpeg")`;
            }
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #about {
        height: 150vh;
    }

    #orkosContainer #landing {
        background-position: center;
    }
}

@media only screen and (min-device-width: 992px) {
    #about {
        height: 100vh;
    }

    #orkosContainer #landing {
        background-position: 50% 33%;
    }
}

#orkosContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#orkosContainer #landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-image: url("/assets/orkos/landing.jpg");
    background-size: cover;
}

#orkosContainer #landing .overlay {
    background-color:rgba(0, 0, 0, 0.70);
    padding-top: calc(5vh + 90px) !important;
    min-height: calc(80vh + 90px);
}

#orkosContainer #about {
    padding-top: 90px;
    margin-top: -90px;
}

#orkosContainer #about img {
    aspect-ratio: 1/1;
    width: 50%;
    object-position: center;
    object-fit: cover;
}

#orkosContainer #about .overflow-y-scroll::-webkit-scrollbar {
    width: 5px;
}

#orkosContainer #about .overflow-y-scroll::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    margin: 30px;
}

#orkosContainer #about .overflow-y-scroll::-webkit-scrollbar-thumb {
    background-color: #E6B474;
}

#orkosContainer ol {
    padding-left: 2rem !important;
}

#orkosContainer #concept {
    background: url("/assets/orkos/concept/research.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: bottom;
    transition: background-image 0.5s ease-in-out;
}

#orkosContainer .overlay {
    padding-top: 90px;
    background-color:rgba(0, 0, 0, 0.8);
}

#orkosContainer #details {
    padding-top: 90px;
}

#orkosContainer #swatches div {
    height: 40px;
    width: 40px;
}

#orkosContainer #swatches #polished316L {
    background: url("/assets/orkos/product/swatches/polished316L.jpg");
    background-size: contain;
}

#orkosContainer #swatches #brushed316L {
    background: url("/assets/orkos/product/swatches/brushed316L.jpg");
    background-size: contain;
}

#orkosContainer #swatches #yellowGold {
    background: url("/assets/orkos/product/swatches/yellowGold.jpg");
    background-size: contain;
}

#orkosContainer #swatches #black316L {
    background: url("/assets/orkos/product/swatches/black316L.jpg");
    background-size: contain;
}

#orkosContainer #swatches #yellow316L {
    background: url("/assets/orkos/product/swatches/yellow316L.jpg");
    background-size: contain;
}

#orkosContainer #swatches #roseGold {
    background: url("/assets/orkos/product/swatches/roseGold.jpg");
    background-size: contain;
}

#orkosContainer #action {
    background: url("/assets/orkos/action.jpg");
    background-attachment: fixed;
    background-size: cover;
}

#orkosContainer #action h3 {
    width: 40px;
    height: 40px;
}

#orkosContainer #press {
    padding-top: 90px;
}

#orkosContainer #press .col-span-1 img {
    -webkit-user-drag: none;
    height: 250px;
    object-position: top;
    object-fit: cover;
}
</style>