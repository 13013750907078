<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergMJContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Gentlemen's jewellery</h3>
                    <p class="mt-2 text-lg leading-snug">
                        Giberg luxurious Acessoirs are pieces of excellent craftmanship for modern, dynamic men with a sense of elegance.
                    </p>
                    <router-link to="#collection" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Explore collection</router-link>
                    <p class="leading-snug text-sm">Official agent/retailer for Australia, Indonesia, Malaysia, New Zealand, Philippines, Thailand, Vietnam, Cambodia, Myanmar, Laos, Middle East, India, China, Japan, Korea, USA and Latin America.</p>
                </div>
            </div>
        </div>

        <div id="collection" class="px-6 xl:px-40 lg:px-20">
            <h2 class="font-lora gradient-rosegold text-center text-gray-300 text-5xl uppercase mt-2 lg:w-3/4 mx-auto">Black Bracelet</h2>
            <p class="py-5 text-lg font-bold text-gray-300 text-center italic">A circle of pure passion as an unequivocal testament to character, determination and strength.</p>
            
            <Lightbox ref="blackBraceletAllBlack" :media="blackBraceletAllBlack" />
            <Lightbox ref="blackBraceletWhiteGold" :media="blackBraceletWhiteGold" />
            <Lightbox ref="blackBraceletYellowGold" :media="blackBraceletYellowGold" />
            <Lightbox ref="blackBraceletSlimWhiteGold" :media="blackBraceletSlimWhiteGold" />
            <Lightbox ref="blackBraceletSlimYellowGold" :media="blackBraceletSlimYellowGold" />
            <Lightbox ref="blackBraceletTennis" :media="blackBraceletTennis" />
            <Lightbox ref="enquiryForm" :media="enquiryForm" />
            
            <div class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10">
                <div class="group col-span-1">
                    <img @click="this.$refs.blackBraceletAllBlack.toggleLightbox();" src="/assets/giberg/product/mensJewellery/blackBraceletAllBlackFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Black Bracelet All Black" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Black Bracelet – All Black Edition</h3>
                    <p>
                        35 grams of 18K white gold black pvd-coated<br>
                        40 brilliant-cut black Diamonds<br>
                        High-quality black rubber band<br>
                        Customizable<br>
                        S$18,820 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Black Bracelet All Black');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.blackBraceletWhiteGold.toggleLightbox();" src="/assets/giberg/product/mensJewellery/blackBraceletWhiteGoldFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Black Bracelet White Gold" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Black Bracelet – White Gold Edition</h3>
                    <p>
                        35 grams of 18K White Gold<br>
                        40 brilliant-cut Diamonds<br>
                        High-quality black rubber band<br>
                        Customizable<br>
                        S$18,640 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Black Bracelet White Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.blackBraceletYellowGold.toggleLightbox();" src="/assets/giberg/product/mensJewellery/blackBraceletYellowGoldFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Black Bracelet Yellow Gold" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Black Bracelet – Yellow Gold Edition</h3>
                    <p>
                        35 grams of 18K Yellow Gold<br>
                        40 brilliant-cut diamonds<br>
                        High-quality black rubber band<br>
                        Customizable<br>
                        S$16,550 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Black Bracelet Yellow Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.blackBraceletSlimWhiteGold.toggleLightbox();" src="/assets/giberg/product/mensJewellery/blackBraceletSlimWhiteGoldFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Black Slim Bracelet White Gold" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Black Bracelet – Slim, White Gold Edition</h3>
                    <p>
                        25 grams of 18K White Gold<br>
                        40 brilliant-cut Diamonds<br>
                        High-quality black rubber band<br>
                        S$15,860 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Black Bracelet Slim, White Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.blackBraceletSlimYellowGold.toggleLightbox();" src="/assets/giberg/product/mensJewellery/blackBraceletSlimYellowGoldFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Black Slim Bracelet Yellow Gold" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Black Bracelet – Slim, Yellow Gold Edition</h3>
                    <p>
                        25 grams of 18K Yellow Gold<br>
                        40 brilliant-cut Diamonds<br>
                        High-quality black rubber band<br>
                        S$14,290 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Black Bracelet Slim, Yellow Gold');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.blackBraceletTennis.toggleLightbox();" src="/assets/giberg/product/mensJewellery/blackBraceletTennisFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Black Bracelet Tennis" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Black Bracelet – Tennis Edition</h3>
                    <p></p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Black Bracelet Tennis');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
            </div>

            <h2 id="crossTitle" class="font-lora gradient-rosegold text-center text-gray-300 text-5xl uppercase mt-2 lg:w-3/4 mx-auto">Cross</h2>
            <p class="py-5 text-lg font-bold text-gray-300 text-center italic">Unmatched in sophistication and complexity – created with humility and uncompromising dedication.</p>
            
            <Lightbox ref="crossWhiteGold1" :media="crossWhiteGold1" />
            <Lightbox ref="crossYellowGold1" :media="crossYellowGold1" />
            <Lightbox ref="crossWhiteGold33" :media="crossWhiteGold33" />
            <Lightbox ref="crossYellowGold33" :media="crossYellowGold33" />
            <Lightbox ref="crossAllBlackRuby" :media="crossAllBlackRuby" />
            <Lightbox ref="crossAllBlackSapphire" :media="crossAllBlackSapphire" />
            <Lightbox ref="crossAllBlackDiamond" :media="crossAllBlackDiamond" />

            <div class="grid gap-6 lg:gap-12 grid-cols-1 lg:grid-cols-3 mt-10">
                <div class="group col-span-1">
                    <img @click="this.$refs.crossWhiteGold1.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossWhiteGold1Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross White Gold 1 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – White Gold, 1 diamond</h3>
                    <p>
                        White Gold Edition with 1 diamond<br>
                        Cross: 11 grams of 18K White-Gold<br>
                        One brilliant-cut Diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        Chain: 9,1 grams of 18K White-Gold<br>
                        S$11,610 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross White Gold, 1 diamond');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossYellowGold1.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossYellowGold1Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross Yellow Gold 1 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – Yellow Gold, 1 diamond</h3>
                    <p>
                        Yellow Gold Edition with 1 diamond<br>
                        Cross: 10 grams of 18K Yellow-Gold (3N)<br>
                        One brilliant-cut diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        Chain: 9,1 grams of 18K White-Gold<br>
                        S$10,660 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross Yellow Gold, 1 diamond');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossWhiteGold33.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossWhiteGold33Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross White Gold 33 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – White Gold, 33 diamonds</h3>
                    <p>
                        White Gold Edition with 33 diamonds<br>
                        Cross: 11 grams of 18K White-Gold<br>
                        One brilliant-cut diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        32 brilliant-cut diamonds: 0,90mm / 0,08ct / F-G IF-VVS<br>
                        Chain: 9,1 grams of 18K White-Gold<br>
                        S$13,370 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross White Gold, 33 diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossYellowGold33.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossYellowGold33Face.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross Yellow Gold 33 Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – Yellow Gold, 33 diamonds</h3>
                    <p>
                        Yellow Gold Edition with 33 diamonds<br>
                        Cross: 10 grams of 18K Yellow-Gold (3N)<br>
                        One brilliant-cut diamond: 4,5mm / 0,35ct / F-G IF-VVS<br>
                        32 brilliant-cut diamonds: 0,90mm / 0,08ct / F-G IF-VVS<br>
                        Chain: 9 grams of 18K Yellow-Gold<br>
                        S$12,410 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross Yellow Gold, 33 diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossAllBlackRuby.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossAllBlackRubyFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross All Black Ruby" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – All Black, Ruby Edition</h3>
                    <p>
                        Stainless Steel black pvd-coated<br>
                        One Ruby Cabochon (0.36Ct. / 3.8mm)<br>
                        S$4,200 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross All Black, Ruby');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossAllBlackSapphire.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossAllBlackSapphireFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross All Black Sapphire" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – All Black, Sapphire Edition</h3>
                    <p>
                        Stainless Steel black pvd-coated<br>
                        One Sapphire Cabochon (0.41Ct. / 3.8mm)<br>
                        S$4,030 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross All Black, Sapphire');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
                <div class="group col-span-1">
                    <img @click="this.$refs.crossAllBlackDiamond.toggleLightbox();" src="/assets/giberg/product/mensJewellery/crossAllBlackDiamondFace.jpeg" class="cursor-pointer filter group-hover:brightness-75 transition ease-linear duration-75" alt="Cross All Black Diamond" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl gradient-rosegold">Cross – All Black, Diamonds Edition</h3>
                    <p>
                        Stainless Steel black pvd-coated<br>
                        One brilliant-cut diamond (4,5mm / 0,35ct / F-G IF-VVS)<br>
                        S$7,910 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox('Cross All Black, Diamonds');" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 text-center" v-if="!formShown">Enquire now</button>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergMJewellery",
    components: {
        Navbar,
        Lightbox,
        Footer
    },
    data() {
        return {
            blackBraceletAllBlack: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletAllBlack1.jpg"
                },
            ],
            blackBraceletWhiteGold: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletWhiteGold1.jpg"
                },
            ],
            blackBraceletYellowGold: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletYellowGold1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletYellowGold2.jpg"
                },
            ],
            blackBraceletSlimWhiteGold: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimWhiteGold1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimWhiteGold2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimWhiteGold3.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimWhiteGold4.jpg"
                },
            ],
            blackBraceletSlimYellowGold: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimYellowGold1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimYellowGold2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimYellowGold3.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletSlimYellowGold4.jpg"
                },
            ],
            blackBraceletTennis: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletTennis1.jpg"
                }
            ],
            enquiryForm: [
                {
                    type: "form"
                }
            ],
            crossWhiteGold1: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold11.jpeg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold12.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold13.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold14.jpg"
                },
            ],
            crossYellowGold1: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold11.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold12.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold13.jpg"
                }
            ],
            crossWhiteGold33: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold331.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold332.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossWhiteGold333.jpg"
                }
            ],
            crossYellowGold33: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold331.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold332.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold333.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossYellowGold334.jpg"
                }
            ],
            crossAllBlackRuby: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackRuby1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackRuby2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackRuby3.jpg"
                },
            ],
            crossAllBlackSapphire: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackSapphire1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackSapphire2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackSapphire3.jpg"
                },
            ],
            crossAllBlackDiamond: [
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackDiamond1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackDiamond2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/crossAllBlackDiamond3.jpg"
                },
            ],
            crossForm: [
                {
                    type: "form",
                    product: "Cross"
                }
            ]
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergMJContainer .overlay {
        background-color:rgba(0, 0, 0, 0.65);
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergMJContainer .overlay {
        background-color:rgba(0, 0, 0, 0.2);
    }
}

#gibergMJContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergMJContainer .overlay {
    padding-top: calc(90px + 5vh) !important;
    min-height: calc(80vh + 90px);
}

#gibergMJContainer #landing {
    background: url("/assets/giberg/mensJewelleryLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
    background-position: center;
}


#gibergMJContainer #collection {
    padding-top: 90px;
    padding-bottom: 45px;
}

#gibergMJContainer #crossTitle {
    padding-top: 90px;
}
</style>
