<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergCreatorsContainer" class="bg-wallgray text-gray-300">
        <div id="landing" class="flex-col flex items-end xl:px-40 lg:px-20 px-6">
             <div class="lg:w-2/5 mt-32">
                <h2 class="font-lora gradient-rosegold text-gray-300 text-4xl uppercase">WITH PASSION AND PRECISION TO PERFECTION</h2>
                <p class="mt-5">
                    Giberg is the Old High German designation for treasure and thereby expresses the credo of Noble Forge: Creating treasures for eternity.<br><br>
                    Andreas Altmann and his highly skilled team in the Swiss studios of Giberg Noble Forge have a collective Know-how of unrivalled range: the ancient knowledge of goldsmiths, the traditional art of watchmaking, the pioneering possibilities of technical engineering and the vision and creativity of artists.<br><br>
                    Giberg Swiss Noble Forge stands for the culmination of innovation to bring the most luxurious to new perfection!
                </p>
            </div>
        </div>
    </div>

    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


export default {
    name: "GibergCreators",
    components: {
        Navbar,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #gibergCreatorsContainer #landing {
        background-position: 10% 50% !important;
    }
}

#gibergCreatorsContainer #landing {
    background: url("/assets/giberg/altmann.png");
    min-height: calc(80vh + 90px);
    margin-top: -90px;
    background-size: cover;
}
</style>