<template>
    <div id="trxPressContainer" class="bg-wallgray text-gray-300">
        <Navbar @toggle-sounds="$emit('toggleSounds')" />
        <div id="landingImage">
        </div>
        
        <div class="px-6 xl:px-14">
            <h2 class="gradient-rosegold text-4xl mt-12 text-center text-rosegold font-lora">WELCOME TO THE TR-X PRESS LOUNGE</h2>
            <h3 class="mt-4 lg:text-lg text-center lg:w-3/5 lg:mx-auto">Discover a very innovative watch brand and its first release TR-X Ghost: an in-house skeleton tourbillon enhanced by the unique use of Superluminova.</h3>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-10 my-14">
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/operaNewsLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Opera News</h3>
                    <a ref="noopener" target="_blank" href="https://www.dailyadvent.com/fr/news/fd862a01687f3efcf67094a82e88d0d3" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/yahooSportsLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Yahoo Sports</h3>
                    <a ref="noopener" target="_blank" href="https://fr.sports.yahoo.com/news/om-c%C3%A9dric-bakambu-l-ambassadeur-151527657.html?guccounter=1" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/canalAlphaLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Canal Alpha</h3>
                    <a ref="noopener" target="_blank" href="https://www.youtube.com/watch?v=TdGYaNKbjKw" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Watch the interview</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/mpoyacblLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Про Часы</h3>
                    <a ref="noopener" target="_blank" href="https://www.youtube.com/watch?v=vxcctlMJ-xw" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Watch the video</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/revuefhLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">RevueFH</h3>
                    <a ref="noopener" target="_blank" href="/assets/trx/press/revuefhMag.pdf" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto mt-8 mb-4 lg:my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the magazine</a>
                    <a ref="noopener" target="_blank" href="/assets/trx/press/revuefhArticle.pdf" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto mb-8 lg:my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/globalDesignNewsLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Global Design News</h3>
                    <a ref="noopener" target="_blank" href="https://globaldesignnews.com/anthony-amato-and-vincent-walther-announce-the-launch-of-the-tr-x-ghost-an-in-house-skeleton-tourbillon-enhanced-by-the-unique-use-of-superluminova/" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/gmtLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">GMT</h3>
                    <a ref="noopener" target="_blank" href="/assets/trx/press/gmtArticle.pdf" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/atlanticoLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Atlantico</h3>
                    <a ref="noopener" target="_blank" href="/assets/trx/press/atlanticoArticle.pdf" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/twistedMaleMagLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Twisted Male Mag</h3>
                    <a ref="noopener" target="_blank" href="https://twistedmalemag.com/tr-x-ghost-the-first-skeleton-tourbillon-sublimated-by-the-superluminova/" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/businessMontresJoallerieLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Business Montre et Joallerie</h3>
                    <a ref="noopener" target="_blank" href="/assets/trx/press/businessMontresArticle.pdf" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/arcinfoLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Arcinfo</h3>
                    <a ref="noopener" target="_blank" href="/assets/trx/press/arcInfoArticle.pdf" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/timekeepersLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Timekeepers Club</h3>
                    <a ref="noopener" target="_blank" href="/trx/press" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/lHorologioLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">L'horologio</h3>
                    <a ref="noopener" target="_blank" href="https://www.orologioblog.net/45353/tr-x-ghost" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
                <div class="col-span-1 shadow border border-rosegold">
                    <img src="/assets/trx/press/zegarkiIPasjaLogo.jpg" alt="Press picture" class="lg:mx-auto xl:my-4" />
                    <h3 class="lg:text-2xl text-lg text-center">Zegarki i Pasja</h3>
                    <a ref="noopener" target="_blank" href="https://zegarkiipasja.pl/artykul/22533-tr-x-ghost-tourbillon" class="font-lora border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read our article</a>
                </div>
            </div>
            <div class="pb-10 flex justify-center">
                <router-link to="/trx" class="font-lora border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75">Explore more TR-X</router-link>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
    name: "TrxPress",
    components: {
        Navbar,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    .col-span-1 img {    
        object-fit: contain;
    }
}

@media only screen and (min-width : 1025px) {
    .col-span-1 img {
        object-fit: cover;
    }
}

#trxPressContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#landingImage {
    background: url("/assets/trx/trxPress.jpg");
    margin-top: -90px;
    height: 80vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.col-span-1 img {
    -webkit-user-drag: none;
    height: 250px;
    object-position: top;
}
</style>