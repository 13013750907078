<template>
    <div tabindex="0" @keydown.esc="toggleLightbox" v-if="shown" id="lightsOut" class="h-screen w-screen fixed top-0 left-0 flex justify-center items-center">
        <div id="box">
            <video v-if="media[index].type ==='video'" controls autoplay class="border-2 shadow border-rosegold">
                <source :src="media[index].src" type="video/mp4">
            </video>

            <div class="bg-wallgray pt-14 pb-6 lg:pb-10 px-6 lg:px-16 border-2 shadow border-rosegold" v-if="media[index].type==='form'">
                <h2 class="font-lora gradient-rosegold text-center text-gray-300 text-5xl uppercase pb-10 lg:pb-14">Enquiry form</h2>
                <ContactForm :messageValue="product" />
            </div>

            <img v-if="media[index].type==='image'" class="border-2 shadow border-rosegold" :src="media[index].src" alt="Lightbox Image" />
        </div>
        
        <button id="left" v-if="this.media.length > 1" class="absolute hover:text-rosegold text-gray-300 transition duration-75 ease-linear top-1/2 left-4 lg:left-12 text-2xl" @click="moveIndex(-1)"><i class="fa-solid fa-angle-left"></i></button>
        <button id="right" v-if="this.media.length > 1" class="absolute hover:text-rosegold text-gray-300 transition duration-75 ease-linear top-1/2 right-4 lg:right-12 text-2xl" @click="moveIndex(1)"><i class="fa-solid fa-angle-right"></i></button>
        <button id="close" class="absolute hover:text-rosegold text-gray-300 transition duration-75 ease-linear top-6 right-6 lg:right-14 text-3xl" @click="toggleLightbox"><i class="fa-solid fa-xmark"></i></button>
    </div>
</template>

<script>
import ContactForm from "./ContactForm"

export default {
    name: "Lightbox",
    props: {
        media: Array
    },
    components: {
        ContactForm
    },
    methods: {
        toggleLightbox(product) {
            if (product) {
                this.product = product;
            } else {
                this.product = this.media[this.index].product;
            }
            this.shown = !this.shown;
            if (this.shown) {
                this.$emit("offSounds");
            } else {
                this.$emit("onSounds");
            }
        },
        moveIndex(num) {
            if (num == 1 && this.index === (this.media.length - 1)) {
                this.index = 0;
            } else if (num == -1 && this.index === 0) {
                this.index = this.media.length - 1;
            } else {
                this.index = this.index + num;
            }
        }
    },
    data() {
        return {
            shown: false,
            index: 0,
            product: ""
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #box img {
        width: 80vw;
        z-index: 2000;
    }

}

@media only screen and (min-device-width: 992px) {
    #box img {
        height: 80vh;
        width: auto;
        z-index: 2000;
    }

}

#box img {
    object-fit: contain;
}

#box video {
    width: 80vw;
    z-index: 2000;
}

#lightsOut {
    background: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}
</style>