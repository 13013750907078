<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergDominicContainer" class="bg-wallgray text-gray-300">
        <Lightbox @off-sounds="$emit('offSounds')" @on-sounds="$emit('onSounds')" ref="dominicGallery" :media="dominicGallery" />
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Dominic Stricker</h3>
                    <p class="mt-2 text-lg leading-snug">
                        Ambitious, distinctive and passionate: the future of Swiss men's tennis has a name, and that name is Dominic Stricker. Already a double Grand Slam winner as a junior, he is well on his way to writing Swiss sporting history.
                    </p>
                    <div class="flex">
                        <router-link to="#more" class="font-lora border-rosegold mr-2 border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</router-link>
                        <button @click="this.$refs.dominicGallery.toggleLightbox();" class="font-lora border-rosegold ml-2 border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Gallery</button>
                    </div>
                    <p class="leading-snug text-sm">Official agent/retailer for Australia, Indonesia, Malaysia, New Zealand, Philippines, Thailand, Vietnam, Cambodia, Myanmar, Laos, Middle East, India, China, Japan, Korea, USA and Latin America.</p>
                </div>
            </div>
        </div>

        <div id="more" class="xl:px-40 lg:px-20 px-6 py-10 lg:py-24 gap-6 lg:gap-12 grid grid-cols-1 lg:grid-cols-2">
            <div class="col-span-1 lg:col-span-2">
                <h3 class="text-rosegold text-center">Dominic Stricker’s Bracelet</h3>
                <h2 class="col-span-2 font-lora gradient-rosegold text-gray-300 text-4xl lg:text-5xl uppercase mt-2 mb-6 text-center">Black Bracelet – Tennis Edition</h2>
            </div>
            <img src="/assets/giberg/ambassadors/dominic1.png" alt="Dominic Stricker picture" class="col-span-1 border-2 border-rosegold" />
            <div class="col-span-1">
                <blockquote class="text-2xl italic mt-6">
                    “My goal is the absolute top. My vision is to achieve the highest possible perfection. I dedicate every moment of the day to this endeavor with precision, efficiency, and commitment.”
                </blockquote>
                <p class="text-lg">
                    <br>
                    <b class="gradient-rosegold">Dominic Stricker</b> (born 2002 in Münsingen, Canton Bern) is well on his way to becoming the successor to Roger Federer and Stan Wawrinka. In Paris in 2020, at the age of 18, he won both the boys' singles and doubles titles (the latter with Flavio Cobolli) at the Grand Slam tournament at Roland Garros. His professional career began in 2021 as world number 1168. In no time at all he made it into the top 300, and is moving up swiftly. In March 2021 he won the ATP Challenger Tournament in Lugano, Switzerland, defeating Vitaliy Sachko in straight sets in the final. He became the 3rd-youngest Swiss player after Federer and Wawrinka to win an ATP Challenger title. He already caused a stir on his debut on the ATP Tour in May 2021 at the tournament in Geneva, Switzerland: He beat among others the former US Open winner Marin Čilić 7:6, 6:1. He also won his first ATP doubles title in Gstaad in 2021, together with Marc-Andrea Hüsler.
                </p>
                <router-link to="/giberg/jewellery/gentlemen" class="font-lora border-rosegold mr-2 border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">View bracelet</router-link>
            </div>
        </div>
    </div>

    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";


export default {
    name: "GibergDominic",
    components: {
        Navbar,
        Lightbox,
        Footer
    },
    data() {
        return {
            dominicGallery: [
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/dominic1.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/dominic2.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/dominic3.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/product/mensJewellery/blackBraceletTennis1.jpg"
                },
                {
                    type: "video",
                    src: "/assets/giberg/ambassadors/dominicVid.mp4"
                }
            ]
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergDominicContainer #landing .overlay {
        min-height: calc(80vh + 90px);
        background-color:rgba(0, 0, 0, 0.65);
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergDominicContainer #landing .overlay {
        min-height: calc(80vh + 90px);
        background-color:rgba(0, 0, 0, 0.25);
    }
}

#gibergDominicContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergDominicContainer .overlay {
    background-color:rgba(0, 0, 0, 0.55);
    padding-top: calc(90px + 5vh) !important;
}

#gibergDominicContainer #landing {
    background: url("/assets/giberg/dominicLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
    background-position: 55% 50%; 
}

#gibergDominicContainer #landing .overlay {
    min-height: calc(80vh + 90px);
}

</style>