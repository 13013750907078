<template>
    <footer class="w-full text-rosegold">
        <div class="grid lg:grid-cols-4 grid-cols-2 gap-12 lg:gap-20 xl:gap-40 pt-10 pb-14 lg:px-16 xl:px-32 px-6">
            <div v-if="!withoutLogo" class="col-span-1 mt-4 lg:mx-auto">
                <router-link to="/home"><img src="/assets/logo.png" alt="The Safe of Time logo" id="footerLogo"></router-link>
            </div>
            <div class="col-span-2 lg:col-span-1">
                <h3 class="uppercase font-lora gradient-rosegold tracking-wider mb-2 text-xl">Get in touch</h3>
                <a href="mailto:contact@thesafeoftime.com" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear inline-block mt-1">contact@thesafeoftime.com</a><br>
                <h3 class="uppercase font-lora gradient-rosegold tracking-wider mt-6 mb-2 text-xl">Follow us</h3>
                <a class="mr-3 text-gray-300 hover:text-rosegold transition duration-75 ease-linear" href="https://www.facebook.com/The-Safe-of-Time-100876229098929/" rel="noopener" target="_blank"><i class="fa-brands text-3xl fa-facebook-square"></i></a>
                <a class="mr-3 text-gray-300 hover:text-rosegold transition duration-75 ease-linear" href="https://www.instagram.com/thesafeoftime/" rel="noopener" target="_blank"><i class="fa-brands text-3xl fa-instagram"></i></a>
                <a class="text-gray-300 hover:text-rosegold transition duration-75 ease-linear" href="https://www.linkedin.com/company/the-safe-of-time" rel="noopener" target="_blank"><i class="fa-brands text-3xl fa-linkedin"></i></a>
            </div>
            <div class="col-span-1">
                <h3 class="uppercase font-lora gradient-rosegold tracking-wider mb-2 text-xl">Brands</h3>
                <router-link to="/fleury" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear tracking-wide uppercase">Fleury</router-link><br>
                <router-link to="/giberg" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear tracking-wide uppercase">Giberg</router-link><br>
                <router-link to="/madegva" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear tracking-wide uppercase">M.A.D’E GVA</router-link><br>
                <!-- <router-link to="/hannaHoGems" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear tracking-wide uppercase">HannaHoGems</router-link><br> -->
                <router-link to="/orkos" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear tracking-wide uppercase">Orkos</router-link><br>
                <router-link to="/trx" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear tracking-wide uppercase">TR-X</router-link><br>
            </div>
            <div class="col-span-1">
                <h3 class="uppercase font-lora gradient-rosegold tracking-wider mb-2 text-xl">Navigate</h3>
                <a href="/" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear">Start</a><br>
                <router-link to="/home" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear">Home</router-link><br>
                <router-link to="/about" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear mt-1">About</router-link><br>
                <router-link to="/contact" class="hover:text-rosegold text-gray-300 transition duration-75 ease-linear mt-1">Contact</router-link>
            </div>
        </div>
        
        <div class="border-t py-3 flex flex-col lg:flex-row gap-2 lg:gap-0 px-6 lg:px-14 justify-between">
            <p class="uppercase text-sm">© Copyright {{new Date().getFullYear()}} The Safe of Time — Based in Singapore</p>
            <p class="uppercase text-sm">Music done by — M.etik for InfiniteLab</p>
        </div>
    </footer>

</template>

<script>
export default {
    name: "Footer",
    props: {
        withoutLogo: Boolean
    }
}
</script>

<style scoped>
#footerLogo {
    height: 120px;
    width: 120px;
}

footer {
    background: url("/assets/wall.png");
    background-size: cover;
    background-position: bottom;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}
</style>