<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" id="introNav" :noMenu="true" />
    <div id="introContainer" class="bg-wallgray relative">
        <video src="/assets/intro.mp4" playsinline autoplay muted class="z-40 top-0 lg:w-full lg:h-screen object-cover"></video>

        <div id="enterBtn" class="lg:absolute hidden flex w-full justify-center items-center">
            <button class="z-50 inline-block font-lora border-rosegold border py-1 px-5 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center" @click="enterPage">Explore our collection</button>
        </div>
        <Footer :withoutLogo="true" class="lg:hidden"/>
    </div>
    
    <Home @toggle-sounds="$emit('toggleSounds')" id="home" class="hidden absolute top-0 z-30" />
        
</template>

<script>
import Home from "../views/Home";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import gsap from "gsap";


export default {
    name: "Intro",
    components: {
        Home,
        Navbar,
        Footer
    },
    mounted() {
        setTimeout(() => {
            document.querySelector("#enterBtn").classList.remove("hidden");
        }, 9100);
    },
    methods: {
        enterPage() {
            document.querySelector("#home").classList.remove("hidden");
            document.querySelector("#enterBtn").classList.add("hidden");
            document.querySelector("#introNav").remove();
            gsap.to("#introContainer video", { opacity: 0, duration: 0.5 });
            setTimeout(() => {
                document.querySelector("#introContainer video").remove();
            }, 500)
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #enterBtn {
        top: 62vw;
    }
}

@media only screen and (min-device-width: 992px) {
    #enterBtn {
        top: 85vh;
    }
}

#introNav {
    height: 90px;
}

#introContainer {
    margin-top: -90px;
}

#enterBtn {
    height: 15vh;
}
</style>