<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergNiuraContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="lg:px-20 xl:px-40 px-6 lg:flex lg:justify-around">
                <Lightbox ref="enquiryForm" :media="enquiryForm" />
                <img id="niuraFace" :src="`/assets/giberg/product/ladiesWatches/niura${color}Face.png`" class="mx-auto" alt="Niura watch face">
                <div class="lg:w-2/5 mt-5 lg:mt-36">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-7xl uppercase mb-5">Niura {{ color }}</h2>
                    <p class="text-lg leading-snug">
                        The glory of a forgotten kingdom. The richness of an ancient culture. The magic of the tales of 1001 Nights. You can see and feel all this and much more with Niura.
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 my-8 mr-2 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                </div>
            </div>
        </div>

        <div id="desc">
            <div class="lg:px-20 xl:px-40 px-6 overlay">
                <p class="lg:w-2/5 text-lg pb-20">
                    Niura is a watch like no other. It represents the perfect combination of great traditional craftsmanship and innovative 21st century engineering – made in Switzerland. Niura represents the breathtaking marriage between the art of jewelry and the artistry of the chronometer with the highest possible precision.
                    <br><br>
                    Niura not only houses a flying tourbillon as part of its breathtaking design. Because Niura is slightly larger than a common ladies’ watch, Giberg Haute Horlogerie invented a swinging frame which allows Niura to cling perfectly to every wrist.
                    <br><br>
                    The visible Tourbillon combined with the breathtaking lustre of the stones of the highest quality results in a dancing duo never before seen in such brilliance.
                    <br><br>
                    Niura is an ultra-limited single-item watch available as an individual customized edition.
                </p>
            </div>
        </div>

        <div id="technical" class="px-6 lg:px-20 xl:px-40 pt-16 pb-20">
            <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 lg:w-2/5">Technical Facts</h2>
            <div class="grid grid-cols-2 gap-10 text-gray-300 py-4">
                <div class="col-span-2 lg:col-span-1 text-left">
                    Niura’s stunning precision comes from the <b class="gradient-rosegold">Trilevis 6118</b>, the revolutionary new Flying Tourbillon, developed and engineered from the ground up by Giberg Haute Horlogerie exclusively for its own watch collection.
                </div>
                <div class="col-span-2 lg:col-span-1 text-left">
                    Niura has a case with red gold, a sapphire dial and is set with diamonds and rubies. The red bracelet is made with lizard leather.
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergNiura",
    data() {
        return {
            color: this.$route.params.color.charAt(0).toUpperCase() + this.$route.params.color.slice(1),
            enquiryForm: [
                {
                    type: "form",
                    product: "Niura " + this.$route.params.color.charAt(0).toUpperCase() + this.$route.params.color.slice(1)
                }
            ]
        }
    },
    mounted() {
        document.querySelector("#gibergNiuraContainer #landing").style.backgroundImage = `url("/assets/giberg/product/ladiesWatches/niura${this.color}Landing.jpg")`;
        document.querySelector("#gibergNiuraContainer #desc").style.backgroundImage = `url("/assets/giberg/product/ladiesWatches/niura${this.color}Desc.jpg")`;
    },
    components: {
        Navbar,
        Lightbox,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergNiuraContainer #landing {
        height: auto;
    }
    
    #gibergNiuraContainer #niuraFace {
        height: 50vh;
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergNiuraContainer #landing {
        height: 80vh;
    }

    #gibergNiuraContainer #niuraFace {
        height: 85vh;
    }
}

#gibergNiuraContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergNiuraContainer .overlay {
    background-color:rgba(0, 0, 0, 0.55);
    padding-top: calc(90px + 5vh) !important;
    min-height: calc(80vh + 90px);
}

#gibergNiuraContainer #landing {
    padding-top: 50px !important;
    margin-top: -90px;
    background: url("/assets/giberg/product/ladiesWatches/oloraDiamondLanding.jpg");
    background-size: cover;
}

#gibergNiuraContainer .flex img {
    height: 85vh;
}

#gibergNiuraContainer #desc {
    background: url("/assets/giberg/product/ladiesWatches/oloraDiamondDesc.jpg");
    background-size: cover;
    min-height: calc(80vh + 90px);
    background-position: center;
}
</style>
