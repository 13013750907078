<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="fleuryContainer" class="bg-wallgray text-gray-300">
        <div id="fleuryLanding">
            <div class="overlay xl:px-40">
                <div class="px-6 xl:w-2/5 pb-10 lg:pb-0">
                    <h2 class="font-lora uppercase text-6xl lg:text-7xl gradient-rosegold">Fleury</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Fleury Manufacture</h3>
                    <p class="mt-2 text-lg leading-snug">Fleury is an independent, family-owned artisanal watch-manufacturing company located in the countryside of Geneva.</p>
                    <router-link to="#details" class="border-rosegold border py-1 my-10 bg-rosegold text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/2 text-center">Coming Spring 2022</router-link>
                    <p class="leading-snug text-sm">Official agent/retailer for Singapore, Malaysia, Vietnam, India, Australia, New Zealand, Thailand, Philippines, Indonesia, China, Hong Kong, Japan, Korea, Middle East, Laos, Myanmar and Cambodia.</p>
                </div>
            </div>
        </div>
        
        <div id="about" class="grid grid-cols-2">
            <Lightbox ref="components" :media="components" />

            <div class="col-span-2 lg:col-span-1 lg:px-20 xl:px-40 my-auto">
                <img @click="this.$refs.components.toggleLightbox();" src="/assets/fleury/component1.jpeg" alt="Fleury Manufacture photos" class="cursor-pointer lg:border-r-2 border-r border-b lg:border-b-2 border-rosegold inline-block">
                <img @click="this.$refs.components.toggleLightbox();" src="/assets/fleury/component2.jpg" alt="Fleury Manufacture photos" class="cursor-pointer lg:border-l-2 border-l border-b lg:border-b-2 border-rosegold inline-block">
                <img @click="this.$refs.components.toggleLightbox();" src="/assets/fleury/component3.jpeg" alt="Fleury Manufacture photos" class="cursor-pointer lg:border-r-2 border-r border-t lg:border-t-2 border-rosegold inline-block">
                <img @click="this.$refs.components.toggleLightbox();" src="/assets/fleury/component4.jpg" alt="Fleury Manufacture photos" class="cursor-pointer lg:border-l-2 border-l border-t lg:border-t-2 border-rosegold inline-block">
            </div>
            <div class="col-span-2 lg:col-span-1 lg:pl-14 pr-8 my-8 mx-6 lg:mr-20 xl:mr-40 overflow-y-scroll lg:border-l border-gray-600">
                <h3 class="text-rosegold">The launch of</h3>
                <h2 class="gradient-rosegold text-5xl mt-2 text-rosegold font-lora">Fleury Manufacture</h2>
                <p class="mt-5 text-lg">Fleury is an independent, family-owned artisanal watch-manufacturing company located in the countryside of Geneva.</p>
                <p class="mt-4">It is animated by the desire of sharing core values such as: The mutual help of small independent craftsmen, trust, respect, fairness, sharing, creativity, development, evolution, education, independence and freedom...</p>
                <h3 class="my-6 text-xl font-lora text-rosegold">THE ZEN</h3>
                <p class="mt-4">The white mother-of-pearl on the dial was chosen for its natural and refined feel. Its celestial design invites us to imagine the movements of the solar system as well as the constellations of Orion, Ursa Major and Cassiopeia. This dial represents the encounter of water through the presence of the mother-of-pearl and that of the sky. In this way you dive into the world of Fleury Manufacture through its spiritually connected watches.</p>
                <p class="mt-4">In addition, the choice of the hands shape gives a feminine appearance, and that of the color of the case creates a balance while highlighting the display of the time which is, in addition, represented by the 12 diamonds.</p>
                <p class="mt-4">Finally, the FM01 caliber was imagined, designed and created with the desire to give the ladies' watch its manufactured movement, thus granting it a touch of elegance through the visibility of its plates and bridges which retain a traditional but nonetheless original shape. The brilliance of the diamond-cut angles and the discoveries brings luminosity to the movement. The realization of the FM01 caliber was also made possible thanks to the participation and the good will of various craftsmen.</p>
                <h3 class="my-6 text-xl font-lora text-rosegold">FLEURY'S MISSION</h3>
                <p class="mt-4">One of Fleury Manufacture's objectives is to produce as much as possible in-house, and thus bring together the finest professions of the watchmaking art under the sign of an harmonious cooperation. The Zen lady watch is the first achievement out of a source of great creativity. Customers also have the choice of personalizing their watches. Fleury Manufacture favors producing unique pieces or in small quantities and highlighting artisanal know-how as well as the quality and respect of the independent artisans who participate in the creations. The Fleury Manufacture is keen to offer pieces which radiate a positive energy. The harmonious line of the ZEN watch gives the chance of being worn by all generations of women.</p>
                <p class="mt-4">In addition, Fleury Manufacture which is very sensitive to the nature that surrounds us, is committed to support a sustainable and respectful economy. It contributes to the protection and development of the local flora and fauna as well as its birds. Not forgetting the protection of water and forests.</p>
                <p class="mt-4">Through the purchase of a Fleury product, you do more than just wear a watch that fits your taste, you also join a cause... It is in this spirit that Fleury Manufacture wants to communicate this message, which is essential in an ever-changing world and economy.</p>
            </div>
        </div>

        <div id="product">
            <div class="overlay lg:px-20 xl:px-40 px-6 pb-10 lg:pb-20">
                <h3 class="text-rosegold">The Lady's Watch</h3>
                <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 lg:w-1/4">Zen</h2>
                <p class="pt-4 pb-12">
                    The ZEN lady watch represents the launch of Fleury Manufacture's activity, it is above all the fruit of a deep passion, it represents the first lines of a grand story. The honor of participating in the art of watchmaking and the wish to pursue the legacy of this centuries’ worth national heritage. Its realization was driven by a great desire to realize a dream that goes well beyond the creation of a watch.
                </p>

                <h3 class="font-lora gradient-rosegold text-2xl lg:w-1/4">Product Details</h3>
                <div class="pt-4 pb-8 grid grid-cols-2">
                    <div class="col-span-1 flex flex-col gap-5">
                        <span><b>Case Material</b> <span class="text-rosegold">—</span> 5N Gold</span>
                        <span><b>Bracelet Strap</b> <span class="text-rosegold">—</span> Satin</span>
                        <span><b>Buckle</b> <span class="text-rosegold">—</span> 5N Gold</span>
                        <span><b>Number of carats</b> <span class="text-rosegold">—</span> 0.055</span>
                        <span><b>Movement</b> <span class="text-rosegold">—</span> Manual-winding mechanical;<br>Power reserve: 40 h, 28800 variations/hours</span>
                        <span><b>Reference</b> <span class="text-rosegold">—</span> FM5</span>
                    </div>
                    <div class="col-span-1 flex flex-col gap-5">
                        <span><b>Model</b> <span class="text-rosegold">—</span> Zen</span>
                        <span><b>Display</b> <span class="text-rosegold">—</span> Hours and minutes</span>
                        <span><b>Dial Finish</b> <span class="text-rosegold">—</span> Mother-of-pearl</span>
                        <span><b>Water resistance</b> <span class="text-rosegold">—</span> 30m</span>
                        <span><b>Size</b> <span class="text-rosegold">—</span> ø 33 mm</span>
                        <span><b>Thickness</b> <span class="text-rosegold">—</span> 9.8mm</span>
                    </div>
                </div>
            </div>
        </div>

        <div id="details" class="grid grid-cols-5 lg:px-20 xl:px-40">
            <div class="col-span-5 lg:col-span-2">
                <Gallery :brand="'fleury'" :color="'zen'" />
            </div>
            <div class="col-span-5 lg:col-span-3 md:mb-10 xl:mb-20 px-6 lg:ml-16">
                <h2 class="font-lora text-rosegold text-4xl lg:text-5xl mb-3 lg:mb-6 lg:w-1/3 gradient-rosegold uppercase">Zen</h2>
                <p class="px-6">
                    <ul class="list-disc">
                        <li>
                            Steel case of 33mm with 3 lugs:<br> 
                                Caseband set with 72 VVS diamonds from ø 1.20mm (0.53 carat)<br>
                                Lugs set with 20 VVS diamonds from ø 1.10mm (0.11 carat)
                        </li>
                        <li>Blue aventurine/White mother-of-pearl dial</li>
                        <li>3 constellations represented by colored stones</li>
                        <li>Celestial pattern representing the movements of the solar system, cycles, infinity</li>
                        <li>14-16mm satin strap with alcantara lining</li>
                        <li>Steel pin buckle set with diamonds</li>
                    </ul>
                    <br />
                </p>
                <span class="gradient-rosegold font-bold">Customers have the choice of personalizing their Zen watches.</span>

                <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-light border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                
                <Lightbox ref="enquiryForm" :media="enquiryForm" />
            </div>
        </div>

        <div id="detailsMagical" class="bg-wallgray2 grid grid-cols-5 lg:px-20 xl:px-40">
            <div class="col-span-5 lg:col-span-2">
                <Gallery ref="zenMagicalGallery" :brand="'fleury'" :color="`zenMagical`" />
            </div>
            <div class="col-span-5 lg:col-span-3 mt-6 md:mb-10 xl:mb-20 px-6 lg:ml-16">
                <h2 class="font-lora text-rosegold text-4xl lg:text-5xl mb-3 lg:mb-6 gradient-rosegold uppercase">Zen Magical Constellations</h2>
                <p class="px-6">
                    <ul class="list-disc">
                        <li>
                            Steel case of 33mm with 3 lugs:<br> 
                                Caseband set with 72 VVS diamonds from ø 1.20mm (0.53 carat)<br>
                                Lugs set with 20 VVS diamonds from ø 1.10mm (0.11 carat)
                        </li>
                        <li v-if="activeMagicalColor === 'blue'">Blue aventurine dial</li>
                        <li v-if="activeMagicalColor === 'white'">White mother-of-pearl dial</li>
                        <li>3 constellations represented by colored stones </li>
                        <li>Celestial pattern representing the movements of the solar system, cycles, infinity</li>
                        <li>14-16mm {{ activeMagicalColor }} satin strap with alcantara lining</li>
                        <li>Steel pin buckle set with diamonds</li>
                        <li>Limited edition of 12 watches</li>
                    </ul>
                    <br />
                </p>
                <span class="gradient-rosegold font-bold">Customers have the choice of personalizing their Zen watches.</span>

                <h4 class="font-rosegold font-bold mt-8">Color — <span class="uppercase">{{ activeMagicalColor }}</span></h4>
                <div id="magicalSwatches" class="flex mt-4">
                    <div id="blue" @click="fleuryColor('magical', 'blue')" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                    <div id="white" @click="fleuryColor('magical', 'white')" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                </div>

                <button @click="this.$refs.enquiryFormMagical.toggleLightbox();" class="font-light border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                
                <Lightbox ref="enquiryFormMagical" :media="enquiryFormMagical" />
            </div>
        </div>

        <div id="atelier">
            <div class="px-6 xl:py-14 text-center overlay">
                <div class="xl:pb-8">
                    <h3 class="text-rosegold">Workshop of light</h3>
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2">L'atelier de lumière</h2>
                </div>
                <div>
                    <p class="xl:w-1/2 xl:mb-14 mx-auto my-10 lg:my-0">One of Fleury Manufacture's objectives is to produce as much as possible in-house, and thus bring together the finest professions of the watchmaking art under the sign of an harmonious cooperation. The Zen lady watch is the first achievement out of a source of great creativity. Customers also have the choice of personalizing their watches. Fleury Manufacture favors producing unique pieces or in small quantities and highlighting artisanal know-how as well as the quality and respect of the independent artisans who participate in the creations. The Fleury Manufacture is keen to offer pieces which radiate a positive energy.</p>
                    <div class="grid grid-cols-3 xl:w-2/3 mx-auto gap-6 py-6 lg:py-0">
                        <img src="/assets/fleury/workshop3.jpeg" alt="Fleury workshop photos" class="col-span-3 lg:col-span-1">
                        <img src="/assets/fleury/workshop2.jpeg" alt="Fleury workshop photos" class="col-span-3 lg:col-span-1">
                        <img src="/assets/fleury/workshop4.jpeg" alt="Fleury workshop photos" class="col-span-3 lg:col-span-1">
                    </div>
                </div>
            </div>
        </div>

        <div id="press" class="grid grid-cols-3 gap-5 xl:gap-10 py-14 px-6 lg:px-20 xl:px-40">
            <div class="col-span-3 text-center lg:w-1/3 mx-auto">
                <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase pt-2 pb-4">In the press</h2>
            </div>
            <div class="col-span-3 lg:col-span-1 lg:col-start-2 text-lg shadow border border-rosegold">
                <img src="/assets/fleury/press/gphg.jpg" alt="Press picture" class="lg:mx-auto xl:mb-6" />
                <h3 class="lg:text-2xl text-lg text-center font-lora">GPHG</h3>
                <a ref="noopener" target="_blank" href="https://www.gphg.org/horlogerie/en/watches/zen" class="border-rosegold border py-1 px-3 block w-4/5 lg:w-3/5 mx-auto my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 align-center text-center">Read the article</a>
            </div>
        </div>
    </div>

    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Gallery from "../components/Gallery";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "Fleury",
    components: {
        Navbar,
        Gallery,
        Lightbox,
        Footer
    },
    data() {
        return {
            activeMagicalColor: "blue",
            enquiryForm: [
                {
                    type: "form",
                    product: "Fleury Zen"
                }
            ],
            enquiryFormMagical: [
                {
                    type: "form",
                    product: `Fleury Zen Magical Constellations`
                }
            ],
            components: [
                {
                    type: "image",
                    src: "/assets/fleury/component1.jpeg"
                },
                {
                    type: "image",
                    src: "/assets/fleury/component2.jpg"
                },
                {
                    type: "image",
                    src: "/assets/fleury/component3.jpeg"
                },
                {
                    type: "image",
                    src: "/assets/fleury/component4.jpg"
                }
            ]
        }
    },
    methods: {
        fleuryColor(collection, color) {
            if (collection === 'magical') {
                this.activeMagicalColor = color
                if (color === 'blue') {
                    this.$refs.zenMagicalGallery.setActiveImage(0)
                } else {
                    this.$refs.zenMagicalGallery.setActiveImage(3)
                }
            } else {
                this.activeColor = color
            }
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #inspiration {
        height: 150vh;
    }
}

@media only screen and (min-device-width: 992px) {
    #inspiration {
        height: 100vh;
    }
}

#fleuryContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#fleuryLanding {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background: url("/assets/fleury/watch4.jpg");
    background-size: cover;
    background-position: 0% 33%;
}

#fleuryContainer #fleuryLanding .overlay {
    background-color:rgba(0, 0, 0, 0.5);
    padding-top: calc(5vh + 90px) !important;
    min-height: calc(80vh + 90px);
}

#fleuryContainer #atelier {
    background: url("/assets/fleury/workshop1.jpeg");
    background-size: cover;
    background-position: 0% 25%;
    background-attachment: fixed;
}

#atelier .relative img {
    aspect-ratio: 3 / 4;
    object-position: center;
    object-fit: cover;
}

#atelier .flex img {
    aspect-ratio: 1 / 1;
    object-position: center;
    object-fit: cover;
}

#fleuryContainer #about {
    padding-top: 90px;
    margin-top: -90px;
    height: 100vh;
}

#fleuryContainer #about img {
    aspect-ratio: 1/1;
    width: 50%;
    object-position: center;
    object-fit: cover;
}

#fleuryContainer #about .overflow-y-scroll::-webkit-scrollbar {
    width: 5px;
}

#fleuryContainer #about .overflow-y-scroll::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    margin: 30px;
}

#fleuryContainer #about .overflow-y-scroll::-webkit-scrollbar-thumb {
    background-color: #E6B474;
}

#fleuryContainer #product {
    background: url("/assets/fleury/watch3.jpg");
    background-size: cover;
    background-position: 0% 25%;
    background-attachment: fixed;
}

#fleuryContainer #details {
    padding-top: 90px;
}

#fleuryContainer #detailsMagical {
    padding-top: 90px;
}

#fleuryContainer .overlay {
    padding-top: 90px;
    background-color:rgba(0, 0, 0, 0.8);
}

#magicalSwatches div {
    height: 40px;
    width: 40px;
}

#magicalSwatches #blue {
    background: #2c476d;
}

#magicalSwatches #white {
    background: white;
}
</style>