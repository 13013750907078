<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergTrilevisContainer" class="bg-wallgray text-gray-300">
        <div id="landing">
            <Lightbox ref="enquiryForm" :media="enquiryForm" />
            <div id="flexContainer" class="lg:px-20 xl:px-40 px-6 lg:flex">
                <img id="trilevisFace" :src="`/assets/giberg/product/trilevisFace.png`" class="mx-auto" alt="Trilevis face">
                <div class="lg:w-2/5 mt-5 lg:mt-36">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-7xl uppercase mb-5">Trilevis 6118</h2>
                    <p class="text-lg leading-snug">
                        A revolutionary new Flying Tourbillon.
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 my-8 mr-2 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                </div>
            </div>
        </div>

        <div id="desc">
            <div class="lg:px-20 xl:px-40 px-6 overlay">
                <p class="lg:w-2/5 text-lg lg:mt-10">
                    Forget everything you’ve ever expected from a tourbillon before: Giberg Haute Horlogerie has reinvented the clock movement in order to achieve an entirely new definition of a wristwatch’s accuracy rate in any position.
                    <br><br>
                    The proportion of the balance wheel was pushed to the limits of the feasible, which brings an enormous dynamic to the movement.
                </p>
            </div>
        </div>

        <div id="technical" class="lg:px-20 xl:px-40 px-6 pt-16 pb-20">
            <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mb-5 lg:w-2/5">Technical Facts</h2>
            <div class="grid gap-6 grid-cols-2 lg:gap-10 text-gray-300 py-4">
                <div class="lg:col-span-1 col-span-2 text-left">
                    All masterpieces by Giberg Haute Horlogerie are equipped with mass weights on the balance. This allows an extremely fine tuning only found in absolute precision watches.
                </div>
                <div class="lg:col-span-1 col-span-2 text-left">
                    The Tourbillon is – in the construction of a flying bogie – supported by ball bearings and is supplied with energy by two barrels connected in series. So the power reserve is at least 72 hours.
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergOlora",
    data() {
        return {
            enquiryForm: [
                {
                    type: "form",
                    product: "Trilevis 6118"
                }
            ]
        }
    },
    components: {
        Navbar,
        Lightbox,
        Footer
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #gibergTrilevisContainer #trilevisFace {
        height: 50vh;
    }

    #gibergTrilevisContainer #landing {
        height: 80vh;
        padding-top: 50px;
    }

    #gibergTrilevisContainer #desc {
        background-position: 55% 50%;
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergTrilevisContainer #landing {
        height: 80vh;
        padding-top: 50px;
    }

    #gibergTrilevisContainer #landing #flexContainer {
        height: 80vh;
    }

    #gibergTrilevisContainer #trilevisFace {
        height: 85vh;
    }
}

#gibergTrilevisContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergTrilevisContainer .overlay {
    background-color:rgba(0, 0, 0, 0.65);
    padding-top: calc(90px + 5vh) !important;
    min-height: 80vh;
}

#gibergTrilevisContainer #landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
}

#gibergTrilevisContainer #technical img {
    height: 500px;
}

#gibergTrilevisContainer #desc {
    background-image: url("/assets/giberg/product/trilevisDesc.jpg");
    background-size: cover;
}
</style>
