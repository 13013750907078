<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="trxContainer">
        <div class="bg-wallgray text-gray-300">
            <div id="landing" class="lg:px-20 xl:px-40">
                <div class="px-6 lg:w-2/5 pb-10 lg:pb-0">
                    <h2 class="uppercase text-5xl lg:text-6xl font-lora gradient-rosegold">Time Research<br>Experience</h2>
                    <h3 class="text-rosegold mt-10 text-xl"><img src="/assets/trx/swissLogo.svg" alt="Swiss logo" class="swiss inline-block mr-2" />Swiss craft product</h3>
                    <p class="mt-2 text-lg leading-snug">TR-X, Time Research Experience, is an innovative watch brand and luxury watch decoration characterized by a quest for experience over time.<br><a href="/assets/trx/patent.pdf" class="underline hover:text-white transition ease-linear duration-75 text-rosegold" target="_blank" ref="noopener">TR-X Process Patented (R)</a></p>
                    <a href="#details" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire Now</a>
                    <p class="leading-snug text-sm">Official agent/retailer for Singapore, Malaysia, Vietnam, Australia, New Zealand, Laos, Cambodia and Myanmar.</p>
                </div>
            </div>

            <div id="inspiration" class="grid grid-cols-2">
                <div class="col-span-2 lg:col-span-1 lg:px-20 xl:px-40 my-auto">
                    <img src="/assets/trx/trxIns1.png" alt="TR-X inspiration photos" class="lg:border-r-2 border-r border-b lg:border-b-2 border-rosegold inline-block">
                    <img src="/assets/trx/trxIns3.jpg" alt="TR-X inspiration photos" class="lg:border-l-2 border-l border-b lg:border-b-2 border-rosegold inline-block">
                    <img src="/assets/trx/trxIns2.jpg" alt="TR-X inspiration photos" class="lg:border-r-2 border-r border-t lg:border-t-2 border-rosegold inline-block">
                    <img src="/assets/trx/trxIns4.jpg" alt="TR-X inspiration photos" class="lg:border-l-2 border-l border-t lg:border-t-2 border-rosegold inline-block">
                </div>
                <div class="col-span-2 lg:col-span-1 lg:pl-14 pr-8 my-8 mx-6 lg:mr-20 xl:mr-40 overflow-y-scroll lg:border-l border-gray-600">
                    <h3 class="text-rosegold">Our TR-X WORLD</h3>
                    <h2 class="gradient-rosegold text-5xl mt-2 text-rosegold font-lora">Inspiration</h2>
                    <p class="mt-5 text-lg">When technology and passion<br>intersect, performance and experience<br>remain the domain of carelessness.</p>
                    <p class="mt-4">Our source of inspiration and our concepts come mainly from our passion for mechanics and the application of CHROMOTHERAPY which gives a physiological and biochemical effect on our body, being consciously intended to serve as a benchmark for the brand.</p>
                    <p class="mt-4">The terms futuristic and high-tech have become words that represent the universe of TR-X in an attempt to decipher the emotional attraction exerted on our watchmaking or mechanical art products.</p>
                    <p class="mt-4">Passionate collectors will immediately recognize that our brand's philosophy represents a critical break with the past, while demonstrating a deep respect for the know-how and traditions of fine watchmaking.</p>
                    <h3 class="my-6 text-xl font-lora text-rosegold">THE CONCEPTS</h3>
                    <p class="mt-4">Our world of "car" motorized vehicles inspires both our design and the wrapping of our "Watch and Clock" products and the mechanisms that drive them.</p>
                    <p class="mt-4">Physiology of the body "Chromotherapy"<br>Each color used contains an energetic quantum, deciphered as climatic energy: Heat - Cold - Humidity - Drought and Wind, taking up the ancient Chinese tradition. It constantly causes a biological and psychic effect on a living organism. Low intensity colored light, unlike some phototherapies, promotes spontaneous responses in the body through physiological stimulation.</p>
                    <p class="mt-4">At TR-X Watch, the experience manifests itself not only Visually and Physiologically, but also in every design choice through every phase of production. The nut-shaped screws on the back and imbued key-shaped screws on the front as well as on the outside of the case of our TR-X Watch products are designed according to the world of mechanics. The cutouts of our wheels and skeletons are inspired by the field of “Block Control Process” PCBs. The shape of the TR-X Watch refers to the shapes of motorized vehicles, with the strap being integrated into the case.</p>
                    <p class="mt-4">At TR-X Clock, it is a design presenting the different divisions of time by combining mechanical art and the animation of automatons. Our inspiration was motivated by 19th century paintings, with the integration of automatons or mechanical clocks directly hidden behind the linen canvas. Through this visual aspect, we wanted to breathe new life into this ancient figurative art, preserving the mystery of the mechanism behind our frame in the form of a painting.</p>
                    <p class="mt-4">The visual experience desired by our TR-X Clock product is to amaze the gaze of the beholder and to illustrate time in a more contemporary way.</p>
                </div>
            </div>

            <div id="product">
                <div class="overlay lg:px-20 xl:px-40 px-6 pb-10 lg:pb-20">
                    <h3 class="text-rosegold">Modernity & simplicity</h3>
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 lg:w-1/3">Product</h2>
                    <p class="pt-4 pb-12">
                        TR-X ghost, was directly inspired by the cult film of the 80s, and the clip of Ray Parker Jr, or in the feature film, colored Led effects dressed in the accessories of the clip. Fan of this film that rocked our childhood, the TR-X GHOST watch and exponentially ghostly with our luminescent skeleton.
                    </p>

                    <h3 class="font-lora gradient-rosegold text-2xl lg:w-1/4">Product Description</h3>
                    <div class="pt-2 pb-8 flex flex-col gap-5">
                        <span><b>Case</b> <span class="text-rosegold">—</span> Black DLC stainless steel case with Superluminova®, anti-reflective sapphire crystal and display case back.</span>
                        <span><b>Diameter</b> <span class="text-rosegold">—</span> 43 mm.</span>
                        <span><b>Dial</b> <span class="text-rosegold">—</span> Skeletonized mainplate with Superluminova®.</span>
                        <span><b>Functions</b> <span class="text-rosegold">—</span> Hour, minute, 60-secondtourbillon.</span>
                        <span><b>Buckle</b> <span class="text-rosegold">—</span> Stainless steel with black DLC folding clasp with Superluminova®.</span>
                        <span><b>Strap</b> <span class="text-rosegold">—</span> Black DLC stainless steel / Leather phosphorescent colored top stitch / Rubber phosphorescent colored top stitch.</span>
                        <span><b>Waterproof</b> <span class="text-rosegold">—</span> 20 m / 2 ATM .</span>
                        <span><b>Movement</b> <span class="text-rosegold">—</span> In-house skeletonized manual tourbillon Black DLC TRX8, Super-LumiNova(R) and Process Patented TR-X (R) bevelling with different colors. Frequency of balance wheel: 21,600 vibrations/hour, antimagnet. Number of jewels: 19. Power reserve: 110h.</span>
                        <span><b>Features</b> <span class="text-rosegold">—</span> Box realized in-house with QRCODE warranty.</span>
                    </div>
                </div>
            </div>

            <div id="details" class="grid grid-cols-5 lg:px-20 xl:px-40">
                <div class="col-span-5 lg:col-span-2">
                    <Gallery :brand="'trx'" :color="activeColor" />
                </div>
                <div class="col-span-5 lg:col-span-3 mt-10 md:mb-10 xl:mb-10 px-6 lg:ml-16">
                    <h2 class="font-lora text-rosegold text-4xl lg:text-5xl mb-3 lg:mb-6 gradient-rosegold">TR-X GHOST</h2>
                    <p>
                        This skeleton tourbillon man’s manufacturing and patented in Swiss is an object of great value.<br>
                        She was developed using watchmaking techniques with a special luminising application with SwissSuper-LumiNova (R) that meets the
                        needs of individuals wishing to wear a watch on their wrist in a modern and avant-garde fashion, during important events, day and night.
                        <br><br>
                        <span class="gradient-rosegold font-bold">TR-X watches can be personalized with 4 letters or numbers on the side.</span>
                    </p>

                    <h4 class="font-rosegold font-bold mt-8">Color — <span class="uppercase">{{ activeColor }}</span></h4>
                    <div id="swatches" class="flex mt-4">
                        <div id="ocean" @click="activeColor='ocean'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                        <div id="lava" @click="activeColor='lava'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                        <div id="atomic" @click="activeColor='atomic'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                        <div id="ray" @click="activeColor='ray'" class="cursor-pointer hover:opacity-100 opacity-70 rounded-full"></div>
                    </div>

                    <h4 class="mt-12 text-3xl">S$55,750.00</h4>
                    <p class="mt-2">Price includes GST</p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 mt-4 mb-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                    <Lightbox ref="enquiryForm" :media="enquiryForm" />

                    <p class="text-base mb-8 lg:mb-0">3-year warranty offered by TRX.Swiss</p>
                </div>
            </div>

            <div id="manufacture">
                <div class="overlay grid grid-cols-2 lg:px-20 xl:px-40 pb-20">
                    <div class="col-span-2 text-rosegold text-center pb-10 lg:pb-16">
                        <h3 class="">Manufacture and Maintenance</h3>
                        <h2 class="font-lora px-6 gradient-rosegold text-gray-300 text-4xl lg:text-5xl uppercase mt-2 mx-auto">Protection of Swiss<br>craftsmanship<img src="/assets/trx/swissLogo.svg" alt="Swiss logo" class="swiss inline-block align-middle ml-3"></h2>
                    </div>
                    <div class="col-span-2 lg:col-span-1 mx-auto">
                        <div class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                            <i class="fa-solid fa-wrench text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                            <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Manufacture</h4>
                            We manufacture prototype parts and small series for our own development as well as that of our customers.
                            <br><br>
                            Our assembly workshop carries out all assembly from A to Z.
                        </div>
                        <div class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative mb-10">
                            <i class="fa-solid fa-certificate text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                            <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Guarantee</h4>
                            Our craftsmanship is ensured by a 3 year warranty and make sure that each of our customers protected in case of breakage or repair.
                        </div>
                        <div class="bg-wallgray group hover:bg-rosegold hover:text-wallgray transition ease-in-out duration-150 w-10/12 mx-auto lg:w-9/12 px-5 py-4 relative">
                            <i class="fa-solid fa-toolbox text-3xl absolute text-wallgray -left-3 -top-4 border border-rosegold px-1.5 py-1 group-hover:text-rosegold group-hover:bg-wallgray bg-rosegold opacity-75 group-hover:opacity-100 transition ease-in-out duration-150"></i>
                            <h4 class="text-rosegold text-center group-hover:text-white mb-2 font-lora uppercase">Repair</h4>
                            Benefiting from a workshop and craftsmen experts in watchmaking, Termitimes promise you the maintenance and repair of your products.
                        </div>
                    </div>
                    <div class="col-span-2 lg:col-span-1 px-6">
                        <p class="pt-10 lg:pt-4">
                            <span class="font-bold text-gray-300">Our manufactory — "We create timepieces out of time"</span>
                            <a href="https://immedia360.ch/visites/TRX/" ref="noopener" target="_blank" class="font-lora border-rosegold border py-1 mt-3 mb-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 block text-center">Virtual tour</a>
                            Company on a human scale, located in Cortaillod in Switzerland in the cradle of watchmaking.
                            <br><br>
                            Avant-garde, TR-X manufacture poses an alternative to traditional watchmaking within its manufacture with innovation and artisanal know-how, from the creation to the manufacture of components of movements to the dressing of the watch and creation of art.
                            <br><br>
                            Our spirit of constant innovation, in search of new mechanisms or materials, physical and chemical work in perfect synergy to create exceptional Watches and Artclocks which constantly push the limits of technique and aesthetics.
                        </p>
                    </div>
                </div>
            </div>

            <div id="ambassador" class="grid lg:grid-cols-2 lg:px-20 xl:px-40 pb-20">
                <div class="col-span-1 px-6 lg:px-0">
                    <video @volumechange="video.muted ? $emit('onSounds') : $emit('offSounds')" controls playsinline autoplay muted loop class="lg:w-4/5 border-2 shadow border-rosegold">
                        <source src="/assets/trx/trxVideoAmbassador.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="col-span-1 px-6 mt-5 lg:mt-0 lg:px-0">
                    <div class="flex relative mb-8">
                        <img src="/assets/trx/trxAmbass4.jpg" alt="TR-X Ambassador photo" class="lg:block w-1/3 object-cover hidden object-right lg:mr-3">
                        <img src="/assets/trx/trxAmbass3.jpg" alt="TR-X Ambassador photo" class="w-1/2 lg:w-1/3 object-cover object-center lg:mr-3">
                        <img src="/assets/trx/trxAmbass5.jpg" alt="TR-X Ambassador photo" class="w-1/2 lg:w-1/3 object-cover trxAmbass5">
                    </div>

                    <h3 class="text-rosegold">An ambassador passionate about technology and fashion</h3>
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 mx-auto">Cédric Bakambu</h2>

                    <p class="mt-4">To launch the TR-X Ghost , the brand has chosen to partner with Cédric Bakambu, a 30 year old Franco-Congolese footballer who is currently playing for Beijing Guoan, one of the main teams in the Chinese Premier League.</p>

                    <router-link to="/trx/ambassador" class="font-lora border-rosegold border py-1 mt-10 mb-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 block text-center">Learn more</router-link>
                </div>
            </div>

            <div id="press">
                <div class="overlay h-full w-full flex flex-col justify-center items-center">
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase pb-10">In the press</h2>
                    <router-link to="/trx/press" class="font-lora border-rosegold border py-1 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mx-auto text-center block">Enter the press lounge</router-link>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Lightbox from "../components/Lightbox";

export default {
    name: "Trx",
    components: {
        Navbar,
        Footer,
        Gallery,
        Lightbox
    },
    data() {
        return {
            activeColor: "ocean",
            enquiryForm: [
                {
                    type: "form",
                    product: "TR-X Ghost"
                }
            ],
            video: null
        }
    },
    mounted() {
        this.video = document.querySelector("#ambassador video");
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #inspiration {
        height: 150vh;
    }
    .trxAmbass5 {
        object-position: 75%;
    }
    #press {
        height: 50vh;
    }
    #ambassador {
        padding-top: 45px;
    }
    #landing {
        background: url("/assets/trx/trxPhoto2Mobile.jpg");
        background-position: top;
    }
}

@media only screen and (min-width : 992px) {
    #inspiration {
        height: 100vh;
    }
    .trxAmbass5 {
        object-position: 85%;
    }
    #press {
        height: 60vh;
    }
    #ambassador {
        padding-top: 90px;
    }
    #landing {
        background: url("/assets/trx/trxPhoto2.jpg");
        background-position: 0% 25%;
    }
}

#trxContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
}

#landing div {
    padding-top: calc(5vh + 90px);
}

.swiss {
    height: 20px;
    width: 20px;
}

#inspiration {
    margin-top: -90px; 
    padding-top: 90px;
}

#inspiration img {
    aspect-ratio: 1/1;
    width: 50%;
    object-position: center;
    object-fit: cover;
}

#inspiration .overflow-y-scroll::-webkit-scrollbar {
    width: 5px;
}

#inspiration .overflow-y-scroll::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    margin: 30px;
}

#inspiration .overflow-y-scroll::-webkit-scrollbar-thumb {
    background-color: #E6B474;
}

#product {
    background: url("/assets/trx/product/ocean/watchClose1.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.overlay {
    padding-top: 90px;
    background-color:rgba(0, 0, 0, 0.8);
}

#details {
    padding-top: 90px;
}

#manufacture {
    background: url("/assets/trx/trxManu.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: bottom;
}

#ambassador img {
    height: 350px;
}

#swatches div {
    height: 40px;
    width: 40px;
}

#swatches #atomic {
    background: #81fd77;
}

#swatches #lava {
    background: #feac02;
}

#swatches #ocean {
    background: #0066ff;
}

#swatches #ray {
    background: #ffe30b;
}

#press {
    background-image: url("/assets/trx/trxPress.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 42%;
}

#press .overlay {
    background-color:rgba(0, 0, 0, 0.70);
    padding-top: 0 !important;
}

</style>
