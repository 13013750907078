import { createRouter, createWebHistory } from "vue-router";
import Intro from "../views/Intro";
import Home from "../views/Home";
import About from "../views/About";
import Contact from "../views/Contact";
import Trx from "../views/Trx";
import TrxAmbassador from "../views/TrxAmbassador";
import TrxPress from "../views/TrxPress";
import Fleury from "../views/Fleury";
// import HannaHoGems from "../views/HannaHoGems";
import Orkos from "../views/Orkos";
import MadeGVA from "../views/MadeGVA";


// Giberg
import GibergHome from "../views/GibergHome";
import GibergDominic from "../views/GibergDominic";
import GibergMaria from "../views/GibergMaria";
import GibergArt from "../views/GibergArt";
import GibergAhton from "../views/GibergAhton";
import GibergCreators from "../views/GibergCreators";
import GibergCustomMade from "../views/GibergCustomMade";
import GibergLWatches from "../views/GibergLWatches";
import GibergLJewellery from "../views/GibergLJewellery";
import GibergOlora from "../views/GibergOlora";
import GibergNiura from "../views/GibergNiura";
import GibergTrilevis from "../views/GibergTrilevis";
import GibergMWatches from "../views/GibergMWatches";
import GibergMJewellery from "../views/GibergMJewellery";
import GibergAlagaro from "../views/GibergAlagaro";


const routes = [
  { path: "/start", component: Intro },
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/about", component: About },
  { path: "/contact", component: Contact },
  { path: "/trx", component: Trx },
  { path: "/trx/ambassador", component: TrxAmbassador },
  { path: "/trx/press", component: TrxPress },
  { path: "/fleury", component: Fleury },
  // { path: "/hannaHoGems", component: HannaHoGems },
  { path: "/orkos", component: Orkos },
  { path: "/madegva", component: MadeGVA },
  // Giberg
  { path: "/giberg", component: GibergHome },
  { path: "/giberg/ambassadors/dominic-stricker", component: GibergDominic },
  { path: "/giberg/ambassadors/maria-khoreva", component: GibergMaria },
  { path: "/giberg/art", component: GibergArt },
  { path: "/giberg/ahton", component: GibergAhton },
  { path: "/giberg/creators", component: GibergCreators },
  { path: "/giberg/custom", component: GibergCustomMade },
  { path: "/giberg/watches/ladies", component: GibergLWatches },
  { path: "/giberg/jewellery/ladies", component: GibergLJewellery },
  { path: "/giberg/watches/ladies/olora/:color", component: GibergOlora },
  { path: "/giberg/watches/ladies/niura/:color", component: GibergNiura },
  { path: "/giberg/watches/trilevis", component: GibergTrilevis },
  { path: "/giberg/watches/gentlemen", component: GibergMWatches },
  { path: "/giberg/jewellery/gentlemen", component: GibergMJewellery },
  { path: "/giberg/watches/gentlemen/alagaro/:color", component: GibergAlagaro },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
        return { 
          top: 0,
          behavior: 'smooth'
        }
    }
  }
});

export default router;