<template>
    <div class="grid grid-rows-3">
        <div class="row-span-2 relative">
            <Lightbox ref="headerImage" :media="headerImage" />
            <img @click="headerLightbox(`/assets/trx/product/${color}/${trxImages[activeImage]}`)" class="gallery mx-auto" v-if="brand === 'trx' && !activeVid" :src="`/assets/trx/product/${color}/${trxImages[activeImage]}`" alt="Carousel image" />
            <img @click="headerLightbox(`/assets/fleury/product/${fleuryImages[activeImage]}`)" class="gallery mx-auto" v-if="brand === 'fleury' && color == 'zen'" :src="`/assets/fleury/product/${fleuryImages[activeImage]}`" alt="Carousel image" />
            <img @click="headerLightbox(`/assets/fleury/product/${fleuryLimitedImages[activeImage]}`)" class="gallery mx-auto" v-if="brand === 'fleury' && color == 'zenMagical'" :src="`/assets/fleury/product/${fleuryLimitedImages[activeImage]}`" alt="Carousel image" />
            <img @click="headerLightbox(`/assets/orkos/product/${color}/${orkosImages[activeImage]}`)" class="gallery mx-auto" v-if="brand === 'orkos'" :src="`/assets/orkos/product/${color}/${orkosImages[activeImage]}`" alt="Carousel image" />
            <img @click="headerLightbox(`/assets/madegva/${color}/${madegvaImages[activeImage]}`)" class="gallery mx-auto" v-if="brand === 'madegva'" :src="`/assets/madegva/${color}/${madegvaImages[activeImage]}`" alt="Carousel image" />
            <!-- TR-X Video -->
            <video v-if="brand === 'trx' && activeVid" id="productVideo" playsinline controls autoplay muted loop class="absolute top-20 ml-auto border-2 shadow border-rosegold">
                <source src="/assets/trx/trxVideo.mp4" type="video/mp4">
            </video>
        </div>

        <div class="row-span-1 pt-4">
            <div class="gallery" v-if="!activeVid">
                <div v-if="brand === 'orkos'" id="picCarousel" class="flex items-center overflow-x-scroll">
                    <img class="cursor-pointer mx-2 filter hover:brightness-75 transition ease-linear duration-75 mb-4" @click="activeImage = index" v-for="(image, index) in orkosImages" :key="index" :src="`/assets/orkos/product/${color}/${image}`" />
                </div>
                <div v-if="brand === 'madegva'" id="picCarousel" class="flex items-center overflow-x-scroll">
                    <img class="cursor-pointer mx-2 filter hover:brightness-75 transition ease-linear duration-75 mb-4" @click="activeImage = index" v-for="(image, index) in madegvaImages" :key="index" :src="`/assets/madegva/${color}/${image}`" />
                </div>
                <div v-if="brand === 'trx'" id="picCarousel" class="flex items-center overflow-x-scroll">
                    <img class="cursor-pointer mx-2 filter hover:brightness-75 transition ease-linear duration-75 mb-4" @click="activeImage = index" v-for="(image, index) in trxImages" :key="index" :src="`/assets/trx/product/${color}/${image}`" />
                </div>
                <div v-if="brand === 'fleury' && color =='zen'" id="picCarousel" class="flex items-center overflow-x-scroll">
                    <img class="cursor-pointer mx-2 filter hover:brightness-75 transition ease-linear duration-75 mb-4" @click="activeImage = index" v-for="(image, index) in fleuryImages" :key="index" :src="`/assets/fleury/product/${image}`" />
                </div>
                <div v-if="brand === 'fleury' && color =='zenMagical'" id="picCarousel" class="flex items-center overflow-x-scroll">
                    <img class="cursor-pointer mx-2 filter hover:brightness-75 transition ease-linear duration-75 mb-4" @click="activeImage = index" v-for="(image, index) in fleuryLimitedImages" :key="index" :src="`/assets/fleury/product/${image}`" />
                </div>
                <div id="scrollArrows" class="z-50 mx-3 lg:mx-0 flex justify-between">
                    <button @click="scrollHori(false)" class="text-gray-300 hover:text-rosegold transition ease-linear duration-75"><i class="fa-solid fa-arrow-left"></i></button>
                    <button @click="scrollHori(true)" class="text-gray-300 hover:text-rosegold transition ease-linear duration-75"><i class="fa-solid fa-arrow-right"></i></button>
                </div>
            </div>


            <button v-if="!activeVid && brand ==='trx'" @click="toggleVideo" class="mx-auto font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Watch video</button>
            <button v-if="activeVid && brand==='trx'" @click="toggleVideo" class="mx-auto font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Close video</button>
        </div>
    </div>
</template>

<script>
import Lightbox from "./Lightbox";

export default {
    name: "Gallery",
    props: {
        color: String,
        brand: String
    },
    components: {
        Lightbox
    },
    data() {
        return {
            activeVid: false,
            activeImage: 0,
            headerImage: [{
                type: "image",
                src: ""
            }],
            fleuryImages: [
                "watch2.jpg",
                "watch3.jpg",
                "watch4.jpg",
                "watch5.jpg",
                "watch6.jpg",
                "watch7.jpg",
                "flower.jpg"
            ],
            fleuryLimitedImages: [
                "limited1.jpg",
                "limited2.jpg",
                "limited3.jpg",
                "limited4.jpg"
            ],
            trxImages: [
                "watch1.png",
                "watch2.png",
                "watchdark1.png",
                "watchdark2.png",
                "watchClose1.jpg",
                "watchClose2.jpg",
                "watchClose3.jpg",
                "watchClose4.jpg",
                "watchClose5.jpg",
                "watchClose6.jpg",
            ],
            orkosImages: [
                "1.jpg",
                "2.jpg",
                "3.jpg",
                "4.jpg",
                "5.jpg",
                "6.jpg"
            ],
            madegvaImages: [
                "close.jpg",
                "movement.jpg",
                "dial.jpg",
                "hero.jpg"
            ]
        }
    },
    methods: {
        headerLightbox(imgSrc) {
            this.headerImage[0].src = imgSrc;
            this.$refs.headerImage.toggleLightbox();
        },
        scrollHori(direction) {
            if (direction) {
                document.querySelector("#picCarousel").scrollLeft += 350;
            } else {
                document.querySelector("#picCarousel").scrollLeft -= 350;
            }
        },
        toggleVideo() {
            this.activeVid = !this.activeVid;
        },
        setActiveImage(index) {
            this.activeImage = index;
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #picCarousel::-webkit-scrollbar-track {
        margin: 40px;
    }
}

@media only screen and (min-width : 992px) {
    #picCarousel::-webkit-scrollbar-track {
        margin: 30px;
    }
}

.row-span-2 {
    height: 60vh;
}

.row-span-2 img {
    height: 100%;
    width: auto;
    object-fit: contain;
}

#picCarousel img {
    height: 18vh;
    width: auto;
}

#picCarousel::-webkit-scrollbar {
    height: 5px;
}

#picCarousel::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    margin: 40px;
}

#picCarousel::-webkit-scrollbar-thumb {
    background-color: #E6B474;
}

#scrollArrows {
    margin-top: -13px;
}
</style>