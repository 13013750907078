<template>
    <div id="navContainer" class="sticky top-0 z-50">
        <div class='font-gelasio flex justify-between items-center h-full lg:px-20 px-6 flex text-rosegold'>
            <div class="text-center z-50">
                <button @click="$emit('toggleSounds')" id="muteButton" aria-label="muteButton" class="bg-wallgray rounded-full hover:text-wallgray transition duration-75 ease-linear hover:bg-rosegold">
                    <span v-if="this.$root.muted"><i class="text-xl fa-solid fa-volume-xmark"></i></span>
                    <span v-if="!this.$root.muted"><i class="text-xl fa-solid fa-volume-high"></i></span>
                </button>
            </div>
            <div v-if="!noMenu" class="text-center z-50">
                <button aria-label="navbarMenu">
                    <img @click='toggleMenu' src="/assets/logo.png" alt="Logo" id="navLogo" class="z-50 rotating">
                </button>
            </div>
        </div>
        <div id="menu" class='font-gelasio shadow ml-auto hidden h-screen lg:w-2/5 xl:w-1/6 w-3/5 pt-2 pb-10 text-lg flex flex-col overflow-x-hidden'>
            <div class="mt-8 text-gray-300 uppercase text-center tracking-wide flex flex-col">
                <a href="/start" class="mb-3 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Start</a>
                <router-link to="/home" class="my-3 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Home</router-link>
                <router-link to="/about" class="my-3 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">About</router-link>
                <router-link to="/contact" class="my-3 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Contact Us</router-link>
                <div class="flex justify-center items-center hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105 mt-3 mb-1 cursor-pointer" @click="toggleBrandsMenu">Brands<i id="brandsArrow" class="ml-2 fa-solid fa-chevron-down text-sm"></i></div>
                <div class="flex-col flex hidden" id="brandLinks">
                    <div class="flex justify-center items-center hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105"><router-link to="/fleury">Fleury</router-link><i id="fleuryArrow" class="cursor-pointer ml-2 fa-solid fa-chevron-down text-sm" @click="toggleLinks('fleury')"></i></div>
                    <div class="flex-col flex hidden mt-2 mb-3 text-sm" id="fleuryLinks">
                        <router-link to="/fleury/#about" class="mb-1 hover:text-rosegold transition duration-75 ease-linear inline-block transform hover:scale-105">About</router-link>
                        <router-link to="/fleury/#product" class="mb-1 hover:text-rosegold transition duration-75 ease-linear inline-block transform hover:scale-105">Product</router-link>
                        <router-link to="/fleury/#atelier" class="mb-1 hover:text-rosegold transition duration-75 ease-linear inline-block transform hover:scale-105">Atelier</router-link>
                    </div>
                    <div class="flex justify-center items-center hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105"><router-link to="/giberg">Giberg</router-link><i id="gibergArrow" class="cursor-pointer ml-2 fa-solid fa-chevron-down text-sm" @click="toggleLinks('giberg')"></i></div>
                    <div class="flex-col flex hidden mt-2 mb-3 text-sm" id="gibergLinks">
                        <router-link to="/giberg/watches/ladies" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Ladies' Watches</router-link>
                        <router-link to="/giberg/watches/gentlemen" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Gentlemen's Watches</router-link>
                        <router-link to="/giberg/jewellery/ladies" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Ladies' Jewellery</router-link>
                        <router-link to="/giberg/jewellery/gentlemen" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Gentlemen's Jewellery</router-link>
                        <router-link to="/giberg/art" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Objets D’Art</router-link>
                        <router-link to="/giberg/#ambassadors" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Ambassadors</router-link>
                        <router-link to="/giberg/#manufacture" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Manufacture</router-link>
                    </div>
                    <div class="flex justify-center items-center hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105"><router-link to="/madegva">M.A.D’E GVA</router-link><i id="madegvaArrow" class="cursor-pointer ml-2 fa-solid fa-chevron-down text-sm" @click="toggleLinks('madegva')"></i></div>
                    <div class="flex-col flex hidden mt-2 mb-3 text-sm" id="madegvaLinks">
                        <router-link to="/madegva/#about" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">About</router-link>
                        <router-link to="/madegva/#product" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Details</router-link>
                        <router-link to="/madegva/#details" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Product</router-link>
                    </div>
                    <div class="flex justify-center items-center hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105"><router-link to="/orkos">Orkos</router-link><i id="orkosArrow" class="cursor-pointer ml-2 fa-solid fa-chevron-down text-sm" @click="toggleLinks('orkos')"></i></div>
                    <div class="flex-col flex hidden mt-2 mb-3 text-sm" id="orkosLinks">
                        <router-link to="/orkos/#about" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">About</router-link>
                        <router-link to="/orkos/#concept" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Concept</router-link>
                        <router-link to="/orkos/#details" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Product</router-link>
                        <router-link to="/orkos/#action" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">In Action</router-link>
                        <router-link to="/orkos/#press" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Press</router-link>
                    </div>
                    <div class="flex justify-center items-center hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105"><router-link to="/trx">TR-X</router-link><i id="trxArrow" class="cursor-pointer ml-2 fa-solid fa-chevron-down text-sm" @click="toggleLinks('trx')"></i></div>
                    <div class="flex-col flex hidden mt-2 mb-3 text-sm" id="trxLinks">
                        <router-link to="/trx/#inspiration" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Inspiration</router-link>
                        <router-link to="/trx/#product" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Product</router-link>
                        <router-link to="/trx/#manufacture" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Manufacture</router-link>
                        <router-link to="/trx/#ambassador" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Ambassador</router-link>
                        <router-link to="/trx/#press" class="mb-1 hover:text-rosegold transition duration-75 ease-linear transform hover:scale-105">Press</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
``
<script>
import gsap from "gsap";

export default {
    name: "Navbar",
    data() {
        return {
            activeMenu: false,
            brandsMenu: false,
            links: {
                fleury: false,
                trx: false,
                orkos: false
            }
        }
    },
    props: {
        noMenu: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        document.body.classList.add("overflow-x-hidden");
    },
    methods: {
        toggleMenu() {
            const menu = document.querySelector("#menu");
            if (this.activeMenu) {
                gsap.fromTo("#menu", { xPercent: 0, opacity: 1 }, { xPercent: 100, opacity: 0, duration: 0.2 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                    this.activeMenu = false;
                }, 300)
            } else {
                gsap.fromTo("#menu", { xPercent: 100, opacity: 0 }, { xPercent: 0, opacity: 1, duration: 0.3 });
                menu.classList.toggle("hidden");
                this.activeMenu = true;
            }
        },
        toggleBrandsMenu(){
            const menu = document.querySelector("#brandLinks");
            const arrow = document.querySelector("#brandsArrow");
            if (this.brandsMenu) {
                gsap.fromTo("#brandLinks", { yPercent: 0 }, { yPercent: -50, opacity: 0, duration: 0.3 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                    this.brandsMenu = !menu.classList.contains("hidden");
                    arrow.classList.remove("transform");
                    arrow.classList.remove("rotate-180");
                }, 300)
            } else {
                gsap.fromTo("#brandLinks", { yPercent: -50, opacity: 0 }, { yPercent: 0, opacity: 1, duration: 0.2 });
                menu.classList.toggle("hidden");
                this.brandsMenu = !menu.classList.contains("hidden");
                arrow.classList.add("transform");
                arrow.classList.add("rotate-180");
            }
        },
        toggleLinks(brand){
            const menu = document.querySelector(`#${brand}Links`);
            const arrow = document.querySelector(`#${brand}Arrow`);
            if (this.links[brand]) {
                gsap.to(`#${brand}Links`, { opacity: 0, duration: 0.3 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                    arrow.classList.remove("transform");
                    arrow.classList.remove("rotate-180");
                    this.links[brand] = !menu.classList.contains("hidden");
                }, 300)
            } else {
                // Close all other brandLinks
                const links = this.links;
                Object.keys(links).map(function(key) {
                    if (links[key]) {
                        links[key] = false;
                        document.querySelector(`#${key}Links`).classList.toggle("hidden");
                        document.querySelector(`#${key}Arrow`).classList.remove("transform");
                        document.querySelector(`#${key}Arrow`).classList.remove("rotate-180");
                    }
                });
                this.links = links;

                gsap.fromTo(`#${brand}Links`, { opacity: 0 }, { opacity: 1, duration: 0.2 });
                menu.classList.toggle("hidden");
                this.links[brand] = !menu.classList.contains("hidden");
                arrow.classList.add("transform");
                arrow.classList.add("rotate-180");
            }
        }
    }
}
</script>

<style scoped>
#navContainer {
    height: 90px;
}

#navLogo {
    height: 44px;
    width: 44px;
}

#muteButton {
    height: 43px;
    width: 43px;
}

#menu {
    background: url("/assets/wall.png");
    background-size: cover;
    background-position: 100% 100%;
    padding-top: 90px;
    margin-top: -90px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 60s linear infinite;
  -moz-animation: rotating 60s linear infinite;
  -ms-animation: rotating 60s linear infinite;
  -o-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}
</style>