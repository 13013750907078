<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="madegvaContainer"  class="bg-wallgray text-gray-300">
        <div id="landing">
            <div class="overlay xl:px-40">
                <div class="px-6 xl:w-2/5 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">M.A.D’E GVA</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Monney Atelier D’Exclusivités</h3>
                    <p class="mt-2 text-lg leading-snug">M.A.D’E GVA for Monney Atelier D’Exclusivités is a private Swiss company based in Geneva, a mecca of the Swiss watch industry and where the largest watchmaking groups rub shoulders.</p>
                    <a href="#details" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire Now</a>
                    <p class="leading-snug text-sm">Official agent/retailer for Singapore, Australia, Myanmar, Cambodia, China, India, Indonesia, Laos, New Zealand, Phillipines, Thailand, USA and Vietnam.</p>
                </div>
            </div>
        </div>

        <div id="about" class="grid grid-cols-2">
            <Lightbox ref="concepts" :media="concepts" />

            <div class="col-span-2 lg:col-span-1 lg:px-20 xl:px-40 my-auto">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/madegva/about1.jpg" alt="Orkos photos" class="lg:border-r-2 border-r border-b lg:border-b-2 border-rosegold inline-block">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/madegva/about3.jpg" alt="Orkos photos" class="lg:border-l-2 border-l border-b lg:border-b-2 border-rosegold inline-block">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/madegva/about4.jpg" alt="Orkos photos" class="lg:border-r-2 border-r border-t lg:border-t-2 border-rosegold inline-block">
                <img @click="this.$refs.concepts.toggleLightbox();" src="/assets/madegva/about2.jpg" alt="Orkos photos" class="lg:border-l-2 border-l border-t lg:border-t-2 border-rosegold inline-block">
            </div>
            <div class="col-span-2 lg:col-span-1 lg:pl-14 pr-8 my-8 mx-6 lg:mr-20 xl:mr-40 overflow-y-auto lg:border-l border-gray-600">
                <h3 class="text-rosegold">Métiers d'art</h3>
                <h2 class="gradient-rosegold text-5xl mt-2 text-rosegold font-lora">Watches</h2>
                <p class="mt-5 text-lg">We invite you to dive into the world of M.A.D’E GVA to discover watchmaking creations with a unique character born from the desire to bring together the know-how and talent of Swiss craftsmen. We offer tailor-made watches, made in our Geneva workshop by our watchmakers in compliance with watchmaking traditions and Swiss quality.</p>
                <p class="mt-4 text-lg">Passionate about art, sports or haute horlogerie, explore our collection and look for the model that suits you.</p>
            </div>
        </div>

        <div id="product">
                <div class="overlay lg:px-20 xl:px-40 px-6 pb-10 lg:pb-20">
                    <h3 class="text-rosegold">Monney Atelier D’Exclusivités</h3>
                    <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase mt-2 lg:w-1/3">Métier d’art</h2>
                    <p class="pt-4 pb-12">
                        
                    </p>

                    <h3 class="font-lora gradient-rosegold text-2xl lg:w-1/4">Product Details</h3>
                    <div class="pt-2 pb-8 flex flex-col gap-5">
                        <span><b>Reference</b> <span class="text-rosegold">—</span> Tiger/Dragon (unique pieces)</span>
                        <span><b>Diameter</b> <span class="text-rosegold">—</span> 41 mm</span>
                        <span><b>Thickness</b> <span class="text-rosegold">—</span> 11.6 mm</span>
                        <span><b>Movement</b> <span class="text-rosegold">—</span> Swiss automatic movement, ETA 2836, 42h power reserve, 28'800 alt/h</span>
                        <span><b>Functions</b> <span class="text-rosegold">—</span> Hours, minutes, seconds</span>
                        <span><b>Case</b> <span class="text-rosegold">—</span> Stainless steel with sapphire glass</span>
                        <span><b>Back</b> <span class="text-rosegold">—</span> Open back with sapphire glass</span>
                        <span><b>Dial</b> <span class="text-rosegold">—</span> Miniature paint dial</span>
                        <span><b>Bracelet</b> <span class="text-rosegold">—</span> Swiss rubber</span>
                        <span><b>Buckle</b> <span class="text-rosegold">—</span> Stainless steel</span>
                        <span><b>Year</b> <span class="text-rosegold">—</span> 2022</span>
                        <span><b>Guarantee</b> <span class="text-rosegold">—</span> 2 years</span>
                    </div>
                </div>
            </div>

        <div id="details" class="grid grid-cols-5 lg:px-20 xl:px-40">
            <div class="col-span-5 lg:col-span-2">
                <Gallery :brand="'madegva'" :color="activeColor" />
            </div>
            <div class="col-span-5 lg:col-span-3 mt-10 md:mb-10 xl:mb-20 px-6 lg:ml-16">
                <h2 class="font-lora text-rosegold text-3xl lg:text-4xl mb-3 lg:mb-6 gradient-rosegold uppercase">M.A.D’E Métier d’art The Safe of time special edition</h2>
                <p>
                    Inspired by the Asian culture, the Dragon, the Tiger, our “Métiers d’Art” collection reveals miniature paintings on dials handmade by our artists. Several days of meticulous work are required for the realization of such a dial, making each piece unique. The high-end finishes of our movements complement these creations entirely made in-house according to the precise criteria of Swiss watchmakers.
                    <br /><br />
                    <span class="gradient-rosegold font-bold">On request, we can use different types of paint to add uniqueness and exclusivity to your watch.</span>
                </p>

                <h4 class="font-rosegold font-bold mt-8">Color — 
                    <span class="uppercase">
                        <span v-if="activeColor === 'dragon'">Red Dragon</span>
                        <span v-if="activeColor === 'tiger'">Tiger</span>
                    </span>
                </h4>
                <div id="swatches" class="flex mt-4">
                    <div id="dragon" @click="activeColor='dragon'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                    <div id="tiger" @click="activeColor='tiger'" class="cursor-pointer hover:opacity-100 opacity-70 mr-1.5 rounded-full"></div>
                </div>

                <h4 v-if="activeColor === 'tiger'" class="mt-12 text-3xl">S$12,500.00</h4>
                <h4 v-if="activeColor === 'dragon'" class="mt-12 text-3xl">S$12,500.00</h4>

                <p class="mt-2">Price includes GST</p>
                <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 mt-4 mb-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                
                <Lightbox ref="enquiryForm" :media="enquiryForm" />
                <p class="text-base mb-8 lg:mb-0">2-year guarantee offered by M.A.D’E GVA</p>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Lightbox from "../components/Lightbox";

export default {
    name: "Orkos",
    components: {
        Navbar,
        Footer,
        Lightbox,
        Gallery
    },
    data() {
        return {
            activeColor: "dragon",
            enquiryForm: [
                {
                    type: "form",
                    product: "M.A.D’E Métier d’art The Safe of Time special edition"
                }
            ],
            concepts: [
                {
                    type: "image",
                    src: "/assets/madegva/about1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/madegva/about3.jpg"
                },
                {
                    type: "image",
                    src: "/assets/madegva/about4.jpg"
                },
                {
                    type: "image",
                    src: "/assets/madegva/about2.jpg"
                },

            ]
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #about {
        height: 150vh;
    }

    #madegvaContainer #landing {
        background-position: center;
    }
}

@media only screen and (min-device-width: 992px) {
    #about {
        height: 100vh;
    }

    #madegvaContainer #landing {
        background-position: 50% 33%;
    }
}

#madegvaContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#madegvaContainer #landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-image: url("/assets/madegva/dragon/hero.jpg");
    background-size: cover;
}

#madegvaContainer #landing .overlay {
    background-color:rgba(0, 0, 0, 0.70);
    padding-top: calc(5vh + 90px) !important;
    min-height: calc(80vh + 90px);
}

#madegvaContainer #about {
    padding-top: 90px;
    margin-top: -90px;
}

#madegvaContainer #about img {
    aspect-ratio: 1/1;
    width: 50%;
    object-position: center;
    object-fit: cover;
}

#madegvaContainer #about .overflow-y-auto::-webkit-scrollbar {
    width: 5px;
}

#madegvaContainer #about .overflow-y-auto::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    margin: 30px;
}

#madegvaContainer #about .overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: #E6B474;
}

#madegvaContainer ol {
    padding-left: 2rem !important;
}

#madegvaContainer #product {
    background: url("/assets/madegva/tiger/hero.jpg");
    background-attachment: fixed;
    background-size: cover;
}

#madegvaContainer .overlay {
    padding-top: 90px;
    background-color:rgba(0, 0, 0, 0.8);
}

#madegvaContainer #details {
    padding-top: 90px;
}

#madegvaContainer #swatches div {
    height: 40px;
    width: 40px;
}

#madegvaContainer #swatches #dragon {
    background: #e8332a;
}

#madegvaContainer #swatches #tiger {
    background: #426a83;
}
</style>