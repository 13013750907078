<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergMariaContainer" class="bg-wallgray text-gray-300">
        <Lightbox ref="mariaGallery" :media="mariaGallery" />
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Maria Khoreva</h3>
                    <p class="mt-2 text-lg leading-snug">
                        The total lightness of being: Maria Khoreva embodies the present and the future of ballet with her incomparable grace and unique charisma. And yet her international career has only just begun. Art in its timeless form is here captured in extraordinary moments with her audience.
                    </p>
                    <div class="flex">
                        <router-link to="#more" class="font-lora border-rosegold mr-2 border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">Explore</router-link>
                        <button @click="this.$refs.mariaGallery.toggleLightbox();" class="font-lora border-rosegold ml-2 border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Gallery</button>
                    </div>
                    <p class="leading-snug text-sm">Official agent/retailer for Australia, Indonesia, Malaysia, New Zealand, Philippines, Thailand, Vietnam, Cambodia, Myanmar, Laos, Middle East, India, China, Japan, Korea, USA and Latin America.</p>
                </div>
            </div>
        </div>

        <div id="more" class="xl:px-40 lg:px-20 px-6 py-10 lg:py-24 gap-6 lg:gap-12 grid grid-cols-1 lg:grid-cols-2">
            <div class="col-span-1 lg:col-span-2">
                <h3 class="text-rosegold text-center">Maria Khoreva’s watch</h3>
                <h2 class="col-span-2 font-lora gradient-rosegold text-gray-300 text-4xl lg:text-5xl uppercase mt-2 mb-6 text-center">Olora Diamond</h2>
            </div>
            <img src="/assets/giberg/ambassadors/maria1.jpg" alt="Maria Khoreva picture" class="col-span-1 border-2 border-rosegold" />
            <div class="col-span-1">
                <blockquote class="text-2xl italic mt-6">
                    “Behind every little movement of the body, behind the complex sequences of a choreography, there is hard work to make this all seem easy to the audience. Every minute, every day.”
                </blockquote>
                <p class="text-lg">
                    <br>
                    <b class="gradient-rosegold">Maria Khoreva</b> (born 2000) grew up in St. Petersburg, Russia. Before attending the Vaganova Ballet Academy she practiced rhythmic gymnastics, and took piano and art classes. In 2018, right after graduation, Maria joined the famous Mariinsky Ballet. She debuted as Terpsichore in George Balanchine's "Apollo" alongside Mariinsky principal Xander Parish in the title role. In the fall of 2018, she danced the title role of Paquita in a three-act ballet, a rarity for such a recent graduate, and at the end of the month was promoted to first soloist. Maria is widely known on Instagram under the handle @marachok. She documents her day-to-day life as a dancer in both English and Russian. In addition, she has already written a book: "Teach Me Ballet: How to Educate Your Body," published in December 2020.
                </p>
                <router-link to="/giberg/watches/ladies/olora/diamond" class="font-lora border-rosegold mr-2 border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-1/2 xl:w-1/3 text-center">View watch</router-link>
            </div>
        </div>
    </div>

    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";


export default {
    name: "GibergDominic",
    components: {
        Navbar,
        Lightbox,
        Footer
    },
    data() {
        return {
            mariaGallery: [
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/maria1.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/maria2.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/maria3.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/maria4.png"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/maria5.jpg"
                },
                {
                    type: "image",
                    src: "/assets/giberg/ambassadors/maria6.png"
                }
            ]
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergMariaContainer #landing .overlay {
        min-height: calc(80vh + 90px);
        background-color:rgba(0, 0, 0, 0.65);
    }
}

@media only screen and (min-device-width: 992px) {
    #gibergMariaContainer #landing .overlay {
        min-height: calc(80vh + 90px);
        background-color:rgba(0, 0, 0, 0.25);
    }
}

#gibergMariaContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergMariaContainer .overlay {
    background-color:rgba(0, 0, 0, 0.55);
    padding-top: calc(90px + 5vh) !important;
}

#gibergMariaContainer #landing {
    background: url("/assets/giberg/mariaLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
    background-position: 55% 50%; 
}

#gibergMariaContainer #landing .overlay {
    min-height: calc(80vh + 90px);
}

</style>