<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergCustomContainer" class="text-gray-300 bg-wallgray">
        <div id="landing">
            <div class="overlay xl:px-40 lg:px-20">
                <div class="px-6 xl:w-1/2 pb-10 lg:pb-0">
                    <h2 class="uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
                    <h3 class="text-rosegold mt-10 text-xl">Breaking boundaries to create infinite possibilities</h3>
                    <p class="mt-2 text-lg leading-snug">
                        Giberg is specialised in individual pieces and ultra-limited editions. On request, the experts of Giberg will create personal masterpieces according to your specifications.
                    </p>
                    <router-link to="#collection" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Explore collection</router-link>
                    <p class="leading-snug text-sm">Official agent/retailer for Singapore, Malaysia, Vietnam, India, Australia, New Zealand, Thailand, Philippines, Indonesia, China, Hong Kong, Japan, Korea, Middle East, Laos, Myanmar and Cambodia.</p>
                </div>
            </div>
        </div>
        <div id="collection" class="px-6 xl:px-40 lg:px-20">
            <Lightbox ref="enquiryForm" :media="enquiryForm"/>
            <h2 class="font-lora gradient-rosegold text-center text-gray-300 text-5xl uppercase mt-2 lg:w-3/4 mx-auto">Custom made masterpieces</h2>
            <p class="py-5 text-lg font-bold text-gray-300 text-center italic">Our quest for the most superior quality and uniqueness is reflected in the creation of the dragon Ahton: a milestone in craftsmanship on the highest level!</p>
            <p class="text-center py-4">At Giberg the sky is the limit. The team at Swiss studios of Giberg are the most experienced experts in handling precious materials worldwide. Led by Andreas Altmann Giberg creates breathtaking masterpieces by combining technical innovation and artistic vision.</p>
            <Lightbox ref="falconBox" :media="falconBox"/>
            <Lightbox ref="falconRing" :media="falconRing"/>
            <Lightbox ref="necklaceCube" :media="necklaceCube"/>
            <Lightbox ref="virtus" :media="virtus"/>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 mt-10">
                <div class="col-span-1">
                    <img @click="this.$refs.falconBox.toggleLightbox();" src="/assets/giberg/customMade/falconBox.png" class="filter cursor-pointer hover:brightness-75 transition ease-linear duration-75" alt="Falcon Box" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl w-1/2 gradient-rosegold">Falcon Box</h3>
                    <p>
                        14x10x7cm<br>
                        532gr of 18K Yellow Gold<br>
                        1382 brilliants (total 16.73 carat)<br>
                        855gr of rock Crystal<br>
                        S$731,630 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                </div>
                <div class="col-span-1">
                    <img @click="this.$refs.falconRing.toggleLightbox();" src="/assets/giberg/customMade/falconRing.png" class="filter cursor-pointer hover:brightness-75 transition ease-linear duration-75" alt="Falcon Ring" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl w-1/2 gradient-rosegold">Falcon Ring</h3>
                    <p>
                        90gr of 18K Yellow Gold<br>
                        549 brilliants (total 1.52 carat)<br>
                        S$118,460 — GST included
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                </div>
                <div class="col-span-1">
                    <img @click="this.$refs.necklaceCube.toggleLightbox();" src="/assets/giberg/customMade/necklaceCube.jpg" class="filter cursor-pointer hover:brightness-75 transition ease-linear duration-75" alt="Necklace Cube" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl w-1/2 gradient-rosegold">Necklace Cube</h3>
                    <p>
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                </div>
                <div class="col-span-1">
                    <img @click="this.$refs.virtus.toggleLightbox();" src="/assets/giberg/customMade/virtus.jpg" class="filter cursor-pointer hover:brightness-75 transition ease-linear duration-75" alt="Virtus" />
                    <h3 class="text-rosegold mt-4 mb-2 text-3xl w-1/2 gradient-rosegold">Virtus</h3>
                    <p>
                        55gr of Rose Gold (4N)<br>
                        13 Emeralds (total 0.22 ct)<br>
                        118 brilliants (total 1.38ct)
                    </p>
                    <button @click="this.$refs.enquiryForm.toggleLightbox();" class="font-lora border-rosegold border py-1 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 w-2/3 md:w-1/4 lg:w-1/2 xl:w-1/3 text-center">Enquire now</button>
                </div>

            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Lightbox from "../components/Lightbox";
import Footer from "../components/Footer";

export default {
    name: "GibergCustomMade",
    components: {
        Navbar,
        Lightbox,
        Footer
    },
    data() {
        return {
            falconBox: [
                {
                    type: "image",
                    src: "/assets/giberg/customMade/falconBox.png"
                }
            ],
            falconRing: [
                {
                    type: "image",
                    src: "/assets/giberg/customMade/falconRing.png"
                }
            ],
            necklaceCube: [
                {
                    type: "image",
                    src: "/assets/giberg/customMade/necklaceCube.jpg"
                }
            ],
            virtus: [
                {
                    type: "image",
                    src: "/assets/giberg/customMade/virtus.jpg"
                }
            ],
            enquiryForm: [
                {
                    type: "form",
                    product: "Custom Made Masterpieces"
                }
            ]
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #gibergCustomContainer #landing {
        background-position: 60% 50% !important;
    }
}

#gibergCustomContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergCustomContainer .overlay {
    background-color:rgba(0, 0, 0, 0.65);
    padding-top: calc(90px + 5vh) !important;
    min-height: calc(80vh + 90px);
}

#gibergCustomContainer #landing {
    background: url("/assets/giberg/customMadeLanding.jpg");
    margin-top: -90px;
    min-height: calc(80vh + 90px);
    background-size: cover;
}

#gibergCustomContainer #collection {
    padding-top: 90px;
    padding-bottom: 45px;
}

#gibergCustomContainer #collection img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 100%;
}
</style>