<template>
    <Navbar @toggle-sounds="$emit('toggleSounds')" />
    <div id="gibergContainer" class="bg-wallgray text-gray-300">
        <div id="landing" class="relative">
            <h2 id="brandName" class="absolute uppercase text-7xl text-rosegold font-lora gradient-rosegold">Giberg</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2">
                <div class="ladies col-span-1">
                    <div class="overlay pr-12 pb-2 flex-col flex justify-end">
                        <div class="ml-auto">
                            <h4 class="text-xl gradient-rosegold w-1/3 ml-auto font-lora text-right">Ladies</h4>
                            <h3 class="text-3xl text-right">Beguiling elegance meets<br>the highest precision</h3>
                            <div class="flex justify-end">
                                <router-link to="/giberg/watches/ladies" class="font-lora border-rosegold border mr-3 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 text-center">Watches</router-link>
                                <router-link to="/giberg/jewellery/ladies" class="font-lora border-rosegold border ml-3 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 text-center">Jewellery</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mens col-span-1">
                    <div class="overlay pl-12 pb-2 flex-col flex justify-end">
                        <div class="mr-auto">
                            <h4 class="text-xl gradient-rosegold w-1/3 mr-auto font-lora text-left">Gentlemen</h4>
                            <h3 class="text-3xl text-left">Breathtaking verve and<br>uncompromising determination</h3>
                            <div class="flex">
                                <router-link to="/giberg/watches/gentlemen" class="font-lora border-rosegold border mr-3 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 text-center">Watches</router-link>
                                <router-link to="/giberg/jewellery/gentlemen" class="font-lora border-rosegold border ml-3 py-1 px-5 my-8 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear block duration-75 text-center">Jewellery</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="discover" class="lg:px-20 px-6 xl:px-40 pb-16">
            <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase lg:w-1/2 mt-2 mb-16 text-center mx-auto">Discovering</h2>
            <div class="grid grid-cols-2 lg:grid-cols-4">
                <router-link to="/giberg/watches/ladies" class="col-span-1 border-rosegold border-r-2 border-b-2 lg:border-b-0 relative group">
                    <img src="/assets/giberg/lwHome.png" alt="Giberg Ladies Watches" class="filter group-hover:brightness-75 transition ease-linear duration-75 w-full h-full">
                    <h4 class="absolute bottom-4 text-3xl left-4 lg:left-6 text-wallgray">Ladies'<br>Watches</h4>
                </router-link>
                <router-link to="/giberg/jewellery/ladies" class="col-span-1 border-rosegold border-b-2 lg:border-b-0 lg:border-l-2 lg:border-r-2 relative group">
                    <img src="/assets/giberg/ljHome.png" alt="Giberg Ladies Jewellery" class="filter group-hover:brightness-75 transition ease-linear duration-75 w-full h-full">
                    <h4 class="absolute bottom-4 text-3xl left-4 lg:left-6 text-wallgray">Ladies'<br>Jewellery</h4>
                </router-link>
                <router-link to="/giberg/watches/gentlemen" class="col-span-1 border-rosegold lg:border-l-2 border-r-2 relative group">
                    <img src="/assets/giberg/mwHome.png" alt="Giberg Gentlemen Watches" class="filter group-hover:brightness-75 transition ease-linear duration-75 w-full h-full">
                    <h4 class="absolute bottom-4 text-3xl left-4 lg:left-6 gradient-rosegold">Gentlemen's<br>Watches</h4>
                </router-link>
                <router-link to="/giberg/jewellery/gentlemen" class="col-span-1 border-rosegold lg:border-l-2 relative group">
                    <img src="/assets/giberg/mjHome.png" alt="Giberg Gentlemen Jewellery" class="filter group-hover:brightness-75 transition ease-linear duration-75 w-full h-full">
                    <h4 class="absolute bottom-4 text-3xl left-4 lg:left-6 gradient-rosegold">Gentlemen's<br>Jewellery</h4>
                </router-link>
            </div>
        </div>
        
        <div id="custom">
            <div class="lg:px-20 px-6 xl:px-40 overlay text-center">
                <h3 class="text-rosegold">Custom made masterpieces</h3>
                <h2 class="font-lora gradient-rosegold text-gray-300 text-5xl uppercase kg:w-1/3 mt-2 mx-auto">Objets d’art</h2>
                <p class="pt-5 pb-9 text-lg font-bold text-gray-300">The magic of an ancient creature reborn in a precious breathtaking sculpture!</p>
                
                <video @volumechange="video.muted ? $emit('onSounds') : $emit('offSounds')" controls muted poster="/assets/giberg/ahtonPic.png" class="lg:w-full mx-auto border-2 shadow border-rosegold">
                    <source src="/assets/giberg/ahtonVid.mp4" type="video/mp4">
                </video>

                <p class="pt-10 text-lg lg:w-1/2 mx-auto">Giberg is the Old High German designation for treasure and thereby expresses the credo of Noble Forge: Creating treasures for eternity.</p>
                
                <router-link to="/giberg/art" class="font-lora border-rosegold border ml-3 py-1 px-5 my-8 inline-block text-rosegold hover:bg-rosegold hover:text-white transition ease-linear duration-75 text-center">Continue</router-link>
            </div>
        </div>


        <div id="ambassadors" class="grid grid-cols-2 lg:px-20 px-6 xl:px-40">
                <div class="col-span-2">
                    <h3 class="text-rosegold text-center">Ambassadors</h3>
                    <h2 class="col-span-2 font-lora gradient-rosegold text-gray-300 w-3/5 mx-auto text-5xl uppercase mt-2 mb-10 text-center">Imagine</h2>
                </div>
                <div class="col-span-2 lg:col-span-1 mb-6 lg:mb-0 lg:mr-4 hover:scale-105 transform transition ease-linear duration-75" id="maria">
                    <div class="overlay px-10 pb-6 flex flex-col justify-end">
                        <blockquote class="text-lg lg:w-3/4">“Behind every little movement of the body, behind the complex sequences of a choreography, there is hard work to make this all seem easy to the audience. Every minute, every day.”</blockquote>
                        <img src="/assets/giberg/mariaSignature.svg" alt="Ambassador signature" class="mt-6 mb-2 mr-auto" />
                        <h4>MARIA KHOREVA</h4>
                        <router-link to="/giberg/ambassadors/maria-khoreva" class="font-lora border-rosegold border py-1 mt-8 mb-4 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear inline-block w-1/2 lg:w-1/4 duration-75 text-center">Explore</router-link>
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1 lg:ml-4 hover:scale-105 transform transition ease-linear duration-75" id="dominic">
                    <div class="overlay px-10 pb-6 flex flex-col justify-end">
                        <blockquote class="text-lg lg:w-3/4">“My goal is the absolute top. My vision is to achieve the highest possible perfection. I dedicate every moment of the day to this endeavor with precision, efficiency, and commitment.”</blockquote>
                        <img src="/assets/giberg/dominicSignature.svg" alt="Ambassador signature" class="mt-6 mb-2 mr-auto" />
                        <h4>DOMINIC STRICKER</h4>
                        <router-link to="/giberg/ambassadors/dominic-stricker" class="font-lora border-rosegold border py-1 mt-8 mb-4 text-rosegold hover:bg-rosegold hover:text-white transition ease-linear inline-block w-1/2 lg:w-1/4 duration-75 text-center">Explore</router-link>
                    </div>
                </div>
        </div>

        <div id="manufacture">
            <div class="lg:px-20 px-6 pb-10 xl:px-40 overlay text-center">
                <h3 class="text-rosegold text-center">Innovation is the centre of our success</h3>
                <h2 class="col-span-2 font-lora gradient-rosegold text-gray-300 lg:w-3/5 mx-auto text-5xl uppercase mt-2 text-center">Manufacture</h2>
                <p class="my-5 text-lg font-bold text-gray-300 italic">A watch has to be a timeless witness for us and for generations to come. In fact: a watch has to be made for eternity.</p>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 text-gray-300 py-4">
                    <div class="col-span-1 text-lg text-left">
                        To bring the great and legendary tradition of the old master watchmakers and goldsmiths into the 21st century is one of the objectives of Giberg. With 40 years of experience as a supplier in the watch and jewellery industry, Andreas Altmann and his team of highly skilled engineers and designers are creating solely ultra-limited masterpieces.
                    </div>
                    <div class="col-span-1 text-lg text-left">
                        The clockworks are developed and engineered from the ground up. The designs combine craftsmanship with artistic creativity and are characterized by an innovative handling of precious stones and luxurious materials resulting in stunning masterpieces. Giberg is <b class="gradient-rosegold">Swiss engineering</b> at its best!
                    </div>
                </div>
                <p class="pt-16 pb-5 text-3xl lg:w-1/2 mx-auto text-gray-300 italic">“We preserve the glorious past of the watchmaker’s art by bringing it together with the infinite possibilities of the future.”</p>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
    name: "GibergHome",
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            video: null
        }
    },
    mounted() {
        this.video = document.querySelector("#custom video");
    }
}
</script>

<style scoped>
#gibergContainer {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

#gibergContainer .overlay {
    background-color:rgba(0, 0, 0, 0.70);
    padding-top: 90px !important;
    min-height: calc(80vh + 90px);
}

#gibergContainer #landing {
    margin-top: -90px;
    min-height: calc(80vh + 90px);
}

#gibergContainer #brandName {
    padding-top: calc(5vh + 90px) !important;
    width: 262px;
    margin-left: -131px;
    left: 50%;
}

#gibergContainer #landing .overlay {
    background-color:rgba(0, 0, 0, 0.70);
    padding-top: 90px !important;
    min-height: calc(80vh + 90px);
}

#gibergContainer #landing .ladies {
    background: url("/assets/giberg/landingLadies.png");
    background-size: cover;
    background-position: top;
}

#gibergContainer #landing .mens {
    background: url("/assets/giberg/landingMens.png");
    background-size: cover;
    background-position: top;
}

#gibergContainer #discover {
    padding-top: 90px;
}

#gibergContainer #discover .grid img {
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
}

#gibergContainer #custom {
    background: url("/assets/giberg/customAhton.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

#gibergContainer #ambassadors {
    padding-top: 90px;
    padding-bottom: 45px;
}

#gibergContainer #ambassadors .col-span-1 {
    min-height: 80vh;
}

#gibergContainer #ambassadors #maria {
    background: url("/assets/giberg/mariaHome.png");
    background-size: cover;
    background-position: 50% 30%;

}

#gibergContainer #ambassadors #dominic {
    background: url("/assets/giberg/dominicHome.png");
    background-size: cover;
    background-position: 50% 20%;

}

#gibergContainer #ambassadors img {
    height: 130px;
}

#gibergContainer #manufacture {
    background: url("/assets/giberg/manufacture.jpg");
    background-size: cover;
    background-attachment: fixed;
}
</style>